import React, {Component} from 'react'
import {PropTypes} from 'prop-types'
import {withFormsy} from 'formsy-react'

class FormCheckbox extends Component {
  changeValue(e) {
    this.props.setValue(e.currentTarget.checked)
  }

  render() {
    const {getValue, label, name} = this.props
    return (
      <div className="checkbox">
        <input
          className="checkbox__input"
          id={name}
          type="checkbox"
          checked={getValue() ? 'checked' : ''}
          onChange={this.changeValue.bind(this)} />
        <label className="checkbox__label" htmlFor={name}>
          {label}
        </label>
      </div>
    )
  }
}

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string
}

export default withFormsy(FormCheckbox)
