import pick from 'lodash/pick'
import { dataJSON, fromObjectToArray } from './PDF/reportPDF'

function DateFormatTrans(tranDate) {
   return tranDate;
   //  var arrDate = tranDate.split('/')
   //  var arrTime = arrDate[2].split(' ')
   //  return `${arrTime[0]}/${arrDate[1]}/${arrDate[0]} ${arrTime[1]}`
}

export function HebrewVersionPDF(texts, results, exportToPDF) {
   let headers = pick(texts, 'Branch', 'ID', 'Name', 'PaidCount', 'PaidSum', 'NotPaidCount', 'NotPaidSum')
   let rows = results.map(item => {
      item.PaidSum = +item.PaidSum.toFixed(2)
      item.NotPaidSum = +item.NotPaidSum.toFixed(2)
      return pick(item, 'BranchNetCode', 'IdentificationNumber', 'SellerFirstName', 'PaidCounter', 'PaidSum', 'NotPaidCounter', 'NotPaidSum')
   })
   exportToPDF(dataJSON(Object.values(headers), fromObjectToArray(rows)))
      .then(
         response => {
            if (navigator.appVersion.toString().indexOf('.NET') > 0) {
               // let link = document.createElement('a')
               // link.href = response
               // link.click()
               var link = document.createElement('a')
               link.setAttribute('id', 'pdf_id')

               link.setAttribute('href', response)
               var NameToDownload = 'tes.pdf'

               link.innerHTML = NameToDownload;
               link.click()
            } else {
               let link = document.createElement('a')
               link.href = response
               link.download = 'report.pdf'
               link.dispatchEvent(new MouseEvent('click'))
            }
         }
      )
}

export function HebrewVersionPDFTrans(texts, results, exportToPDF) {
   let headers = pick(texts, 'DataScale', 'TimeScale', 'DataCashRegister', 'TimeCashRegister', 'VendorName', 'ProductName', 'WeightUnits', 'Total', 'Paid', 'Trance')
   let rows = results.map(item => {
      item.ScaleTranDate = DateFormatTrans(item.ScaleTranDate)
      item.TranDate = DateFormatTrans(item.TranDate)
      return pick(item, 'ScaleTranDate', 'TranDate', 'SellerFirstName', 'ItemName', 'Quantity', 'Amount', 'IsPaid', 'TranNumber')
   })
   exportToPDF(dataJSON(Object.values(headers), fromObjectToArray(rows)))
      .then(
         response => {
            if (navigator.appVersion.toString().indexOf('.NET') > 0) {
               // let link = document.createElement('a')
               // link.href = response
               // link.click()
               var link = document.createElement('a')
               link.setAttribute('id', 'pdf_id')

               link.setAttribute('href', response)
               var NameToDownload = 'tes.pdf'

               link.innerHTML = NameToDownload;
               link.click()
            } else {
               let link = document.createElement('a')
               link.href = response
               link.download = 'report.pdf'
               link.dispatchEvent(new MouseEvent('click'))
            }
         }
      )
}
