import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

const WrapLink = (props) => {
  return (
    <Link
      to={props.path}
      className="table__link">
      {props.icon ? <div className={`icon-${props.icon} table__edit`}></div> : null}
      <span>{props.label}</span>
    </Link>
  )
}

WrapLink.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string.isRequired
}

export default WrapLink
