import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';

import * as actions from '../store/actions/index';
import Icon from '../utils/Icon';
import http from '../utils/Http';
import Node from '../components/node';

class ScalesGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
    };
  }

  async componentDidMount() {
    let type;
    switch (this.props.source) {
      case 'promo': type = '1'; break;
      case 'sales': type = '2'; break;
      case 'scale': type = '3'; break;
      default: type = '1';
    }
    try {
      const groups = await http('GetGroups', 'GET', { type });
      this.setState({ groups });
    } catch (err) {
      console.log('error reading JSON from GetGroups (1) - ' + err);
    }
  }

  select = (groupId) => {
    this.props.actions.selectScaleGroup(groupId, this.state.groups.find(x => x.id === groupId), this.props.source);
  }

  showGroupInfo = () => {

  }

  render() {
    const { groups } = this.state;
    const { selectedGroupId } = this.props;

    let header;
    switch (this.props.source) {
      case 'promo': header = 'קבוצות משקלים לפרסום'; break;
      case 'sales': header = 'קבוצות משקלים לדרבון'; break;
      case 'scale': header = 'קבוצות משקלים'; break;
      default: header = '';
    }
    if (typeof groups === undefined) return;

    const treeView = groups.map((group, index) => (
      <Node key={index}
        onClick={(e) => this.select(group.id)}
        text={group.name}
        selected={selectedGroupId === group.id}
        depth={1} >
        {group.branches.map((branch, index) => (
          <Node key={branch.id} text={branch.name}>
            {branch.departments.map((department, index) => (
              <Node key={department.id} text={department.name} />
            ))}
          </Node>
        ))}
      </Node>
    ));

    const items = groups.map((group, index) => {
      const tooltip = group.branches.map((branch, index) => (
        branch.departments.map((department, index) => (
          ` ${branch.name} => ${department.id} | ${department.name}`
        )
        )));
      //console.log('tooltip', tooltip);
      const selectedClass = selectedGroupId === group.id ? 'scalegroups__item--selected' : '';
      const classes = `scalegroups__item ${selectedClass}`;
      return (
        <li
          key={index}
          className={classes}
          onClick={(e) => this.select(group.id)} >
          {`${group.id} | ${group.name}`}
          <div className="item-tooltip">
            <span className="tooltiptext"><pre>{tooltip}</pre></span>
          </div>
          <a href="#" onClick={(e) => this.showGroupInfo(group.id)}>
            <i className="scalegroups__item-icon" >
              <Icon width="15px" height="15px" icon={"info"} /></i>
          </a>
        </li>
      )
    });

    const itemsView = <ul className="scalegroup__items">{items}</ul>

    return (
      <section className="weight-groups" >
        <h2>{header}</h2>
        <div className='weight-group-list'>
          {itemsView}
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedGroupId: state.App.selectedGroupId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScalesGroups);



