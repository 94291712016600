/** @jsx jsx */

import React, { Component } from 'react';
import { jsx, css } from '@emotion/core';
import produce from "immer";
import Select from 'react-select';
import Formsy from 'formsy-react'

import http from '../../utils/Http';
import httpJSON from '../../utils/HttpJSON';
import FormRadioGroup from '../../components//form/FormRadioGroup'

import red from "../../assets/images/red-icon.png";
import scale from "../../assets/images/scale.png";
import noScale from "../../assets/images/no-scales.png";
import scales from "../../assets/images/scales.png";
import largeScale from "../../assets/images/large-scale.png";

import { ReactComponent as SettingIcon } from '../../assets/images/settings-cogwheel.svg';
import { ReactComponent as StarIcon } from '../../assets/images/star.svg';
import { ReactComponent as SaveIcon } from '../../assets/images/export-file.svg';

import Checkbox from '../../components/Checkbox';

import UpdateBranchDepartmentScalesPopup from './updateBranchDepartmentScalesPopup';

const header = (startCol, departments, onClick) => {
   return (
      <div className="status__grid-header">
         <div className="status__grid-header-fixed">סניף</div>
         {departments.slice(startCol).slice(0, 10).map(item => {
            if (item.id === 0)
               return null;
            return (
               <div
                  key={item.id}
                  className="status__grid-header-item">
                  {item.name}
               </div>
            )
         })}
         <button className="status__grid-header-button" onClick={onClick}>>></button>
      </div>
   )
}

const branchDepartment = (key, scalesCount, tooltip, state, onClick, checked, marked) => {
   //console.log('branchDepartment', { key, scalesCount, tooltip, state })
   const image = (scalesCount > 1)
      ? scales
      : scale
   const scaleLabel = (scalesCount > 1)
      ? <p className="status__item-text">{`${scalesCount} יח'`}</p>
      : null;
   const tooltipInfo = tooltip ? (tooltip.length > 0)
      ? <span className="tooltiptext"><pre>{tooltip}</pre></span>
      : null : null;
   const className = `status__grid-item status__item--darkgray item-tooltip`;
   const imgSizeClassName = (scalesCount > 1) ? 'large' : 'small';

   if (scalesCount === 0) {
      if (state.viewMode === 'EditScales') {
         return (
            <div key={key} className='status__grid-item status__item--gray' css={css`&:hover{cursor:pointer;}`} onClick={() => onClick()}>
               <img className={`status__item-image status__item-image--${imgSizeClassName}`} src={noScale}></img>
            </div>
         )
      }
      else {
         return (
            <div key={key} className='status__grid-item status__item--gray' ></div>
         )
      }
   }
   else {
      return (
         <div key={key} className={className} css={css`&:hover{cursor:pointer;}`} onClick={() => onClick()}>
            {tooltipInfo}
            <img className={`status__item-image status__item-image--${imgSizeClassName}`} src={image}></img>
            {scaleLabel}
            {(state.viewMode !== 'ReadOnly' && state.viewMode !== 'EditScales') &&
               < Checkbox
                  className='regular-checkbox'
                  checked={checked}
               />
            }
            {marked &&
               <StarIcon css={css`position:absolute;top:3px;right:3px;`} width="14px" height="14px" />
            }
         </div>
      )
   }
}

class ScalesManagment extends Component {
   state = {
      branches: {},
      layouts: {},
      departments: {},
      selectedBranch: {},
      groupsParameters: {},
      scaleTypes: {},
      showDetails: false,
      selectedBranchId: null,
      selectedDeptId: null,
      showUpdageBranchDepartmentPopup: false,
      showUpdageBranchDepartmentScalesPopup: false,
      viewMode: 'ReadOnly',
      viewModeLayoutId: null,
      viewModeParamId: null,
      showSaveButton: false,
      selectedLayout: null,
      selectedParam: null,
      viewModeInfo: 'בחירת שבלונה או קבוצת פרמטרים תציג את המחלקות בסניף הכלולות בקבוצות הנבחרות',
      startCol: 0
   };

   async componentDidMount() {
      const branches = await http('GetBranches', 'GET');
      const departments = await http('GetDepartments', 'GET');
      const data = await http('GetLayouts', 'GET');
      const groupsParameters = await http('GetGroupsParameters', 'GET');
      const scaleTypes = await http('GetGroupScaleTypes', 'GET');

      this.setState({ branches, departments, layouts: data, groupsParameters, scaleTypes })
   }

   handleFilterChange = (filter) => {
      let newFilters = Object.assign({}, this.state.filters);
      if (filter.filterTerm) {
         newFilters[filter.column.key] = filter;
      } else {
         delete newFilters[filter.column.key];
      }

      this.setState({ filters: newFilters });
   };

   clearSelection = () => {
      const branches = produce(this.state.branches, draft => {
         for (let branchIndex = 0; branchIndex < draft.length; ++branchIndex)
            for (let departmentIndex = 0; departmentIndex < draft[branchIndex].departments.length; ++departmentIndex)
               draft[branchIndex].departments[departmentIndex].isChecked = false;
      });
      this.setState({ branches });
   }

   handleLayoutSelection = layoutId => {
      // select all relevant branch departments.
      const branches = produce(this.state.branches, draft => {
         for (let branchIndex = 0; branchIndex < draft.length; ++branchIndex) {
            for (let departmentIndex = 0; departmentIndex < draft[branchIndex].departments.length; ++departmentIndex) {
               if (draft[branchIndex].departments[departmentIndex].layoutId === layoutId) {
                  draft[branchIndex].departments[departmentIndex].isChecked = true;
               }
            }
         }
      });

      this.setState({ branches, layoutId });
   }

   handleParamsSelection = paramId => {
      // select all relevant branch departments.
      const branches = produce(this.state.branches, draft => {
         for (let branchIndex = 0; branchIndex < draft.length; ++branchIndex) {
            for (let departmentIndex = 0; departmentIndex < draft[branchIndex].departments.length; ++departmentIndex) {
               if (draft[branchIndex].departments[departmentIndex].layoutId === paramId) {
                  draft[branchIndex].departments[departmentIndex].isChecked = true;
               }
            }
         }
      });
      this.setState({ branches, paramId });
   }

   handleBranchDepartmentClick = (branchId, departmentId) => {

      if ((this.state.viewMode === 'EditParams' && this.state.paramId === undefined) || (this.state.viewMode === 'EditLayouts' && this.state.layoutId === undefined))
         return;

      const branches = produce(this.state.branches, draft => {
         const branchIndex = draft.findIndex(x => x.id === branchId);
         const departmentIndex = draft[branchIndex].departments.findIndex(x => x.id === departmentId);
         draft[branchIndex].departments[departmentIndex].isChecked = !draft[branchIndex].departments[departmentIndex].isChecked;
      });
      if (this.state.viewMode === 'EditScales')
         this.setState({ showUpdageBranchDepartmentScalesPopup: true });
      else { // EditParams || EditLayouts
         this.setState({ showUpdageBranchDepartmentPopup: true, showSaveButton: true });
      }

      this.setState({ branches, selectedBranchId: branchId, selectedDeptId: departmentId });
   }

   handleUpdatingBranchDepartment = async (e, data) => {
      const { selectedBranchId, selectedDeptId } = this.state;
      const branches = produce(this.state.branches, draft => {
         const branchIndex = draft.findIndex(branch => branch.id === selectedBranchId);
         const departmentIndex = draft[branchIndex].departments.findIndex(department => department.id === selectedDeptId);

         draft[branchIndex].departments[departmentIndex].scales = data;
      });

      console.log('handleUpdatingBranchDepartment', data, branches)
      this.setState({ branches, showUpdageBranchDepartmentScalesPopup: false, showSaveButton: true });
   }

   handleBranchDepartmentValueChanged = (field, value) => {
      console.log('handleBranchDepartmentValueChanged', field, value)
   }

   handleBranchesSave = async () => {
      const { viewMode, viewModeLayoutId, viewModeParamId } = this.state;

      let updatedBranches = this.state.branches.slice(0);
      switch (viewMode) {

         case 'EditParams':
            updatedBranches = produce(this.state.branches, draft => {
               for (let branchIndex = 0; branchIndex < draft.length; ++branchIndex) {
                  for (let departmentIndex = 0; departmentIndex < draft[branchIndex].departments.length; ++departmentIndex) {
                     if (draft[branchIndex].departments[departmentIndex].isChecked) {
                        draft[branchIndex].departments[departmentIndex].isChecked = false;
                        draft[branchIndex].departments[departmentIndex].paramId = viewModeParamId;
                     }
                  }
               }
            });

            break;

         case 'EditLayouts':
            updatedBranches = produce(this.state.branches, draft => {
               for (let branchIndex = 0; branchIndex < draft.length; ++branchIndex) {
                  for (let departmentIndex = 0; departmentIndex < draft[branchIndex].departments.length; ++departmentIndex) {
                     if (draft[branchIndex].departments[departmentIndex].isChecked) {
                        draft[branchIndex].departments[departmentIndex].isChecked = false;
                        draft[branchIndex].departments[departmentIndex].layoutId = viewModeLayoutId;
                        //console.log(`update branch ${draft[branchIndex].name} department ${draft[branchIndex].departments[departmentIndex].name} value ${viewModeLayoutId} `)
                     }
                  }
               }
            });
            break;

         default:
            break;
      }

      const branches = await httpJSON('UpdateBranches', 'POST', updatedBranches);
      this.setState({
         branches,
         showSaveButton: false,
         selectedLayout: null,
         selectedParam: null,
         viewModeLayoutId: null,
         viewModeParamId: null,
      });
   }

   handleUpdateStartCol = () => {
      if (this.state.startCol === 0)
         this.setState({ startCol: 10 });
      else
         this.setState({ startCol: 0 });
   }

   render() {
      const {
         branches,
         layouts,
         departments,
         groupsParameters,
         showDetails,
         selectedBranch,
         viewMode,
         selectedBranchId,
         selectedDeptId,
         scaleTypes,
         showUpdageBranchDepartmentPopup,
         showUpdageBranchDepartmentScalesPopup,
         viewModeLayoutId,
         viewModeParamId,
         showSaveButton,
         selectedLayout,
         selectedParam,
         viewModeInfo,
         startCol,
         selectedScale } = this.state;

      if (Object.entries(branches).length === 0 && branches.constructor === Object)
         return null;

      // build the view,for each branch place branch name and 10 departments
      let branchDepartments = [], index = 0;
      branches.forEach(branch => {
         index++;
         branchDepartments.push(<div key={index} className="status__grid-header-fixed">{branch.name}</div>);
         for (let i = 1 + this.state.startCol; i < 11 + this.state.startCol; i++) {
            index++;
            const departmentIndex = branch.departments.findIndex(x => x.id === i);
            if (departmentIndex !== -1) {

               const markedLayout = viewModeLayoutId !== null
                  ? branch.departments[departmentIndex].layoutId === viewModeLayoutId
                     ? true
                     : false
                  : false;
               const markedParams = viewModeParamId !== null
                  ? branch.departments[departmentIndex].paramId === viewModeParamId
                     ? true
                     : false
                  : false;

               // display branch departments.
               const item = branchDepartment(
                  branch.id.toString() + branch.departments[departmentIndex].id.toString() + index,
                  branch.departments[departmentIndex].scales.length,
                  scale.tooltip,
                  this.state,
                  () => this.handleBranchDepartmentClick(branch.id, branch.departments[departmentIndex].id),
                  branch.departments[departmentIndex].isChecked || markedLayout || markedParams,
                  viewMode === 'ReadOnly' ? markedLayout || markedParams : false);
               branchDepartments.push(item)
            }
            else {
               // empty item
               branchDepartments.push(<div key={index} className='status__grid-item status__item--gray'></div>)
            }
         }
      });

      const table =
         <div className="status__grid-container">
            {branchDepartments}
         </div>

      let details = null;
      if (Object.entries(selectedBranch).length !== 0) {
         details =
            <div>
               <div className="details__name">{`סניף ${selectedBranch.name} | ${selectedBranch.id} `}</div>
               <div className="details__container">
                  <div className="details__right"></div>
                  <div className="details__center">
                     {selectedBranch.departments.map(department => {

                        const departments = department.scales.map(scale => (
                           <div className="row__item">
                              <a href="#" className={`row__item - button row__item - button--${scale.status} `} onClick={() => this.selectScale(selectedBranch.id, scale.id)}>{scale.id}</a>
                           </div>
                        ));

                        return (
                           <>
                              <div className="row__name">{department.name}</div>
                              {departments}
                              <div className="line"></div>
                           </>
                        )
                     })}
                  </div>
               </div>
            </div>
      }

      let layoutsOptions = [];
      if (layouts) {
         layouts.forEach(function (layout) {
            layoutsOptions.push({ key: layout.id, value: layout.id, label: `${layout.id} | ${layout.name} ` })
         });
      }

      let paramsOptions = [];
      if (groupsParameters) {
         groupsParameters.forEach(function (param) {
            paramsOptions.push({ key: param.id, value: param.id, label: `${param.id} | ${param.name} ` })
         });
      }

      const layoutsSelection = layouts.map(layout => (
         <>
            <a href="#"
               className="status__button"
               css={css`background - color: ${layout.color} `}
               onClick={() => this.statusClicked(layout.id)}> {layout.name}
            </a >

         </ >
      ));

      const viewModeOptions = [
         {
            label: 'שיוך שבלונות',
            value: 'EditLayouts'
         },
         {
            label: 'שיוך פרמטרים',
            value: 'EditParams'
         },
         // {
         //    label: 'עריכת עמדות',
         //    value: 'EditScales'
         // },
         {
            label: 'צפייה בלבד',
            value: 'ReadOnly'
         }
      ]

      const branchDepartmentName = (selectedBranchId !== null && selectedDeptId != null)
         ? `${branches.find(x => x.id == selectedBranchId).name} | ${departments.find(x => x.id == selectedDeptId).name} `
         : '';

      const presetSelection = {
         visible: viewMode === 'ReadOnly' || viewMode === 'EditLayouts',
         title: viewMode === 'EditLayouts' ? 'בחר שבלונה' : 'שבלונות',
         placeHolder: viewMode === 'EditLayouts' ? 'בחר שבלונה..' : 'בחר שבלונה להצגה..',

      }
      const paramSelection = {
         visible: viewMode === 'ReadOnly' || viewMode === 'EditParams',
         title: viewMode === 'EditParams' ? 'בחר קבוצת פרמטרים' : 'פרמטרים',
         placeHolder: viewMode === 'EditParams' ? 'בחר קבוצת פרמטרים..' : 'בחר קבוצה להצגה..',

      }
      const branchDepartmentScales = (selectedBranchId !== null && selectedDeptId !== null)
         ? branches.find(branch => branch.id === selectedBranchId).departments.find(department => department.id === selectedDeptId).scales
         : null;
      return (
         <section className="managment-system">
            <div className='presetscales_container'>
               <div className='presetscales_selectors'>
                  {presetSelection.visible &&
                     <div css={css`
                     display: flex;
                     align - items: center;
                     margin - left: 10px;
                     `}>
                        <label css={css`
                     width: 200px;
                     font - size: 12px;
                     color: #0f2649;
                     transition: background - color 0.5s ease;
                     font - weight: 400;
                     margin - bottom: 5px; `}>
                           {presetSelection.title}
                           <Select css={css`width: 200px; `}
                              autosize={false}
                              isClearable={true}
                              isRtl={true}
                              simpleValue
                              placeholder={presetSelection.placeHolder}
                              noOptionsMessage={() => 'לא הוגדרו שבלונות'}
                              onChange={(option, { action }) => {
                                 if (action !== 'clear' && viewMode === 'EditLayouts')
                                    this.handleLayoutSelection(option.value);
                                 this.setState({ viewModeLayoutId: option ? option.value : null, selectedLayout: option })
                              }}
                              value={selectedLayout}
                              options={layoutsOptions}
                           />
                        </label>
                     </div>
                  }
                  {paramSelection.visible &&
                     <div css={css`
                     display: flex;
                     align - items: center;
                     margin - bottom: 5px;
                     `}>
                        <label css={css`
                     width: 200px;
                     font - size: 12px;
                     color: #0f2649;
                     transition: background - color 0.5s ease;
                     font - weight: 400;
                     `}>
                           {paramSelection.title}
                           <Select css={css`width: 200px; `}
                              autosize={false}
                              isClearable={true}
                              isRtl={true}
                              simpleValue
                              placeholder={paramSelection.placeHolder}
                              noOptionsMessage={() => 'לא הוגדרו קבוצות פרמטרים'}
                              onChange={(option, { action }) => {
                                 if (action !== 'clear' && viewMode === 'EditParams')
                                    this.handleParamsSelection(option.value);
                                 this.setState({ viewModeParamId: option ? option.value : null, selectedParam: option })
                              }}
                              value={selectedParam}
                              options={paramsOptions}
                           />
                        </label>
                     </div>
                  }
               </div>

               {showSaveButton &&
                  <button css={css`position: relative; right: 22px; top: 6px; `}>
                     <SaveIcon title='שמור שינויים' width='25' height='25' css={css`fill: green; `}
                        onClick={this.handleBranchesSave}
                     />
                  </button>
               }

               <Formsy
                  className="sort_presetScales"
                  onChange={(model) => {
                     if (model.viewMode === 'EditLayouts' || model.viewMode === 'EditParams')
                        this.clearSelection();
                     let info = '';
                     switch (model.viewMode) {
                        case 'ReadOnly':
                           info = 'בחירת שבלונה או קבוצת פרמטרים תציג את המחלקות בסניף הכלולות בקבוצות הנבחרות';
                           break;
                        case 'EditLayouts':
                           info = 'יש לבחור שבלונה ולאחר מכן לסמן את המחלקות בסניפים אותם רוצים לשייך';
                           break;
                        case 'EditParams':
                           info = 'יש לבחור קבוצת פרמטרים ולאחר מכן לבחור את המחלקות בסניפים אותם רוצים לשייך';
                           break;
                        case 'EditScales':
                           info = 'יש לבחור מחלקה בסניף אשר בה רוצים לעדכן את העמדות';
                           break;
                     }
                     this.setState({
                        viewMode: model.viewMode,
                        viewModeInfo: info,
                        viewModeParamId: null,
                        viewModeLayoutId: null
                     });
                  }}>

                  <FormRadioGroup
                     items={viewModeOptions}
                     itemClassName="sort__radio"
                     listClassName="sort__list"
                     name="viewMode"
                     value={viewMode}
                  />
               </Formsy>
            </div>

            <h4 css={css`padding - bottom: 3px; `}>{viewModeInfo}</h4>

            {header(startCol, departments, this.handleUpdateStartCol)}
            {table}

            <UpdateBranchDepartmentScalesPopup
               visible={showUpdageBranchDepartmentScalesPopup}
               data={branchDepartmentScales}
               scaleTypes={scaleTypes}
               title={branchDepartmentName}
               onAccept={this.handleUpdatingBranchDepartment}
               onChange={this.handleBranchDepartmentValueChanged}
               onClose={() => this.setState({ showUpdageBranchDepartmentScalesPopup: false })}
            />
         </section >

      );
   }
}

export default ScalesManagment;
