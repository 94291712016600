import React from 'react';
import { ReactComponent as WarningIcon } from '../../assets/images/warning.svg';

const warningPopup = (props) => {

  if (!props.visible)
    return null;

  return (
    <div className="advertising-popup popup shown-popup ">
      <div className="pop-up-wrapper popup_height">
        <WarningIcon title='' width='50' height='50' />
        <div className="pop-up-text">
          <h2>{props.title}</h2>
        </div>
        <div className="pop-up-empty-fields">
        </div>
        <div className="pop-up-buttons">
          {props.keys.includes('close') &&
            < button
              onClick={(e) => props.onClick('close', e)}
              className="grey-btn">
              {props.labels.find(x => x.key === 'close').label}
            </button>
          }
          {props.keys.includes('yes') &&
            < button
              onClick={(e) => props.onClick('yes', e)}
              className="grey-btn">
              {props.labels.find(x => x.key === 'yes').label}
            </button>
          }
          {props.keys.includes('no') &&
            < button
              onClick={(e) => props.onClick('no', e)}
              className="grey-btn">
              {props.labels.find(x => x.key === 'no').label}
            </button>
          }
        </div >
      </div >
    </div >
  )
}

export default warningPopup;
