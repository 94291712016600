
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTable from "react-table";
import Select from 'react-select';
import _ from 'lodash';

import classes from './Branches.module.scss';
import * as actions from '../../store/actions/index';
import http from '../../utils/Http';

import AdvDeleteItemPopup from '../../components/advertising/AdvDeleteItemPopup';

class Branches extends Component {

   state = {
      data: [],
      departments: [],
      deleteId: 0,
      expanded: {},
      showDeletePopup: false,
   };

   async componentDidMount() {
      const data = await http('GetBranches', 'GET');
      const departments = await http('GetDepartments', 'GET');

      this.setState({ data, departments: departments.filter(x => x.id !== 0) });
   }

   addRow = e => {
      console.log(this.state.data)
      const max = this.state.data.length
         ? this.state.data.reduce(function (prev, current) {
            return (prev.id > current.id) ? prev : current
         })
         : { id: 0 };


      const data = [...this.state.data];
      const departments = this.state.departments.map(department => { return ({ id: department.id, name: department.name, scales: '' }) });
      data.push({ deleteId: max.id + 1, id: max.id + 1, name: '???', network: '0.0.0.0', totalScales: 0, departments });
      this.setState({ data });
   }

   deleteRow = deleteId => {
      this.setState({ showDeletePopup: true, deleteId });
   }

   handlePopupDelete = (type, e) => {
      if (type === 'delete') {
         const data = this.state.data.filter(x => x.deleteId !== this.state.deleteId);
         this.setState({ data });
      }
      this.setState({ showDeletePopup: false });
   }

   async handleSumbit(e) {
      const { data } = this.state;
      let uniqArr = _.uniqBy(data, 'id');
      if (uniqArr.length !== data.length) {
         console.log('found duplication..')
      }

      this.props.actions.UpdateBranches(data);
      this.setState({ saveChanges: false, expanded: {} });
   }

   renderEditable = cellInfo => {
      if (this.state.data[cellInfo.index] == null)
         return null;

      return (
         <div
            className={classes.vertical_center}
            //style={{ backgroundColor: "red" }}
            contentEditable
            suppressContentEditableWarning
            onBlur={e => {
               const data = [...this.state.data];
               data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
               this.setState({ data, saveChanges: true });
            }}
            dangerouslySetInnerHTML={{
               __html: this.state.data[cellInfo.index][cellInfo.column.id]
            }}
         />
      );
   }

   render() {

      const {
         data,
         childData,
         showBranchDepartments,
         showDeletePopup,
         saveChanges,
         selectedBranchDepartment,
         expanded
      } = this.state;

      const { Branches } = this.props;

      if (data === undefined)
         return null;

      //console.log('Branches render', data, Branches);

      return (
         <section className="managment-system">
            <div className="container">
               <div className="row">
                  <div className="col-md-4 col-xs-12">
                     <div className={classes.header}>
                        <h3>סניפים</h3>
                        <div className={classes.users__buttons} >
                           <button className={`green-btn ${classes.users__button}`} onClick={(e) => this.addRow(e)}>הוסף סניף</button>
                           <button className={`${saveChanges ? 'green-btn' : 'grey-btn'} ${classes.users__button}`} onClick={(e) => this.handleSumbit(e)}>שמירת נתונים</button>
                        </div>
                     </div>
                     <div className={classes.users__table}>
                        <ReactTable
                           data={data}
                           noDataText="אין נתונים להצגה.."
                           previousText="הקודם"
                           nextText="הבא"
                           pageText="דף"
                           ofText="/"
                           defaultSorted={[{
                              id: 'id',
                              desc: false,
                           }]}
                           columns={[
                              {
                                 Header: "",
                                 accessor: "deleteId",
                                 width: 30,
                                 Cell: row => {
                                    return (
                                       <div className={classes.vertical_center}>
                                          <a href="#" className={`bolet-icon-trash align-center`} onClick={() => this.deleteRow(row.row.deleteId)}> </a>
                                       </div>
                                    )
                                 }
                              },
                              {
                                 Header: "קוד",
                                 accessor: "id",
                                 width: 80,
                                 Cell: this.renderEditable
                              },
                              {
                                 Header: "שם",
                                 accessor: "name",
                                 Cell: this.renderEditable
                              },
                              // {
                              //    Header: "כתובת שרת סניפי",
                              //    accessor: "network",
                              //    width: 150,
                              //    Cell: this.renderEditable
                              // },
                              // {
                              //    Header: "כמות",
                              //    accessor: "totalScales",
                              //    width: 80,
                              //    Cell: (cellInfo) => Branches !== undefined && Branches[cellInfo.index] != undefined ? Branches[cellInfo.index].totalScales : data[cellInfo.index].totalScales
                              // },
                           ]}
                           showPageSizeOptions={false}
                           defaultPageSize={10}
                           className="-striped -highlight"
                        />
                     </div>
                     <AdvDeleteItemPopup
                        visible={showDeletePopup}
                        title='למחוק סניף?'
                        onClick={this.handlePopupDelete}
                     />
                  </div>
               </div>
            </div>
         </section>
      )
   }
}

function mapStateToProps(state) {
   return {
      Branches: state.App.Branches,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: bindActionCreators(actions, dispatch)
   }
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Branches);
