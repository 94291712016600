import React, { Component } from 'react'
import { Editor } from 'react-draft-wysiwyg'; // https://jpuri.github.io/react-draft-wysiwyg/#/demo
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

class SaleUserTextPopup extends Component {

   state = {
      editorState: EditorState.createEmpty(),
   };

   onEditorStateChange = (editorState) => {
      this.setState({ editorState });
   };

   setEditorReference = (ref) => {
      this.editorReferece = ref;
      if (this.editorReferece !== null)
         this.editorReferece.focus();
   }

   render() {
      const { content, visible, onClick } = this.props;
      const { editorState } = this.state;

      if (!visible)
         return null;

      const toolbar = {
         options: ['inline', 'fontSize', 'fontFamily', 'textAlign', 'colorPicker', 'history'],
         inline: {
            options: ['bold', 'italic', 'underline'],
         },
         fontFamily: {
            options: ['Arial', 'Tahoma', 'Times New Roman'],
         }
      }
      let defaultEditorState;
      if (content !== '') {
         const blocksFromHTML = convertFromHTML(content);
         const contentState = ContentState.createFromBlockArray(blocksFromHTML);
         defaultEditorState = EditorState.createWithContent(contentState);
      }
      return (
         <div className="user-text popup shown-popup">
            <div className="pop-up-wrapper">
               <h2> הוספת טקסט למוכרן</h2>
               <br />
               <Editor
                  toolbarHidden="true" // TODO: Add support
                  editorRef={this.setEditorReference}
                  wrapperClassName="editor-wrapper"
                  editorClassName="editor"
                  defaultEditorState={defaultEditorState}
                  onEditorStateChange={this.onEditorStateChange}
                  toolbar={toolbar}
               />
               <br />
               <div className="pop-up-buttons">
                  <button
                     className="green-btn"
                     onClick={e => onClick('update-user-text', draftToHtml(convertToRaw(editorState.getCurrentContent())))}
                     type="submit">
                     שמור
                  </button>
                  <button
                     onClick={(e) => onClick('hide-user-text', e)}
                     className="grey-btn cancel-popup">
                     ביטול
                  </button>
               </div>
            </div>
         </div>
      )
   }
}

export default SaleUserTextPopup
