import filter from 'lodash/filter'
import squish from 'object-squish'

function isExist(query, flat, key) {
  if (String(flat[key]).toLowerCase().indexOf(query.toLowerCase()) >= 0) {
    return true
  }
}

export default function customFilterFunction({state, action, dataName}) {
  return {
    ...state,
    query: action.query,
    results: filter(state[dataName], item => {
      var flat = squish(item)
      
      for (var key in flat) {
        if (isExist(action.query, flat, key)) {
          return true
        }
      }
      return false
    })
  }
}
