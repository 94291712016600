import map from 'lodash/map'

function checkArray(arr, field, id, isGroup) {
  return map(arr, item => {
    if (isGroup) {
      if (item.IsGroup && item[field] === id) {
        return {...item, IsChecked: !item.IsChecked}
      } else {
        return item
      }
    } else {
      if (!item.IsGroup && item[field] === id) {
        return {...item, IsChecked: !item.IsChecked}
      } else {
        return item
      }
    }
  })
}

export default function toggleCheckItem(state, dataName, field, id, isGroup) {
  return {
    ...state,
    allSelected: false,
    [dataName]: checkArray(state[dataName], field, id, isGroup),
    results: checkArray(state.results, field, id, isGroup),
    saved: checkArray(state.saved, field, id, isGroup)
  }
}
