import some from 'lodash/some';

import Http from '../../../utils/Http';
import HttpJSON from '../../../utils/HttpJSON';
import { getLocal } from '../../../utils/LocalStorage';
import * as actionTypes from '../actionTypes';

export function presetItemsSet(data) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.PRESET_ITEMS_SET,
      data
    }) 
  }
}

export function presetItemsSetFilter(query) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.PRESET_ITEMS_SET_FILTER,
      query
    }) 
  }
}
