import * as actionTypes from '../../actions/actionTypes';

import customFilterFunction from '../../../utils/customFilterFunction'
import toggleCheckItem from '../../../utils/toggleCheckItem'
import toggleCheckAllItems from '../../../utils/toggleCheckAllItems'
import customSortFunction from '../../../utils/customSortFunction'
import groupFunction from '../../../utils/groupFunction'
import map from 'lodash/map'

const dataName = 'ReportLines'

const initialState = {
   allSelected: false,
   fetching: false,
   query: '',
   [dataName]: [],
   TotalNotPaidCounter: 0,
   TotalNotPaidSum: 0,
   TotalPaidCounter: 0,
   TotalPaidSum: 0,
   results: [],
   // reverse: false,
   // saved: [],
   sort: '',
   sortAscending: '',
   sumBranches: false,
   errorMessage: ''
}

export default function ReportPage(state = initialState, action) {
   switch (action.type) {
      case actionTypes.REPORT_PAGE_REQUEST:
         return {
            ...state,
            fetching: true,
            errorMessage: ''
         }
      case actionTypes.REPORT_PAGE_ERROR:
         return {
            ...state,
            fetching: false,
            errorMessage: action.errorMessage,
            results: action.errorMessage === 'Timeout' ? [] : state.results
         }
      case actionTypes.REPORT_PAGE_GET_ADVENCED_DETAILS_SELLER_REPORT:
         return {
            ...state,
            ...action.payload,
            results: map(action.payload[dataName], item => ({ ...item, IsChecked: false })),
            // saved: action.payload[dataName],
            fetching: false,
            allSelected: false
         }

      case actionTypes.REPORT_PAGE_CHANGE_SORT: return customSortFunction({ state, action, dataName })
      case actionTypes.REPORT_PAGE_SET_FILTER: {
         let TotalPaidCounter = 0
         let TotalPaidSum = 0
         let TotalNotPaidCounter = 0
         let TotalNotPaidSum = 0
         const filtered = customFilterFunction({ state, action, dataName })
         filtered.results.forEach(item => {
            TotalPaidCounter += item.PaidCounter
            TotalPaidSum += item.PaidSum
            TotalNotPaidCounter += item.NotPaidCounter
            TotalNotPaidSum += item.NotPaidSum
         })

         return {
            ...filtered,
            TotalPaidCounter,
            TotalPaidSum,
            TotalNotPaidCounter,
            TotalNotPaidSum
         }
      }

      case actionTypes.REPORT_PAGE_EXPORT_EXCEL:
         return {
            ...state,
            excelUrl: action.payload
         }

      case actionTypes.REPORT_PAGE_TOGGLE_CHECK_ITEM: return toggleCheckItem(state, dataName, 'IdentificationNumber', action.id)
      case actionTypes.REPORT_PAGE_TOGGLE_CHECK_ALL_ITEMS: return toggleCheckAllItems(state, dataName)
      case actionTypes.REPORT_PAGE_TOGGLE_SUM_BRANCHES: {
         return {
            ...state,
            results: state.sumBranches ? state.ReportLines.map(item => ({ ...item, IsChecked: false })) : groupFunction(map(state.ReportLines, item => ({ ...item, IsChecked: false }))),
            sumBranches: !state.sumBranches,
            allSelected: false
         }
      }

      default:
         return state
   }
}
