import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Griddle, { RowDefinition, ColumnDefinition, plugins } from 'griddle-react';
import moment from 'moment';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import * as actions from '../../store/actions/index';
import FilterByDates from '../../layout/reportPage/FilterByDates';
import FilterByQuery from '../../layout/reportPage/FilterByQuery';
import Exports from '../../layout/reportPage/Exports';
import GetTableFootRow from '../../utils/tableFoots/ReportPage';
import TableFoot from '../../components/table/TableFoot';
import Checkbox from '../../components/Checkbox'

// const enhancedWithRowData = connect((state, props) => {
//    return {
//       rowData: state.ReportPage.results[props.griddleKey]
//    };
// });

const rowDataSelector = (state, { griddleKey }) => {
   return state
      .get('data')
      .find(r => r.get('griddleKey') === griddleKey)
      .toJSON();
};

const enhancedWithRowData = connect((state, props) => {
   return {
      rowData: rowDataSelector(state, props),
   };
});

const NoResults = (text) => {
   text = 'אין נתונים זמינים';
   return (
      <h3>{text}</h3>
   )
}
export class ReportPage extends React.Component {
   state = {
      dates: this.props.dates,
      excelUrl: null
   }

   componentDidMount() {
      this.props.actions.getAdvancedDetailsSellerReport({
         id: this.props.match.params.id,
         startdate: moment(this.props.dates.startdate).format('YYYY-MM-DD'),
         enddate: moment(this.props.dates.enddate).format('YYYY-MM-DD'),
         scalestartdate: moment(this.props.dates.scalestartdate).format('YYYY-MM-DD'),
         scaleenddate: moment(this.props.dates.scaleenddate).format('YYYY-MM-DD')
      })
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.reportPage.excelUrl !== this.props.reportPage.excelUrl)
         this.setState({ excelUrl: nextProps.reportPage.excelUrl });
      else
         this.setState({ excelUrl: null });
   }

   changeDates = dates => {
      this.props.actions.getAdvancedDetailsSellerReport({
         id: this.props.match.params.id,
         startdate: moment(dates.scalestartdate).format('YYYY-MM-DD'),
         enddate: moment(dates.scaleenddate).format('YYYY-MM-DD'),
         scalestartdate: moment(dates.scalestartdate).format('YYYY-MM-DD'),
         scaleenddate: moment(dates.scaleenddate).format('YYYY-MM-DD')
      })

      this.setState({ dates: { scalestartdate: moment(dates.scalestartdate), scaleenddate: moment(dates.scaleenddate) } })
   }

   render() {
      const { app, actions, reportPage } = this.props
      const texts = !isEmpty(app.Texts) && app.Texts.Vendors.ReportPage[app.lang]
      const tableTexts = !isEmpty(app.Texts) && app.Texts.Table[app.lang]
      const errors = !isEmpty(app.Texts) && app.Texts.Errors[app.lang]

      if (this.state.excelUrl !== null) {
         console.log('set excel url', this.state.excelUrl)
         window.location.href = this.state.excelUrl;
      }

      const styleConfig = {
         icons: {
            TableHeadingCell: {
               sortDescendingIcon: <small>(desc)</small>,
               sortAscendingIcon: <small>(asc)</small>,
            },
         },
         classNames: {
            Table: 'table',
            TableBody: 'table__row',
            TableHeading: 'table__row',
            TableHeadingCell: 'table__item',
            Row: 'table__row'
         },
         styles: {}
      }

      //console.log('ReportPage render', this.props, reportPage.allSelected)
      return (
         <div className="vendors">
            <div className="report-list__wrapper">
               <div className="report-page">
                  <h2 className="title">{this.props.match.params.name}</h2>
                  <FilterByDates
                     texts={pick(texts, ['ScaleStart', 'TransStart', 'ScaleEnd', 'TransEnd'])}
                     changeDates={this.changeDates}
                     dates={this.state.dates} />
                  <FilterByQuery
                     texts={pick(texts, 'Search')}
                     setFilter={actions.reportPageSetFilter} />
                  <Exports
                     lang={app.lang}
                     texts={texts}
                     dates={this.state.dates}
                     exportToPDF={actions.exportToPDF}
                     exportToExcel={actions.exportToExcel}
                     isChecked={actions.reportPageIsChecked}
                     results={reportPage.results.filter(item => item.IsChecked)}
                     criterionId={this.props.match.params.id}
                     sellers={map(filter(reportPage.results, item => item.IsChecked), value => ({ Branch: value.BranchNetCode, Seller: value.SellerId }))}
                     openModal={actions.openModal} />
                  {reportPage.errorMessage && (
                     <div className="form-group">
                        <div className="text-danger">{errors[reportPage.errorMessage]}</div>
                     </div>
                  )}
                  <div className="table__wrap">
                     <Griddle
                        styleConfig={styleConfig}
                        data={reportPage.results}
                        plugins={[plugins.LocalPlugin, plugins.PositionPlugin({ tableHeight: 400 })]}
                        pageProperties={{
                           currentPage: 1,
                           pageSize: 100
                        }}
                        components={{
                           Layout: ({ Table }) => <Table />,
                           NoResults: (tableTexts) => <NoResults text={tableTexts.NoData} />
                        }}
                     >
                        <RowDefinition>
                           <ColumnDefinition
                              id='IsChecked'
                              title=' '
                              cssClassName='table__item text-center'
                              customHeadingComponent={() => {
                                 return <Checkbox
                                    name="reportPage__allSelected"
                                    checked={reportPage.allSelected}
                                    onChange={() => actions.reportPageToggleCheckAllItems()} />
                              }}
                              customComponent={enhancedWithRowData((props) => {
                                 return <Checkbox
                                    name={props.rowData.IdentificationNumber}
                                    checked={props.rowData.IsChecked}
                                    onChange={() => actions.reportPageToggleCheckItem(props.rowData.IdentificationNumber)} />
                              })}
                           />
                           <ColumnDefinition
                              id='BranchName'
                              title={texts.Branch}
                              cssClassName='table__item'
                           />
                           <ColumnDefinition
                              id='SellerId'
                              title={texts.ID}
                              cssClassName='table__item'
                           />
                           <ColumnDefinition
                              id='SellerFirstName'
                              title={texts.Name}
                              cssClassName='table__item'
                           />
                           <ColumnDefinition
                              id='PaidCounter'
                              title={texts.PaidCount}
                              cssClassName='table__item'
                           />
                           <ColumnDefinition
                              id='PaidSum'
                              title={texts.PaidSum}
                              cssClassName='table__item'
                           />
                           <ColumnDefinition
                              id='NotPaidCounter'
                              title={texts.NotPaidCount}
                              cssClassName='table__item'
                           />
                           <ColumnDefinition
                              id='NotPaidSum'
                              title={texts.NotPaidSum}
                              cssClassName='table__item'
                           />
                        </RowDefinition>
                     </Griddle>
                     {reportPage.results.length && !reportPage.fetching ? (
                        <div className="table__foot">
                           <table className="table">
                              <TableFoot
                                 row={GetTableFootRow({
                                    sumBranches: reportPage.sumBranches,
                                    TotalNotPaidCounter: reportPage.TotalNotPaidCounter,
                                    TotalNotPaidSum: reportPage.TotalNotPaidSum,
                                    TotalPaidCounter: reportPage.TotalPaidCounter,
                                    TotalPaidSum: reportPage.TotalPaidSum,
                                    onChange: actions.toggleSumBranches,
                                    texts: pick(texts, ['SumBranches', 'Summary'])
                                 })} />
                           </table>
                        </div>
                     ) : null}
                  </div>
               </div>
            </div>
         </div>
      )
   }
}

const mapStateToProps = state => ({
   app: state.App,
   reportPage: state.ReportPage,
})

const mapDispatchToProps = dispatch => ({
   actions: bindActionCreators(actions, dispatch)
})

ReportPage.defaultProps = {
   dates: {
      startdate: moment().subtract(7, 'days'),
      enddate: moment(),
      scalestartdate: moment().subtract(7, 'days'),
      scaleenddate: moment()
   }
}

ReportPage.propTypes = {
   dates: PropTypes.object.isRequired,
   reportPage: PropTypes.object.isRequired,
   actions: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage)
