import React from 'react';
import { PropTypes } from 'prop-types';
import { withFormsy } from 'formsy-react';

import DatePicker from 'react-datepicker';
// import moment from 'moment';
// import 'moment/locale/he';
// moment.locale('he')

class CustomDatePicker extends React.Component {
  
  render() {
    const { dateFormat, disabled, setValue, icon, label, value } = this.props;
    return (
      <div className="form_datepicker">
        <label className={`form__label icon-${icon}`}>{label}</label>
        <div className="form__input-wrap">
          <div className="datepicker">
            <DatePicker
              // locale='he'
              // weekdays={['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']}
              placeholderText='בחר תאריך רצוי'
              dateFormat={dateFormat || 'dd / MM / yyyy'}
              disabled={disabled}
              selected={value.toDate()}
              onChange={value => setValue(value)} />
          </div>
        </div>
      </div>
    )
  }
}

CustomDatePicker.propTypes = {
  disabled: PropTypes.bool
}

export default withFormsy(CustomDatePicker);
