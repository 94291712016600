import pick from 'lodash/pick'
import {
   convertToExcel,
   getDataListReport,
   getDataTrance
} from './Excel/forExcelReport'

export function ReportExcel(lang, texts, results) {
   let contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
   let textsObject = {}
   if (lang === 'he') {
      textsObject = pick(texts, 'NotPaidSum', 'NotPaidCount', 'PaidSum', 'PaidCount', 'Name', 'ID', 'Branch')
   } else {
      textsObject = pick(texts, 'Branch', 'ID', 'Name', 'PaidCount', 'PaidSum', 'NotPaidCount', 'NotPaidSum')
   }

   if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      window.navigator.msSaveOrOpenBlob(new Blob([convertToExcel(getDataListReport(results, textsObject, lang), textsObject)], { 'type': contentType }))
   } else {
      let blob = new Blob([convertToExcel(getDataListReport(results, textsObject, lang), textsObject)], { 'type': contentType })
      var a = document.getElementById('excel_button')
      a.href = window.URL.createObjectURL(blob)
      a.download = 'resultExcelReport.xls'
   }
}


export function ReportExcelTrans(lang, checkedData, en, he) {
   let textsObject
   let contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
   if (lang === 'he') {
      textsObject = pick(he, 'Trance', 'Paid', 'Total', 'WeightUnits', 'ProductName', 'ProductCode', 'VendorName', 'TimeCashRegister', 'DataCashRegister', 'TimeScale', 'DataScale')
   } else {
      textsObject = pick(en, 'DataScale', 'TimeScale', 'DataCashRegister', 'TimeCashRegister', 'VendorName', 'ProductCode', 'ProductName', 'WeightUnits', 'Total', 'Paid', 'Trance')
   }

   if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      window.navigator.msSaveOrOpenBlob(new Blob([convertToExcel(getDataTrance(checkedData, textsObject, lang), textsObject)], { 'type': contentType }))
   } else {
      let blob = new Blob([convertToExcel(getDataTrance(checkedData, textsObject, lang), textsObject)], { 'type': contentType })
      var a = document.getElementById('excel_button_trance')
      a.href = window.URL.createObjectURL(blob)
      a.download = 'resultExcelReport.xls'
   }
}
