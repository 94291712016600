import * as actionTypes from '../../actions/actionTypes';
import { getLocal } from '../../../utils/LocalStorage'
import customFilterFunction from '../../../utils/customFilterFunction'
import toggleCheckItem from '../../../utils/toggleCheckItem'
import toggleCheckAllItems from '../../../utils/toggleCheckAllItems'
import customSortFunction from '../../../utils/customSortFunction'
import toggleShowSelected from '../../../utils/toggleShowSelected'
import map from 'lodash/map'
import filter from 'lodash/filter'
import some from 'lodash/some'
// import merge from 'lodash/merge'
import toString from 'lodash/toString'
import moment from 'moment'

const dataName = 'pluItemList'

const initialState = {
   ItemCodes: [],
   CategoryCodes: [],
   branchList: [],
   branchListKeys: [],
   hasUpdate: false,
   reportCriterionData: {
      CreatedDate: moment(),
      UpdateDate: moment(),
      IsActive: true,
      Name: ''
   },
   [dataName]: [],
   Id: 1,
   results: [],
   // saved: [],
   allSelected: false,
   fetching: false,
   includedGroups: true,
   query: '',
   // reverse: false,
   sort: '',
   sortAscending: '',
   showSelected: false
}

export default function CreateReport(state = initialState, action) {
   //console.log('CreateReport reducer', action.type)
   switch (action.type) {
      case actionTypes.CREATE_REPORT_REQUEST:
         return { ...state, fetching: true }
      case actionTypes.CREATE_REPORT_UPDATE_SELLER_REPORT_CRITERION_DATA:
         return {
            ...state,
            hasUpdate: !state.hasUpdate
         }
      case actionTypes.CREATE_REPORT_ALLOCATE_SELLER_REPORT_CRITERION:
         return {
            ...state,
            branchList: action.payload.BranchList,
            results: action.payload.ItemCodes,
            pluItemList: action.payload.ItemCodes,
            Id: action.payload.Id,
            reportCriterionData: {
               CreatedDate: moment(),
               UpdateDate: moment(),
               IsActive: true,
               Name: ''
            },
            branchListKeys: [],
            ItemCodes: [],
            hasUpdate: '',
            showSelected: false
         }
      case actionTypes.CREATE_REPORT_GET_SELLER_REPORT_CRITERION:
         return {
            ...state,
            branchList: action.payload.BranchList,
            branchListKeys: map(filter(action.payload.BranchList, item => item.IsChecked && item.Id), item => toString(item.Id)),
            results: action.payload.ItemCodes,
            Id: action.payload.Id,
            ItemCodes: action.payload.ItemCodes,
            CategoryCodes: action.payload.CategoryCodes,
            pluItemList: action.payload.ItemCodes,
            // pluItemList: map(state.pluItemList, item => {
            //    if (some(action.ItemCodes, { Key: item.Code })) {
            //       return { ...item, IsChecked: true }
            //    } else {
            //       return item
            //    }
            // }),
            reportCriterionData: {
               ...state.reportCriterionData,
               IsActive: some(getLocal('inactive'), { Id: action.payload.Id }) ? false : action.payload.IsActive,
               Name: action.payload.Name,
               CreatedDate: moment(moment(action.payload.CreatedDate)),
               UpdateDate: moment(moment(action.payload.UpdateDate))
            },
            hasUpdate: '',
            showSelected: false
         }
      case actionTypes.CREATE_REPORT_GET_PLU_ITEM_LIST:
         return {
            ...state,
            fetching: false,
            // pluItemList: findByValues(action.payload, 'Code', [85, 87])
            pluItemList: action.payload,
            results: action.payload
            // saved: map(action.payload.slice(0, 50), item => {
            //   if (some(state.ItemCodes, {Key: item.Code})) {
            //     return {...item, IsChecked: true}
            //   } else {
            //     return {...item, IsChecked: false}
            //   }
            // })
         }
      case actionTypes.CREATE_REPORT_GET_ITEM_CATEGORY_LIST:
         return {
            ...state,
            // pluItemList: findByValues(action.payload, 'Code', [85, 87])
            // [dataName]: state.includedGroups ? filter(state[dataName], item => !item.IsGroup && item) : [...state[dataName], ...action.payload ],
            // results: state.includedGroups ? filter(state.results, item => !item.IsGroup && item) : [...state.results, ...action.payload ],
            [dataName]: state.includedGroups ? filter(state[dataName], item => !item.IsGroup && item) : [...map(action.payload, value => ({ ...value, IsGroup: true, IsChecked: some(state.CategoryCodes, { Key: value.Code }) })), ...state[dataName]],
            results: state.includedGroups ? filter(state.results, item => !item.IsGroup && item) : [...map(action.payload, value => ({ ...value, IsGroup: true, IsChecked: some(state.CategoryCodes, { Key: value.Code }) })), ...state.results],
            // saved: state.includedGroups ? filter(state.saved, item => !item.IsGroup && item) : merge(state.saved, action.payload),
            includedGroups: !state.includedGroups
         }

      case actionTypes.CREATE_REPORT_SET_BRANCH_LIST:
         return {
            ...state,
            branchList: map(state.branchList, item => {
               if (item.Id === action.id) {
                  const every = item.Departments.every(ev => ev.IsChecked)

                  return {
                     ...item, IsChecked: !every ? true : !item.IsChecked, Departments: map(item.Departments, value => {
                        return { ...value, IsChecked: !every ? true : !item.IsChecked }
                     })
                  }
               } else {
                  return item
               }
            })
         }
      case actionTypes.CREATE_REPORT_SET_BRANCH_DEPT_LIST:
         return {
            ...state,
            branchList: map(state.branchList, item => {
               if (item.Id === action.payload.parentID) {
                  return {
                     ...item,
                     IsChecked: true,
                     Departments: map(item.Departments, value =>
                        (value.Id === action.payload.childID) ? ({ ...value, IsChecked: value.IsChecked ? false : true }) : value)
                  }
               } else {
                  return item
               }
            })
         }
      case actionTypes.CREATE_REPORT_TO_CHANGE_SORT: return customSortFunction({ state, action, dataName })
      case actionTypes.CREATE_REPORT_SET_FILTER: return customFilterFunction({ state, action, dataName })
      case actionTypes.CREATE_REPORT_TOGGLE_CHECK_ITEM: return toggleCheckItem(state, dataName, 'Id', action.id, action.isGroup)
      case actionTypes.CREATE_REPORT_TOGGLE_CHECK_ALL_ITEMS: return toggleCheckAllItems(state, dataName)
      case actionTypes.CREATE_REPORT_TOGGLE_SHOW_SELECTED: return toggleShowSelected(state, dataName)

      default:
         return state
   }
}
