/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import moment from "moment";

import plus from "../../assets/images/plus@svg.svg";
import smallImagePlaceholder from "../../assets/images/image-placeholder-small.png";
import smallMoviePlaceholder from "../../assets/images/movie-placeholder-small.png";

import ViewMediaGallery from "./ViewMediaGallery";

const MediaItem = ({ insertMode, durationUpdated, image, divStyle, duration, onChange, onInsertClick, onSelectClick, onDeleteClick }) => {
    if (insertMode) {
        return (
            <li className="image-upload-btn">
                <div className="img-wrapper">
                    <a href="#" className="add-images-button">
                        <img src={plus} alt="" onClick={onInsertClick} />
                    </a>
                </div>
            </li>
        )
    }
    else {

        var imageUrl, imageClassName;
        if (image.includes('mp4')) {
            imageUrl = smallMoviePlaceholder;
            imageClassName = 'real-movie';
        }
        else if (image) {
            imageUrl = image;
            imageClassName = 'real-image';
        }
        else {
            imageUrl = smallImagePlaceholder;
            imageClassName = 'place-holder';
        }

        return (
            <li>
                <div className="img-wrapper" style={divStyle}>
                    <a href="#" onClick={onSelectClick} >
                        <img className={imageClassName} src={imageUrl} alt="" />
                    </a>
                    <a
                        href="#"
                        className="delete-images-button"
                        onClick={onDeleteClick}
                        title="מחק תמונה">
                        <i className="bolet-icon-trash" />
                    </a>
                </div>
                <div className="time-select">
                    {moment.utc(duration * 1000).format('mm:ss')}
                </div>
            </li>
        )
    }
}

class ViewMedia extends Component {

    constructor(props) {
        super(props);

        this.state = {
            playlistItems: props.data.filter(item => item.Display.type === 'playlist'),
            showMediaGalleryPopup: false,
            originalBodyOverflow: document.body.style.overflow,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ playlistItems: nextProps.data.filter(item => item.Display.type === 'playlist') });
    }

    handleMediaGalleryShow = () => {
        this.setState({ showMediaGalleryPopup: true });
    }

    handleMediaGalleryClosed = () => {
        this.setState({ showMediaGalleryPopup: false });
    }

    handleMediaGallerySubmited = (data, e) => {
        this.props.onClick('update-playlist', data);
        this.setState({ showMediaGalleryPopup: false });
    }

    editDurationInput = (e) => {
        e.preventDefault();
        document.getElementsByClassName(".duration-read").removeAttribute('readonly');
    }

    render() {
        const {
            playlistItems,
            showMediaGalleryPopup,
        } = this.state;

        const {
            currentIndex,
            data,
            onClick
        } = this.props;

        if (playlistItems === undefined)
            return null;

        return (
            <div>
                <div className="col-md-7 col-xs-12">
                    <div className="images-gallery-container">
                        <h2>פריטים בקבוצה</h2>
                        <div className="small-images-container">
                            <ul>
                                {playlistItems.map((item, index) => (() => {

                                    var divStyle = { border: 'none' };
                                    if (data[currentIndex].Id === item.Id)
                                        divStyle = { border: '3px solid black' };

                                    return (
                                        <MediaItem key={index}
                                            divStyle={divStyle}
                                            insertMode={false}
                                            image={item.Display.image}
                                            duration={item.Display.duration.value}
                                            onDeleteClick={() => onClick('delete-playlist-item', item.Id)}
                                            onSelectClick={() => onClick('update-current-index', item.Id)}
                                        />
                                    )
                                })())}

                                <MediaItem
                                    insertMode={true}
                                    onInsertClick={this.handleMediaGalleryShow}
                                />
                            </ul>
                        </div>
                    </div>
                </div>
                <ViewMediaGallery
                    visible={showMediaGalleryPopup}
                    singleSelection={true}         // TODO: support multi selection
                    //media={media.filter(x => !x.url.includes('logo'))}
                    imageFilter='!logo'
                    onClose={this.handleMediaGalleryClosed}
                    onSubmit={this.handleMediaGallerySubmited}
                />
            </div>
        )
    }
}

ViewMedia.propTypes = {
    checked: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
}

ViewMedia.defaultProps = {
    checked: false
}

function mapStateToProps(state) {
    return {
        media: state.App.Media,
    };
}

export default connect(
    mapStateToProps,
)(ViewMedia);
