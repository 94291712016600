import React, { Component } from 'react';
import { connect } from 'react-redux';

import TemplateDiscountImage from '../../assets/images/template_discount.svg';
import TemplateBuygetImage from '../../assets/images/template_buyget.svg';
import TemplatePriceImage from '../../assets/images/template_price.svg';
import AdvColorPicker from '../../containers/Advertising/AdvColorPicker';
import ViewMediaGallery from './ViewMediaGallery';

class SelectNewTemplatePopup extends Component {

    state = {
        templateData: {},
        selectedImage: {},
        ShowMediaGalleryPopup: false,
        showSaveButton: false,
        templateId: 0,
    };

    componentDidMount() {
        this.selectTemplate(0);
    }

    componentWillReceiveProps() {
        const { selected } = this.props;

        if (selected !== undefined) {

            if (selected.Template.id === -1) {
                this.setState({ showSaveButton: false });
                this.selectTemplate(0);
            }
            else {
                this.selectTemplate(selected.Template.id);
                this.validateTemplate();
            }

            this.setState(prevState => ({
                ShowMediaGalleryPopup: false,
                templateData: {
                    ...prevState.templateData,
                    companyLogo: selected.Template.images.companyLogo,
                    supplierLogo: selected.Template.images.supplierLogo,
                    textColor: selected.Style.color,
                    backgroundColor: selected.Style.backgroundColor,
                    bubbleColor: selected.Style.bubbleColor,
                    texts: selected.Template.texts,
                }
            }));

        }
    }

    selectTemplate = (index) => {
        const data = [
            {
                Id: 0,
                Name: 'בחר תבנית..',
                Texts: [],
                Images: [],
                Colors: []
            },
            {
                Id: 1,
                Name: 'קנה/קבל | קנה/שלם',
                Texts: [
                    { value: 'קנה כמות של', placeHolder: 'הכנס ערך..', type: 1 },
                    { value: 'שלם על כמות של', placeHolder: 'הכנס ערך..', type: 2 },
                    { value: 'הערה', placeHolder: 'הכנס ערך..', type: 3 },
                ],
                Images: [
                    { value: 'לוגו ספק', type: 'supplier-logo' },
                    { value: 'לוגו חברה', type: 'company-logo' },
                ],
                Colors: [
                    { value: 'צבע גופן', default: 'FFCC00', type: 'text-color' },
                    { value: 'צבע רקע', default: 'E34912', type: 'background-color' },
                ],
            },
            {
                Id: 2,
                Name: 'הנחה',
                Texts: [
                    { value: 'שורה 1', placeHolder: 'הכנס ערך..', type: 1 },
                    { value: 'שורה 2', placeHolder: 'הכנס ערך..', type: 2 },
                    { value: 'שורה 3', placeHolder: 'הכנס ערך..', type: 3 },
                    { value: 'הערה', placeHolder: 'הכנס ערך..', type: 4 },
                ],
                Images: [
                    { value: 'לוגו ספק', type: 'supplier-logo' },
                    { value: 'לוגו חברה', type: 'company-logo' },
                ],
                Colors: [
                    { value: 'צבע גופן', default: 'A0DDE0', type: 'text-color' },
                    { value: 'צבע רקע', default: 'ff6600', type: 'background-color' },
                    { value: 'צבע בועות', default: 'FFD791', type: 'bubble-color' },
                ]
            },
            {
                Id: 3,
                Name: 'מוצר וכמות',
                Texts: [
                    { value: 'הערה', placeHolder: 'הכנס ערך..', type: 1 },
                ],
                Images: [
                    { value: 'לוגו חברה', type: 'company-logo' },
                ],
                Colors: [
                    { value: 'צבע גופן', default: 'd63424', type: 'text-color' },
                    { value: 'צבע רקע', default: '00829b', type: 'background-color' },
                ],
            },
        ];
        this.setState(prevState => ({
            templateData: {
                ...prevState.templateData,
                texts: data[index].Texts.map(text => ''),
                view: data[index]
            },
            templateId: index,
        }), () => {
            this.validateTemplate();
        });
    }

    selectImage = (image) => {
        this.setState({
            ShowMediaGalleryPopup: true,
            selectedImage: image,
        });
    }

    handleMediaGalleryClosed = (e) => {
        this.setState({ ShowMediaGalleryPopup: false });
    }

    handleMediaGallery = (item) => {
        const { selectedImage } = this.state;
        switch (selectedImage.type) {
            case 'supplier-logo':
                this.setState(prevState => ({
                    ShowMediaGalleryPopup: false,
                    templateData: {
                        ...prevState.templateData,
                        supplierLogo: item.url
                    }
                }));
                break;

            case 'company-logo':
                this.setState(prevState => ({
                    ShowMediaGalleryPopup: false,
                    templateData: {
                        ...prevState.templateData,
                        companyLogo: item.url
                    }
                }));
                break;

            default:
                break;
        }
        this.setState({});
    }

    handleColorSelection = (id, color) => {
        switch (id) {
            case 0:
                this.setState(prevState => ({
                    templateData: {
                        ...prevState.templateData,
                        textColor: color
                    }
                }));
                break;

            case 1:
                this.setState(prevState => ({
                    templateData: {
                        ...prevState.templateData,
                        backgroundColor: color
                    }
                }));
                break;

            case 2:
                this.setState(prevState => ({
                    templateData: {
                        ...prevState.templateData,
                        bubbleColor: color
                    }
                }));
                break;

            default:
                break;
        }
        this.setState({});
    }

    handleImageClick = (type, image) => {

        switch (type) {

            case 'edit-media':
                this.selectImage(image);
                break;

            case 'del-media':
                console.log('del template image')

                switch (image.type) {
                    case 'supplier-logo':
                        this.setState(prevState => ({
                            templateData: {
                                ...prevState.templateData,
                                supplierLogo: null,
                                imageType: ''
                            }
                        }));
                        break;

                    case 'company-logo':
                        this.setState(prevState => ({
                            templateData: {
                                ...prevState.templateData,
                                companyLogo: null,
                                imageType: ''
                            }
                        }));
                        break;

                    default:
                        break;
                }
                break;

            default:
                break;
        }
    }

    validateTemplate = () => {
        const { templateData, templateId } = this.state;

        switch (templateId) {
            case 1:
                if (templateData.texts[0].length !== 0 && templateData.texts[1].length !== 0)
                    this.setState({ showSaveButton: true });
                else if (templateData.texts[0].length === 0 || templateData.texts[1].length === 0)
                    this.setState({ showSaveButton: false });
                break;

            case 2:
            case 3:
                if (templateData.texts[0].length !== 0)
                    this.setState({ showSaveButton: true });
                else if (templateData.texts[0].length === 0)
                    this.setState({ showSaveButton: false });
                break;
        }

    }

    handleTextChange = (index, e) => {
        const { templateData, templateId } = this.state;
        let texts = templateData.texts;
        texts[index] = e.target.value;

        this.validateTemplate();

        this.setState(prevState => ({
            templateData: {
                ...prevState.templateData,
                texts: texts
            },
        }));
    }

    hexToRGB = function (hex) {
        var r = hex >> 16;
        var g = (hex >> 8) & 0xFF;
        var b = hex & 0xFF;
        var a = 1;
        return { r, g, b, a };
    }

    render() {

        const {
            visible,
            onClick,
            media,
        } = this.props;
        const {
            templateData,
            ShowMediaGalleryPopup,
            showSaveButton,
        } = this.state;

        if (!visible)
            return null;

        var textFields = templateData.view.Texts.map((text, index) => (() => {
            return (
                <div key={index} className="name-box">
                    <input type="text" placeholder={text.placeHolder} value={templateData.texts[index]} onChange={(e) => this.handleTextChange(index, e)} />
                    <label className="label">{text.value}</label>
                </div>
            )
        })());
        var imageFields = templateData.view.Images.map((image, index) => (() => {
            if (image.type === 'supplier-logo' && templateData.supplierLogo !== undefined && templateData.supplierLogo !== null) {
                return (
                    <div key={index} className='image-wrapper'>
                        <label>
                            {image.value} &nbsp;&nbsp;&nbsp;&nbsp;
                            <button className="bolet-icon-edit" onClick={() => this.handleImageClick('edit-media', image)}></button>
                            <button className="bolet-icon-trash" onClick={() => this.handleImageClick('del-media', image)}></button>
                            <img className="image-box" alt="" src={templateData.supplierLogo} />
                        </label>
                    </div>
                )
            }
            else if (image.type === 'company-logo' && templateData.companyLogo !== undefined && templateData.companyLogo !== null) {
                return (
                    <div key={index} className='image-wrapper'>
                        <label>
                            {image.value} &nbsp;&nbsp;&nbsp;&nbsp;
                            <button className="bolet-icon-edit" onClick={() => this.handleImageClick('edit-media', image)}></button>
                            <button className="bolet-icon-trash" onClick={() => this.handleImageClick('del-media', image)}></button>
                            <img className="image-box" alt="" src={templateData.companyLogo} />
                        </label>
                    </div>
                )
            }
            else {
                return (
                    <div key={index}>
                        <a className="sale-add-link" onClick={(e) => this.selectImage(image)}>{'+ הוסף ' + image.value}</a>
                    </div>
                )
            }
        })());
        var colorFields = templateData.view.Colors.map((color, index) => (
            <AdvColorPicker key={index}
                title={color.value}
                id={index}
                color={this.hexToRGB(parseInt(color.default, 16))}
                onSelect={this.handleColorSelection}
            />
        ));

        return (
            <div className="template-selection popup shown-popup">
                <div className="pop-up-wrapper">
                    <h2> הוספת תבנית</h2>
                    {/* <h1>בחירת סוג תבנית</h1> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-xs-12 images-box">
                                <div id="c1">
                                    <button onClick={() => this.selectTemplate(1)} >
                                        <img alt="" src={TemplateBuygetImage} />
                                    </button>
                                    <label>קנה/קבל | קנה/שלם</label>
                                </div>
                                <div id="c2">
                                    <button onClick={() => this.selectTemplate(2)}>
                                        <img alt="" src={TemplateDiscountImage} />
                                    </button>
                                    <br />
                                    <label>הנחה</label>
                                </div>
                                <div id="c3">
                                    <button onClick={() => this.selectTemplate(3)}>
                                        <img alt="" src={TemplatePriceImage} />
                                    </button>
                                    <label>מוצר ומחיר</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-xs-12 fields-box">
                                <h3>{templateData.view.Name}</h3>
                                {textFields}
                                {/* {colorFields} */}

                                {/* <input name="name" className="name-box" type="text" placeholder="שם המוצר.." />
                                <textarea className="remark-box" placeholder='הוספת הערות..'
                                /> */}
                            </div>
                            <div className="col-md-5 col-xs-12 fields-box">
                                <div className="sale-images">
                                    {imageFields}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pop-up-buttons">
                        {showSaveButton &&
                            <button className="green-btn" onClick={() => onClick('update-template', templateData)}>שמור</button>
                        }
                        <button className="grey-btn" onClick={() => onClick('hide-template-selection')}>ביטול</button>
                    </div>
                </div>
                <ViewMediaGallery
                    visible={ShowMediaGalleryPopup}
                    singleSelection={true}
                    onlyImages={true}
                    imageFilter='logo'
                    minImageWidth='150'
                    minImageHeight='130'
                    hideAddingImages
                    onClose={this.handleMediaGalleryClosed}
                    onSubmit={this.handleMediaGallery}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        media: state.App.Media,
    };
}

export default connect(
    mapStateToProps,
)(SelectNewTemplatePopup);
