
import React, { Component } from 'react';
import { connect } from 'react-redux';
import pick from 'lodash/pick'
import { bindActionCreators } from 'redux';
import ReactTable from "react-table";
import Select from 'react-select';

import classes from './ScaleGroups.module.scss';

import plus from "../../assets/images/plus@svg.svg";
import AdvSaleNamePopup from '../Advertising/AdvSaleNamePopup';

import http from '../../utils/Http';
import * as actions from '../../store/actions/index';

import AdvDeleteItemPopup from '../../components/advertising/AdvDeleteItemPopup';
import { tsPropertySignature } from '@babel/types';

const Configuration = require('../../config.json');

class ScaleGroups extends Component {

   state = {
      data: [],
      groups: [],
      branches: [],
      departments: [],
      columns: [],
      selectedGroup: undefined,
      fields: {},
      deleteId: 0,
      deleteGroupId: 0,
      deleteSource: '',
      showDeletePopup: false,
      showSaveButton: false,
      showInsertGroupPopup: false,
      saveChanges: false,
      pageSize: 10,
      activeModules: Configuration.activeModules,
   };

   async componentDidMount() {
      const type = 4;
      const groups = await http('GetGroups', 'GET', { type });
      const branches = await http('GetBranches', 'GET');
      const departments = await http('GetDepartments', 'GET');

      let branchesOption = [];
      branches.forEach(branch => { branchesOption.push({ value: branch.id, label: branch.name }) });

      let departmentsOption = [];
      departments.forEach(department => { departmentsOption.push({ value: department.id, label: department.name }) });

      this.setState({ groups, branches, departments, branchesOption, departmentsOption });
   }

   // componentWillReceiveProps = (nextProps) => {
   //    if (nextProps.Groups !== undefined)
   //       this.setState({ groups: nextProps.Groups });
   // }

   async handleSumbit(e) {
      let { selectedGroup, fields, data } = this.state;
      const group = { ...selectedGroup, oldId: selectedGroup.id, fields, data };

      this.props.actions.UpdateGroup(group);
      this.setState({ showSaveButton: false, saveChanges: false });
   }

   handleFieldChange = (field, e) => {
      const { selectedGroup } = this.state;
      selectedGroup[field] = e.target.value;
      this.setState({ showSaveButton: true, selectedGroup });
   }

   saveButtonStyle = {
      width: '200px',
   };

   handleClick = (selectedGroup) => { // select group, show group details

      const data = [];
      let index = 1;
      selectedGroup.branches.forEach(branch => {
         branch.departments.forEach(department => {
            data.push({ deleteId: index, branch: branch.name, department: department.name });
            index++;
         })
      })
      this.setState({ selectedGroup, data, pageSize: data.length + 3 });
   }

   GroupParameters = (selectedGroup, saveChanges, onChange) => {

      const typeOptions = [
         { value: 1, name: "", title: "פרסום" },
         { value: 2, name: "", title: "מבצעים ודרבון" },
      ]

      if (selectedGroup === undefined)
         return null;

      return (
         <div className="group__container">
            <div className="group__buttons">
               <button className={`green-btn ${classes.users__button}`} onClick={(e) => this.addRow(e)}>הוסף סניף לקבוצה</button>
               <button className={`${saveChanges ? 'green-btn' : 'grey-btn'} ${classes.users__button}`} onClick={(e) => this.handleSumbit(e)}>שמירת נתונים</button>
            </div>
            <div className="group__details">
               <div className="group__row">
                  <div className="group__row-item">
                     <label htmlFor="name">שם</label>
                     <input
                        type="text"
                        className="form-control group__item--large"
                        name="name"
                        id="name"
                        value={selectedGroup["name"]}
                        onChange={(e) => onChange("name", e)}
                     />
                  </div>
                  <div className="group__row-item">
                     <label htmlFor="type">סיווג</label>
                     <select
                        name="type"
                        id="type"
                        className="form-control"
                        value={selectedGroup["type"]}
                        onChange={(e) => onChange("type", e)}
                     >
                        {typeOptions.map((option, index) =>
                           <option key={index} value={option.value}>{option.title}</option>
                        )}
                     </select>
                  </div>
               </div>
            </div>
         </div >
      )
   }

   showInsertGroupPopup = () => {
      this.setState({ showInsertGroupPopup: true });
   }

   hideInsertGroupPopup = () => {
      this.setState({ showInsertGroupPopup: false });
   }

   handleInsertGroupPopup = (fields) => {
      console.log('handleInsertGroupPopup', fields, fields['type'])
      const name = fields['name'];
      const type = fields['type'] === undefined ? 1 : fields['type'];
      if (this.state.groups.find(x => x.id === Number(fields['id'])) === undefined)
         this.props.actions.AddGroup(name, type);
      this.setState({ showInsertGroupPopup: false });
   }

   deleteGroup = (e, id) => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ showDeletePopup: true, deleteGroupId: id, deleteSource: 'group' });
   }

   addRow = e => {
      const data = this.state.data;
      data.push({ deleteId: data.length + 1, name: '' });
      console.log('add row click', data.length)
      this.setState({ data, pageSize: data.length + 3 });
   }

   deleteRow = deleteId => {
      this.setState({ showDeletePopup: true, deleteId, deleteSource: 'item' });
   }

   handlePopupDelete = (type, e) => {
      const { deleteSource, deleteGroupId } = this.state;
      if (type === 'delete' && deleteSource === 'item') {
         const data = this.state.data.filter(x => x.deleteId !== this.state.deleteId);
         this.setState({ data });
      }
      else if (type === 'delete' && deleteSource === 'group') {
         this.props.actions.DeleteGroup(deleteGroupId);
         this.setState({ selectedGroup: undefined });
      }
      this.setState({ showDeletePopup: false });
   }

   handleRowBranchChange = (e, cellInfo) => {
      const rows = this.state.data.map((row, index) => {
         if (cellInfo.index === index) {
            return ({
               ...row,
               branch: e.label
            })
         }
         return row;
      })

      this.setState({ data: rows, pageSize: rows.length + 3, saveChanges: true });
   }

   handleRowDepartmentChange = (e, cellInfo) => {
      const rows = this.state.data.map((row, index) => {
         if (cellInfo.index === index) {
            return ({
               ...row,
               department: e.label
            })
         }
         return row;
      })

      this.setState({ data: rows, saveChanges: true });
   }

   renderEditable = cellInfo => {
      return (
         <div
            className={classes.vertical_center}
            contentEditable
            suppressContentEditableWarning
            onBlur={e => {
               const data = [...this.state.data];
               data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
               this.setState({ data, saveChanges: true });
            }}
            dangerouslySetInnerHTML={{
               __html: this.state.data[cellInfo.index][cellInfo.column.id]
            }}
         />
      );
   }

   render() {

      const {
         showInsertGroupPopup,
         selectedGroup,
         groups,
         departmentsOption,
         branchesOption,
         showDeletePopup,
         deleteSource,
         saveChanges,
         pageSize,
         data,
         activeModules,
      } = this.state;

      const backgroundStyle = [
         '',
         '',
         ''
      ]
      const groupHeader = [
         'פרסום',
         'מבצעים ודרבון',
      ]
      //console.log('ScaleGroups render', data, selectedGroup)
      const sortedGroups = activeModules.includes('promotion')
         ? groups.sort((a, b) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : 0))
         : groups.filter(x => x.type === 1).sort((a, b) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : 0));

      /*
      https://medium.com/@diegocasmo/a-simple-react-tabs-component-47cac2cfbb5
      
      <Tabs>
          <Tab iconClassName={'icon-class-0'} linkClassName={'link-class-0'}>
              <p>content 0</p>
          </Tab>
          <Tab iconClassName={'icon-class-1'} linkClassName={'link-class-1'}>
              <CustomComponent propA={'foo'} propB={this.handleSomething}/>
          </Tab>
      </Tabs>
      */
      let currentGroupType = -1;
      return (
         <section className="managment-system">
            <div className="container">
               <div className="row">
                  <div className="col-md-2 col-xs-12">
                     <div className="header-wrapper">
                        <h2>קבוצות משקלים
                        <a href="#" onClick={this.showInsertGroupPopup}>
                              <i className="plus-icon"><img src={plus} alt="" /></i>
                           </a>
                        </h2>
                     </div>
                     <div className="groups-wrapper">
                        <div className="items-wrapper">
                           {sortedGroups && sortedGroups.map((group, index) => {
                              if (currentGroupType === -1 || currentGroupType !== group.type) {
                                 currentGroupType = group.type;

                                 return (
                                    <>
                                       <p className='items-header'>{groupHeader[group.type - 1]}</p>
                                       <div
                                          key={index}
                                          className={selectedGroup !== undefined && group.id === selectedGroup.id ? 'item-selected' : 'item'}
                                          style={{ backgroundColor: backgroundStyle[group.type - 1] }}
                                          onClick={() => this.handleClick(group)} >
                                          <span>{group.id + ' | ' + group.name}</span>
                                          <button key={index + 'trash'} className="bolet-icon-trash" id={group.id} onClick={(e) => this.deleteGroup(e, group.id)}></button>
                                       </div>
                                    </>
                                 )
                              }

                              return (
                                 <div
                                    key={index}
                                    className={selectedGroup !== undefined && group.id === selectedGroup.id ? 'item-selected' : 'item'}
                                    style={{ backgroundColor: backgroundStyle[group.type - 1] }}
                                    onClick={() => this.handleClick(group)} >
                                    <span>{group.id + ' | ' + group.name}</span>
                                    <button key={index + 'trash'} className="bolet-icon-trash" id={group.id} onClick={(e) => this.deleteGroup(e, group.id)}></button>
                                 </div>
                              )
                           })}
                        </div>
                     </div>
                  </div>

                  <div className="col-md-3 col-xs-12">
                     {selectedGroup &&
                        <>
                           {this.GroupParameters(selectedGroup, saveChanges, this.handleFieldChange)}
                           < ReactTable
                              data={data}
                              noDataText="אין נתונים להצגה.."
                              previousText="הקודם"
                              nextText="הבא"
                              pageText="דף"
                              ofText="/"
                              columns={[
                                 {
                                    Header: "",
                                    accessor: "deleteId",
                                    width: 30,
                                    Cell: row => {
                                       return (
                                          <div className={classes.vertical_center}>
                                             <a href="#" className={`bolet-icon-trash align-center`} onClick={() => this.deleteRow(row.row.deleteId)}> </a>
                                          </div>
                                       )
                                    }
                                 },
                                 {
                                    accessor: 'branch',
                                    Header: 'סניף',
                                    Cell: row => {
                                       return (
                                          <Select
                                             options={branchesOption}
                                             placeholder='בחר סניף..'
                                             searchable={false}
                                             className='react-select-container'
                                             classNamePrefix='react-select'
                                             value={branchesOption.find(x => x.label === row.value)}
                                             onChange={(e) => this.handleRowBranchChange(e, row)}
                                          />
                                       )
                                    }
                                 },
                                 {
                                    accessor: 'department',
                                    Header: 'מחלקה',
                                    Cell: row => {
                                       return (
                                          <Select
                                             options={departmentsOption}
                                             placeholder='בחר מחלקה..'
                                             searchable={false}
                                             className='react-select-container'
                                             classNamePrefix='react-select'
                                             value={departmentsOption.find(x => x.label === row.value)}
                                             onChange={(e) => this.handleRowDepartmentChange(e, row)}
                                          />
                                       )
                                    }
                                 },
                              ]}
                              showPageSizeOptions={false}
                              showPagination={false}
                              pageSize={pageSize}
                              getTrProps={(state, rowInfo, column) => {
                                 return {
                                    style: {
                                       height: '50px'
                                    }
                                 };
                              }}
                              className="-striped -highlight"
                           />
                        </>
                     }
                  </div>
               </div>
               <AdvSaleNamePopup
                  visible={showInsertGroupPopup}
                  type="new-group"
                  onAccept={this.handleInsertGroupPopup}
                  onClose={this.hideInsertGroupPopup}
               />
               <AdvDeleteItemPopup
                  visible={showDeletePopup}
                  title={deleteSource === 'group' ? 'למחוק קבוצה?' : 'למחוק פריט בקבוצה?'}
                  onClick={this.handlePopupDelete}
               />

            </div>
         </section>
      )
   }
}

function mapStateToProps(state) {
   return {
      Groups: state.App.Groups,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: bindActionCreators(actions, dispatch)
   }
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ScaleGroups);
