
import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import moment from "moment";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import produce from "immer"
import Http from '../../utils/Http';

import ScalesGroups from '../../components/ScalesGroups';
import AdvDeleteItemPopup from '../../components/advertising/AdvDeleteItemPopup';
import AdvSaleNamePopup from '../../containers/Advertising/AdvSaleNamePopup';
import ViewSaleList from '../../components/advertising/ViewSaleList';
import ViewDetails from './ViewDetails';
import ViewTemplatePopup from './ViewTemplatetPopup';
import SaleUserTextPopup from './SaleUserTextPopup';
import ViewSaleDetails from './ViewSaleDetails';
import ViewSaleItems from './ViewSaleItems';
import ViewMediaGallery from './ViewMediaGallery';
import SelectNewTemplatePopup from './SelectNewTemplatePopup';

function UserView(props) {

   if (props.UserText === '')
      return (
         <div>
            <h2>צד מוכרן</h2>
            <a className="sale-add-link" onClick={(e) => props.onClick('add-message', e)}>+  הוסף  הודעה  למוכרן</a>
         </div>
      );
   else {
      const blocksFromHTML = convertFromHTML(props.UserText);
      const contentState = ContentState.createFromBlockArray(blocksFromHTML);
      const editorState = EditorState.createWithContent(contentState);

      return (
         <div>
            <h2>צד מוכרן
               <button style={{ position: 'relative', right: '20px' }} className="bolet-icon-edit" onClick={(e) => props.onClick('edit-message', e)}></button>
               <button style={{ position: 'relative', right: '40px' }} className="bolet-icon-trash" onClick={(e) => props.onClick('del-message', e)}></button>
            </h2>
            <Editor
               editorState={editorState}
               toolbarHidden="true"
               readOnly="true"
               wrapperClassName="readonly-editor-wrapper"
               editorClassName="editor"
            />
         </div>
      );
   }
}

class Sales extends Component {

   constructor(props) {
      super(props)

      this.state = {
         showDeleteItemPopup: false,
         showSaleNamePopup: false,
         showMediaGalleryPopup: false,
         showAddTemplatePopup: false,
         showAddItemsPopup: false,
         showEditUserTextPopup: false,
         showSaveButton: false,
         saleNamePopupType: 'new-sale',
         deleteItemTitle: '',
         deleteType: '',
         deleteIndex: 0,
         currentIndex: -1,
         selectedGroupId: -1,
      }
   }

   async componentDidMount() {
      // if (this.props.media === undefined)
      //    this.props.actions.GetMedia();
      const items = await Http(`GetCatalogItems`, 'GET');
      this.props.actions.saleItemsSet(items);
   }

   //TODO: change currentIndex into selectedGroupId
   componentWillUnmount = () => {
      this.props.actions.UpdateSelectedGroup(null);
   }

   static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.selectedGroupId !== prevState.selectedGroupId)
         return { selectedGroupId: nextProps.selectedGroupId, currentIndex: -1 };
      else return null;
   }

   itemSelect = async (id) => {
      const { actions, selectedPromo, saleItems } = this.props;
      const actualIndex = selectedPromo.findIndex(x => x.Id === id);
      this.setState({ currentIndex: actualIndex });
      if (saleItems.showSelected) {
         actions.saleItemsToggleShowSelected();
         // TODO: set checkbox value
      }
      actions.saleItemsUncheckAllItems();
      selectedPromo[actualIndex].Items.forEach(item =>
         actions.saleItemsToggleCheckItem(item.id));
   }

   showSaleNamePopup = () => {
      this.setState({ saleNamePopupType: 'new-sale', showSaleNamePopup: true });
   }

   hideSaleNamePopup = () => {
      this.setState({ showSaleNamePopup: false });
   }

   itemDelete = (id) => {
      var { selectedPromo } = this.props;
      let index = selectedPromo.findIndex(x => x.Id === id);

      var title = selectedPromo[index] && 'למחוק פריט ' + selectedPromo[index].Name;
      console.log('itemDelete', selectedPromo[index], index);
      this.setState({
         deleteIndex: index,
         deleteItemTitle: title,
         deleteType: 'del-sale',
         showDeleteItemPopup: true
      });
   }

   itemEdit = () => {
      this.setState({ saleNamePopupType: 'edit-sale-name', showSaleNamePopup: true });
   }

   handleItemDelete = (type, e) => {
      if (type === 'delete') {
         const { deleteIndex, currentIndex, deleteType } = this.state;
         const { actions } = this.props;
         let selectedPromo = undefined;

         //console.log('handleItemDelete', {type,deleteType,deleteIndex:deleteIndex, currentIndex:currentIndex, sale: selectedPromo[currentIndex], saleToDelete: selectedPromo[0]})

         switch (deleteType) {
            case 'del-sale':
               actions.deleteAdvertising(this.props.selectedPromo[deleteIndex].Group, this.props.selectedPromo[deleteIndex].Id);
               this.setState({ currentIndex: -1 });
               break;
            case 'del-message':
               selectedPromo = produce(this.props.selectedPromo, draft => {
                  draft[currentIndex].UserText = '';
               });
               break;
            case 'del-template':
               selectedPromo = produce(this.props.selectedPromo, draft => {
                  draft[currentIndex].Template.id = -1;
               });
               break;
            case 'del-media':
               selectedPromo = produce(this.props.selectedPromo, draft => {
                  draft[currentIndex].Display.image = '';
               });
               break;
            case 'del-movie':
               selectedPromo = produce(this.props.selectedPromo, draft => {
                  draft[currentIndex].Display.image = '';
               });
               break;

            default:
               break;
         }
         if (selectedPromo !== undefined)
            actions.refreshAdvertising(selectedPromo);
      }
      this.setState(prevState => ({
         deleteIndex: 0,
         showDeleteItemPopup: false
      }));
   }

   handleDetailsClick = (type, data = null) => {

      const { currentIndex } = this.state;
      const { actions } = this.props;

      let selectedPromo = undefined;

      switch (type) {
         case 'select-all-dates':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].View.selectDates = false;
            });
            break;

         case 'select-specific-dates':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].View.selectDates = true;
            });
            break;

         case 'select-all-days':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].View.selectDays = false;
            });
            break;

         case 'select-specific-days':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].View.selectDays = true;
            });
            break;

         case 'select-all-hours':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].View.selectHours = false;
            });
            break;

         case 'select-specific-hours':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].View.selectHours = true;
            });
            break;

         case 'set-date':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].View.startDate = data.startDate && moment(data.startDate).format("YYYY-MM-DD");
               draft[currentIndex].View.endDate = data.endDate && moment(data.endDate).format("YYYY-MM-DD");
            });
            break;

         case 'set-days':
            let days = this.props.selectedPromo[currentIndex].View.days;
            switch (data.name) {
               case 'day-1':
                  if (data.value)
                     days |= 0x01;
                  else
                     days &= 0xfe;
                  break;

               case 'day-2':
                  if (data.value)
                     days |= 0x02;
                  else
                     days &= 0xfd;
                  break;

               case 'day-3':
                  if (data.value)
                     days |= 0x04;
                  else
                     days &= 0xfb;
                  break;

               case 'day-4':
                  if (data.value)
                     days |= 0x08;
                  else
                     days &= 0xf7;
                  break;

               case 'day-5':
                  if (data.value)
                     days |= 0x10;
                  else
                     days &= 0xef;
                  break;

               case 'day-6':
                  if (data.value)
                     days |= 0x20;
                  else
                     days &= 0xdf;
                  break;

               case 'day-7':
                  if (data.value)
                     days |= 0x40;
                  else
                     days &= 0xbf;
                  break;

               default:
                  break;
            }

            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].View.days = days;
            });
            break;

         case 'set-start-hour':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].View.startHour = data;

               if (draft[currentIndex].View.startHour !== null && draft[currentIndex].View.endHour !== null &&
                  parseInt(draft[currentIndex].View.startHour.split(':')[0]) > parseInt(draft[currentIndex].View.endHour.split(':')[0]))
                  draft[currentIndex].View.endHour = draft[currentIndex].View.startHour;
            });
            break;

         case 'set-end-hour':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].View.endHour = data;

               if (draft[currentIndex].View.startHour !== null && draft[currentIndex].View.endHour !== null &&
                  parseInt(draft[currentIndex].View.startHour.split(':')[0]) > parseInt(draft[currentIndex].View.endHour.split(':')[0]))
                  draft[currentIndex].View.endHour = draft[currentIndex].View.startHour;
            });
            break;

         case 'add-media':
            this.setState({ showMediaGalleryPopup: true });
            break;

         case 'add-template':
            this.setState({ showAddTemplatePopup: true });
            break;

         case 'add-template-buyget':
            this.setState({
               showAddTemplatePopup: false,
               showBuygetTemplatePopup: true,
            });
            break;

         case 'add-template-price':
            this.setState({
               showAddTemplatePopup: false,
               showPriceTemplatePopup: true,
            });
            break;

         case 'add-template-discount':
            this.setState({
               showAddTemplatePopup: false,
               showDiscountTemplatePopup: true,
            });
            break;

         case 'add-items':
            this.setState({ showAddItemsPopup: true });
            break;

         case 'add-message':
            this.setState({ showEditUserTextPopup: true });
            break;

         case 'hide-user-text':
            this.setState({ showEditUserTextPopup: false });
            break;

         case 'update-user-text':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].UserText = data;
            });
            this.props.actions.UpdateUserText(data);
            this.setState({ showEditUserTextPopup: false, showSaveButton: true });
            break;

         case 'hide-items-selection':
            this.setState({ showAddItemsPopup: false });
            break;

         case 'update-items-selection':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].Items = data;
            });
            this.setState({ showAddItemsPopup: false, showSaveButton: true });
            break;

         case 'update-template-type':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].Template.type = data;
            });
            this.setState({ showSaveButton: true });
            break;

         case 'update-items':
            this.setState({ showSaveButton: true });
            break;

         case 'edit-items':
            this.setState({ showAddItemsPopup: true });
            break;

         case 'edit-message':
            this.setState({ showEditUserTextPopup: true });
            break;

         case 'del-message':
            this.setState({
               deleteIndex: -1,
               deleteItemTitle: 'למחוק את ההודעה',
               deleteType: type,
               showDeleteItemPopup: true
            });
            break;

         case 'edit-template':
            this.setState({ showAddTemplatePopup: true });
            break;

         case 'edit-media':
            this.setState({ showMediaGalleryPopup: true });
            break;

         case 'set-duration':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].Display.duration = data;
            });
            break;

         case 'del-template':
         case 'del-media':
         case 'del-movie':
            this.setState({
               deleteIndex: -1,
               deleteItemTitle: 'למחוק את המידע המוצג בצד לקוח',
               deleteType: type,
               showDeleteItemPopup: true
            });
            break;

         case 'update-template':
            selectedPromo = produce(this.props.selectedPromo, draft => {
               draft[currentIndex].Template.id = data.view.Id;
               draft[currentIndex].Template.images = { supplierLogo: data.supplierLogo, companyLogo: data.companyLogo };
               draft[currentIndex].Template.my_images = { supplierLogo: data.supplierLogo, companyLogo: data.companyLogo };
               draft[currentIndex].Template.texts = data.texts;
               draft[currentIndex].Style.textColor = data.textColor;
               draft[currentIndex].Style.backgroundColor = data.backgroundColor;
               draft[currentIndex].Style.bubbleColor = data.bubbleColor;
            });
            this.setState(oldState => ({ showAddTemplatePopup: false, showSaveButton: true }));
            break;

         case 'hide-template-selection':
            this.setState({ showAddTemplatePopup: false });
            break;

         default:
            alert(type)
      }
      if (selectedPromo !== undefined)
         actions.refreshAdvertising(selectedPromo);

      if (type.includes('set') || type.includes('select'))
         this.setState({ showSaveButton: true });
      else
         this.setState({});
   }

   handleSaleNamePopup = (fields) => {
      const { actions, selectedGroupId, selectedPromo } = this.props;
      const { saleNamePopupType, currentIndex } = this.state;

      if (currentIndex === -1)
         actions.updateAdvertising(selectedGroupId, selectedPromo[0].Id, saleNamePopupType, fields['name']);
      else
         actions.updateAdvertising(selectedGroupId, selectedPromo[currentIndex].Id, saleNamePopupType, fields['name']);
      this.setState({ showSaleNamePopup: false });
   }

   hideMediaGalleryPopup = () => {
      const { actions, selectedPromo } = this.props;
      this.setState({ showMediaGalleryPopup: false });
      actions.refreshAdvertising(selectedPromo);
   }

   submitMediaGalleryPopup = (item) => {
      const { actions } = this.props;
      const { currentIndex } = this.state;

      const selectedPromo = produce(this.props.selectedPromo, draft => {
         draft[currentIndex].Display.image = item.url;
      })
      actions.refreshAdvertising(selectedPromo);

      this.setState({ showMediaGalleryPopup: false, showSaveButton: true });
   }

   handleSumbit = (data, e) => {
      e.preventDefault();
      const { actions, selectedPromo, saleItems } = this.props;
      const current = produce(selectedPromo[selectedPromo.findIndex(x => x.Id === data.Id)], draft => {
         draft.Items = saleItems.results.filter(x => x.IsChecked == true)
      });
      actions.updateCurrentAdvertising(current);
      this.setState({ showSaveButton: false });
   }

   handleTemplatePopupCloseClick = () => {
      this.props.actions.hideTemplatePopup(this.props.templateView);
   }

   showTemplateViewHandler = (id) => {
      this.props.actions.getAdvertisingTemplateView(id);
   }

   handleDistributePromotion = () => {
      this.props.actions.DistributePromotion();
   }

   render() {

      const {
         currentIndex,
         deleteItemTitle,
         showDeleteItemPopup,
         showSaleNamePopup,
         showMediaGalleryPopup,
         showAddTemplatePopup,
         showAddItemsPopup,
         showEditUserTextPopup,
         showSaveButton,
         saleNamePopupType,
      } = this.state;

      const {
         selectedPromo,
         selectedGroupId,
         templateView,
         templateViewPopup,
         //media,
         activeModules
      } = this.props;

      let refreshButtonStyle = {
         position: 'absolute',
         left: '-130px',
         top: '-60px',
         width: '200px',
      };

      //console.log('sales render', currentIndex, selectedGroupId, selectedPromo);
      if (!activeModules.includes('promotion')) {
         return (
            <section className="managment-system">
               <div className="container">
                  <div className="row">
                     <p>העמוד בבניה..</p>
                  </div>
               </div>
            </section>
         )
      }

      if (!selectedGroupId || (selectedGroupId && selectedPromo !== undefined && selectedPromo[0] === undefined)) {
         return (
            <section className="managment-system">
               <div className="container">
                  <div className="row">
                     <div className="col-md-2 col-xs-12">
                        <ScalesGroups source="sales" />
                     </div>
                  </div>
               </div>
            </section>
         )
      }
      else {
         return (
            <section className="managment-system">
               <div className="container">
                  <div className="row">
                     <div className="col-md-2 col-xs-12">
                        <ScalesGroups source="sales" />
                     </div>
                     <div className="col-md-2 col-xs-12">
                        <ViewSaleList
                           data={selectedPromo}
                           currentIndex={currentIndex}
                           onSelect={this.itemSelect}
                           onEdit={this.itemEdit}
                           onInsert={this.showSaleNamePopup}
                           onDelete={this.itemDelete}
                        />
                     </div>
                     {currentIndex !== -1 &&
                        <div>
                           <div className="col-md-2 col-xs-12">
                              <ViewDetails
                                 showImage={false}
                                 data={selectedPromo[currentIndex]}
                                 showSaveButton={showSaveButton}
                                 onClick={this.handleDetailsClick}
                                 onSubmit={this.handleSumbit}
                                 onDistributePromotion={this.handleDistributePromotion}
                              />
                              <div className="sales-customer-view">
                                 <UserView
                                    UserText={selectedPromo[currentIndex].UserText}
                                    onClick={this.handleDetailsClick}
                                 />
                              </div>
                           </div>
                           <div className="col-md-3 col-xs-12">
                              <ViewSaleDetails
                                 data={selectedPromo[currentIndex]}
                                 onClick={this.handleDetailsClick}
                                 showTemplateView={this.showTemplateViewHandler}
                                 onTemplateTypeChanged={this.templateTypeChangedHandler}
                              />
                           </div>
                        </div>
                     }
                     {currentIndex === -1 &&
                        <div className='col-md-3 col-xs-12 details'>
                           <button className="green-btn" style={refreshButtonStyle} onClick={this.handleDistributePromotion}>
                              רענן משקלים
                           </button>
                        </div>
                     }
                  </div>
                  <AdvDeleteItemPopup
                     visible={showDeleteItemPopup}
                     title={deleteItemTitle}
                     onClick={this.handleItemDelete}
                  />
                  <AdvSaleNamePopup
                     visible={showSaleNamePopup}
                     type={saleNamePopupType}
                     onChange={() => { }}
                     onAccept={this.handleSaleNamePopup}
                     onClose={this.hideSaleNamePopup}
                  />
                  <ViewMediaGallery
                     visible={showMediaGalleryPopup}
                     singleSelection={true}
                     onlyImages={true}
                     imageFilter='!logo'
                     onClose={this.hideMediaGalleryPopup}
                     onSubmit={this.submitMediaGalleryPopup}
                  />
                  <SelectNewTemplatePopup
                     visible={showAddTemplatePopup}
                     onClick={this.handleDetailsClick}
                     selected={selectedPromo[currentIndex]}
                  />
                  {/**
                  <ViewSaleItems
                     visible={showAddItemsPopup}
                     onClick={this.handleDetailsClick}
                     currentIndex={currentIndex}
                     selected={selectedPromo[currentIndex] !== undefined ? selectedPromo[currentIndex].Items : null}
                  />
**/}
                  <SaleUserTextPopup
                     content={selectedPromo[currentIndex] !== undefined ? selectedPromo[currentIndex].UserText : null}
                     visible={showEditUserTextPopup}
                     onClick={this.handleDetailsClick}
                  />
                  <ViewTemplatePopup
                     url={templateView}
                     visible={templateViewPopup}
                     onClick={this.handleTemplatePopupCloseClick}
                  />
               </div>
            </section>
         )
      }
   }
}

function mapStateToProps(state) {
   return {
      selectedGroupId: state.App.selectedGroupId,
      selectedPromo: state.App.selectedPromo,
      //media: state.App.Media,
      templateView: state.App.templateView,
      templateViewPopup: state.App.templateViewPopup,
      activeModules: state.App.activeModules,
      saleItems: state.Sales,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: bindActionCreators(actions, dispatch)
   }
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Sales);

