import React from 'react';
import { PropTypes } from 'prop-types';
import classes from './AdvCheckbox.module.scss';

// https://lokesh-coder.github.io/pretty-checkbox

const AdvCheckbox = ({ checked, name, onChange, label }) => {
  return (
    <div className={`pretty p-default ${classes.checkbox}`}>
      <input
        id={name}
        checked={checked}
        type="checkbox"
        onChange={onChange}
      />
      <div className="state p-primary-o">
        <label htmlFor={name}>{label}</label>
      </div>
    </div>
  )
}

AdvCheckbox.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func
}

AdvCheckbox.defaultProps = {
  checked: false
}

export default AdvCheckbox;
