export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

// reportpage
export const REPORT_PAGE_CHANGE_SORT = 'REPORT_PAGE_CHANGE_SORT'
export const REPORT_PAGE_GET_REQUEST = 'REPORT_PAGE_GET_REQUEST'
export const REPORT_PAGE_GET_ADVENCED_DETAILS_SELLER_REPORT = 'REPORT_PAGE_GET_ADVENCED_DETAILS_SELLER_REPORT'
export const REPORT_PAGE_SET_FILTER = 'REPORT_PAGE_SET_FILTER'
export const REPORT_PAGE_TOGGLE_CHECK_ITEM = 'REPORT_PAGE_TOGGLE_CHECK_ITEM'
export const REPORT_PAGE_TOGGLE_CHECK_ALL_ITEMS = 'REPORT_PAGE_TOGGLE_CHECK_ALL_ITEMS'
export const REPORT_PAGE_TOGGLE_SUM_BRANCHES = 'REPORT_PAGE_TOGGLE_SUM_BRANCHES'
export const REPORT_PAGE_REQUEST = 'REPORT_PAGE_REQUEST'
export const REPORT_PAGE_ERROR = 'REPORT_PAGE_ERROR'
export const REPORT_PAGE_EXPORT_EXCEL = 'REPORT_PAGE_EXPORT_EXCEL' 

// sales
export const SALE_ITEMS_SET = 'SALE_ITEMS_SET'
export const SALE_ITEMS_SET_FILTER = 'SALE_ITEMS_SET_FILTER'
export const SALE_ITEMS_UNCHECK_ALL_ITEMS = 'SALE_ITEMS_UNCHECK_ALL_ITEMS'
export const SALE_ITEMS_TOGGLE_CHECK_ITEM = 'SALE_ITEMS_TOGGLE_CHECK_ITEM'
export const SALE_ITEMS_TOGGLE_CHECK_ALL_ITEMS = 'SALE_ITEMS_TOGGLE_CHECK_ALL_ITEMS'
export const SALE_ITEMS_TOGGLE_SHOW_SELECTED = 'SALE_ITEMS_TOGGLE_SHOW_SELECTED'


// presets
export const PRESET_ITEMS_SET = 'PRESET_ITEMS_SET'
export const PRESET_ITEMS_SET_FILTER = 'PRESET_ITEMS_SET_FILTER'

// app
export const INIT_SYSTEM = 'INIT_SYSTEM'
export const SET_TEXTS = 'SET_TEXTS'
export const CHANGE_LANG = 'CHANGE_LANG'
export const TOGGLE_MENU_VIEW = 'TOGGLE_MENU_VIEW'
export const TOGGLE_SIDEBAR_VIEW = 'TOGGLE_SIDEBAR_VIEW'
export const GET_LAST_UPDATE = 'GET_LAST_UPDATE'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const SELECT_SCALE_GROUP = 'SELECT_SCALE_GROUP'
export const NEW_PROMO_DATA = 'NEW_PROMO_DATA'
export const NEW_SALE_DATA = 'NEW_SALE_DATA'
export const DELETE_ADVERTISING = 'DELETE_ADVERTISING'
export const SHOW_TEMPLATE = 'SHOW_TEMPLATE'
export const DISTRIBUTE_PROMOTION = 'DISTRIBUTE_PROMOTION'
export const HIDE_TEMPLATE = 'HIDE_TEMPLATE'
export const ADD_ADVERTISING = 'ADD_ADVERTISING'
export const REFRESH_ADVERTISING = 'REFRESH_ADVERTISING'
export const UPDATE_CURRENT_ADVERTISING = 'UPDATE_CURRENT_ADVERTISING'
export const UPDATE_DEPARTMENTS = 'UPDATE_DEPARTMENTS'
export const UPDATE_BRANCHES = 'UPDATE_BRANCHES'
export const UPDATE_USERS = 'UPDATE_USERS'
export const UPDATE_GROUPS = 'UPDATE_GROUPS'
export const UPDATE_SELECTED_GROUP = 'UPDATE_SELECTED_GROUP'
export const UPDATE_USER_TEXT = 'UPDATE_USER_TEXT'
export const GET_MEDIA = 'GET_MEDIA'
export const UPDATE_MEDIA = 'UPDATE_MEDIA'
export const DELETE_MEDIA = 'DELETE_MEDIA' 
export const TOGGLE_MODAL = 'TOGGLE_MODAL' 

export const SHOW_GROUP_PRESETS_DATA = 'SHOW_GROUP_PRESETS_DATA'
export const GET_GROUP_PRESETS_DATA = 'GET_GROUP_PRESETS_DATA'
export const GET_GROUP_PARAMETERS_DATA = 'GET_GROUP_PARAMETERS_DATA'
export const SET_GROUP_PARAMETERS_DATA = 'SET_GROUP_PARAMETERS_DATA'
export const UPDATE_GROUP_PARAMETERS_DATA = 'UPDATE_GROUP_PARAMETERS_DATA'

export const ENTERED_PARAMETERS_ROUTE = 'ENTERED_PARAMETERS_ROUTE'
export const EXITED_PARAMETERS_ROUTE = 'EXITED_PARAMETERS_ROUTE'

// createreport.js
export const CREATE_REPORT_ALLOCATE_SELLER_REPORT_CRITERION = 'CREATE_REPORT_ALLOCATE_SELLER_REPORT_CRITERION'
export const CREATE_REPORT_GET_SELLER_REPORT_CRITERION = 'CREATE_REPORT_GET_SELLER_REPORT_CRITERION'
export const CREATE_REPORT_GET_ITEM_CATEGORY_LIST = 'CREATE_REPORT_GET_ITEM_CATEGORY_LIST'
export const CREATE_REPORT_ADD_SELLER_REPORT_CRITERION_DATA = 'CREATE_REPORT_ADD_SELLER_REPORT_CRITERION_DATA'
export const CREATE_REPORT_UPDATE_SELLER_REPORT_CRITERION_DATA = 'CREATE_REPORT_UPDATE_SELLER_REPORT_CRITERION_DATA'
export const CREATE_REPORT_GET_PLU_ITEM_LIST = 'CREATE_REPORT_GET_PLU_ITEM_LIST'
export const CREATE_REPORT_SET_FILTER = 'CREATE_REPORT_SET_FILTER'                                          // WAS DUPLICATED
export const CREATE_REPORT_TO_CHANGE_SORT = 'CREATE_REPORT_TO_CHANGE_SORT'
export const CREATE_REPORT_TOGGLE_CHECK_ITEM = 'CREATE_REPORT_TOGGLE_CHECK_ITEM'                           // WAS DUPLICATED
export const CREATE_REPORT_TOGGLE_CHECK_ALL_ITEMS = 'TOGCREATE_REPORT_TOGGLE_CHECK_ALL_ITEMSGLE_CHECK_ALL_ITEMS__CREATE_REPORT'                  // WAS DUPLICATED
export const CREATE_REPORT_TOGGLE_SHOW_SELECTED = 'CREATE_REPORT_TOGGLE_SHOW_SELECTED'
export const CREATE_REPORT_SET_BRANCH_LIST = 'CREATE_REPORT_SET_BRANCH_LIST'
export const CREATE_REPORT_SET_BRANCH_DEPT_LIST = 'CREATE_REPORT_SET_BRANCH_DEPT_LIST'
export const CREATE_REPORT_REQUEST = 'CREATE_REPORT_REQUEST'                                                // WAS DUPLICATED

export const GET_DASHBOARD_DETAILS = 'GET_DASHBOARD_DETAILS'

export const REQUEST_FAIL = 'REQUEST_FAIL'

// reportlist.js
export const REPORT_LIST_GET_SELLER_REPORT_CRITERION_LIST = 'REPORT_LIST_GET_SELLER_REPORT_CRITERION_LIST'
export const REPORT_LIST_SET_FILTER = 'REPORT_LIST_SET_FILTER'                                              // WAS DUPLICATED
export const REPORT_LIST_TO_CHANGE_SORT = 'REPORT_LIST_TO_CHANGE_SORT'                                       // WAS DUPLICATED
export const REPORT_LIST_SEND_TO_INNACTIVE = 'REPORT_LIST_SEND_TO_INNACTIVE'
export const REPORT_LIST_REQUEST = 'REPORT_LIST_REQUEST'                                                       // WAS DUPLICATED

// reporttrans.js
export const REPORT_TRANS_GET_SELLER_REPORT_TRANS = 'REPORT_TRANS_GET_SELLER_REPORT_TRANS'
export const REPORT_TRANS_SET_FILTER = 'REPORT_TRANS_SET_FILTER' // WAS DUPLICATED
export const REPORT_TRANS_TO_CHANGE_SORT = 'REPORT_TRANS_TO_CHANGE_SORT' // WAS DUPLICATED
export const REPORT_TRANS_TOGGLE_CHECK_ITEM = 'REPORT_TRANS_TOGGLE_CHECK_ITEM' // WAS DUPLICATED
export const REPORT_TRANS_TOGGLE_CHECK_ALL_ITEMS = 'REPORT_TRANS_TOGGLE_CHECK_ALL_ITEMS' // WAS DUPLICATED
export const REPORT_TRANS_REQUEST = 'REPORT_TRANS_REQUEST' // WAS DUPLICATED
export const REPORT_TRANS_ERROR = 'REPORT_TRANS_ERROR' 
