import React from 'react'
import { PropTypes } from 'prop-types'

const ProfileLogout = (props) => {
  return (
    <div className="profile__logout text-right">
      <p className="profile__descr">{props.text}</p>
      <button 
        className="profile__icon icon-log-out"
        onClick={props.systemLogOut}>
      </button>
    </div>
  )
}

ProfileLogout.propTypes = {
   systemLogOut: PropTypes.func.isRequired
 }
 
export default ProfileLogout
