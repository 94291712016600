import React from 'react'
import { PropTypes } from 'prop-types'

export default function Help(props) {
  const texts = props.texts.map((item, i) => {
    return (
      <div key={i}>
        <p className="modal__txt"><b>{item.title}</b></p>
        <p className="modal__txt">{item.desc}</p>
      </div>
    )
  })
  
  return (
    <div className="modal__descr">
      {texts}
      
      
      {/*<p className="modal__txt"><b>Quis bonorum consequuntur at sed. In partem percipitur sadipscing mea, in eos viris sadipscing, pro postea abhorreant ad.</b></p>
      <p className="modal__txt">Per te quis suas signiferumque, persecuti efficiantur ius cu, at accusata qualisque definitiones sit.</p>
      <p className="modal__txt"><b>Cu nec idque ullamcorper, labitur constituto pro te.</b></p>
      <p className="modal__txt">Vocent voluptua mediocritatem vix ne, accumsan perpetua pro te, habemus delicata splendide ei usu. Wisi accusamus disputationi nam te.</p>
      <p className="modal__txt"><b>Vix ex possim impetus.</b></p>
      <p className="modal__txt">Mea dolorum omittantur ea. Wisi ipsum explicari ne mei, ea ridens putent labore est. In qui simul option honestatis, quo iusto ludus definitiones id.</p>
      <p className="modal__txt">Ut eligendi recusabo nam, iriure feugait recteque mei et, everti impetus an vix.</p>*/}
    </div>
  )
}

Help.propTypes = {
  message: PropTypes.string
}
