import React from 'react'
import Checkbox from '../Checkbox'

export default class Children extends React.Component {
  render() {
    const onChange = () => this.props.setBranchDeptList({
      parentID: this.props.parentID,
      childID: this.props.item.Id
    })
    return (
      <div className="tree__child">
        <Checkbox 
          checked={this.props.item.IsChecked}
          label={this.props.item.Name} 
          name={this.props.item.Name + '-' + this.props.parentID}
          onChange={onChange} />
      </div>
    )
  }
}
