import React, { Component } from 'react'
import ReactTable from "react-table";
import Select from 'react-select';
import produce from "immer";

import Image from "../../assets/images/edit-branch-dept.png";
import classes from './updateBranchDepartmentScalesPopup.module.scss';
import AdvDeleteItemPopup from '../../components/advertising/AdvDeleteItemPopup';
import WarningPopup from '../../components/advertising/warningPopup';

export default class UpdateBranchDepartmentScalesPopup extends Component {

   state = {
      data: null,
      deleteId: 0,
      showDeletePopup: false,
      deletePopupTitle: '',
      showWarningPopup: false,
      warningPopupTitle: '',
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.data !== this.props.data) {
         this.setState({ data: nextProps.data })
      }
   }

   handleRowScaleTypeChange = (e, cellInfo) => {
      const rows = this.state.data.map((row, index) => {
         if (cellInfo.index === index) {
            return ({
               ...row,
               scaleType: e.value
            })
         }
         return row;
      })

      this.setState({ data: rows, saveChanges: true });
   }

   handleRowModelChange = (e, cellInfo) => {
      const rows = this.state.data.map((row, index) => {
         if (cellInfo.index === index) {
            return ({
               ...row,
               model: e.value
            })
         }
         return row;
      })

      this.setState({ data: rows, saveChanges: true });
   }

   deleteRow = deleteId => {
      this.setState({
         showDeletePopup: true,
         deletePopupTitle: 'למחוק משקל?',
         deleteId
      });
   }

   handlePopupDelete = (type, e) => {
      if (type === 'delete') {
         const data = this.state.data.filter(x => x.deleteId !== this.state.deleteId);
         this.setState({ data });
      }
      this.setState({ showDeletePopup: false });
   }

   handleAddScale = e => {
      const scalesWithoutId = this.state.data.filter(x => x.deleteId === 0);
      if (scalesWithoutId.length) {
         this.setState({
            showWarningPopup: true,
            WarningPopupTitle: 'חובה להקליד מספר משקל',
         });
      }
      else {
         const data = produce(this.state.data, draft => {
            draft.push({ deleteId: 0, id: '', ipAddr: '', macAddr: '', model: '', scaleType: '', serialNumber: '', tooltip: null });
         });
         this.setState({ data });
      }
   }

   handleClose = () => {
      const data = this.state.data.filter(x => x.deleteId !== 0);
      this.setState({ data }, this.props.onClose());
   }

   handleAccept = (e, data) => {

      const countErrors = data.filter(x => x.id === '');
      if (countErrors.length) {
         this.setState({
            showWarningPopup: true,
            WarningPopupTitle: 'חובה להקליד מספר משקל',
         });
      }
      else
         this.props.onAccept(e, data);
   }


   handleWarningClick = () => {
      this.setState({ showWarningPopup: false });
   }

   renderEditable = cellInfo => {
      if (cellInfo.index >= this.state.data.length)
         return null;

      return (
         <div
            className={classes.vertical_center}
            contentEditable
            suppressContentEditableWarning
            onBlur={e => {
               const data = produce(this.state.data, draft => {
                  draft[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                  if (cellInfo.column.id === 'id')
                     draft[cellInfo.index]['deleteId'] = e.target.innerHTML;
               });

               this.setState({ data, saveChanges: true });
            }}
            dangerouslySetInnerHTML={{
               __html: this.state.data[cellInfo.index][cellInfo.column.id]
            }}
         />
      );
   }

   render() {
      const { visible, title, scaleTypes, onClose, onAccept } = this.props;
      const {
         data,
         showWarningPopup,
         warningPopupTitle,
         showDeletePopup,
         deletePopupTitle,
         deleteId } = this.state;


      if (!visible)
         return null;

      let scaleTypesOptions = [], modelsOptions = [];
      if (scaleTypes !== null) {
         scaleTypes.forEach(scaleType => {
            scaleTypesOptions.push({ label: scaleType.name, value: scaleType.name });
            scaleType.models.forEach(model => {
               modelsOptions.push({ scaleType: scaleType.name, label: model, value: model });
            });
         });
      }
      //console.log('render', scaleTypes, this.state.data, scaleTypesOptions);

      return (
         <div className="advertising-popup popup shown-popup">
            <div className={classes.wrapper}>
               <div className={classes.image}>
                  <img src={Image} alt="" />
               </div>
               <div className={classes.text}>
                  <h2>עריכת משקלים במחלקה</h2>
                  <h3>{title}</h3>
               </div>
               <div className={classes.fields}>
                  <form action="">
                     <div className={classes.table}>
                        <ReactTable
                           data={this.state.data}
                           noDataText="אין נתונים להצגה.."
                           previousText="הקודם"
                           nextText="הבא"
                           pageText="דף"
                           ofText="/"
                           columns={[
                              {
                                 Header: "",
                                 accessor: "deleteId",
                                 width: 20,
                                 Cell: row => (
                                    <div className={classes.vertical_center}>
                                       <a href="#" className={`bolet-icon-trash align-center`} onClick={() => this.deleteRow(row.row.deleteId)}> </a>
                                    </div>
                                 )
                              },
                              {
                                 Header: "מס' משקל",
                                 accessor: "id",
                                 width: 80,
                                 Cell: this.renderEditable
                              },
                              {
                                 Header: "סוג משקל",
                                 accessor: "scaleType",
                                 width: 150,
                                 Cell: row => {
                                    const menuPlacementValue = "auto"; // set to 'top' to make the dropdown move up.
                                    return (
                                       <div className='reacttable_select_align' >
                                          <Select
                                             noOptionsMessage={() => 'אין סוגי משקל'}
                                             menuPlacement={menuPlacementValue}
                                             options={scaleTypesOptions}
                                             placeholder='בחר סוג משקל..'
                                             searchable={false}
                                             value={scaleTypesOptions.find(x => x.value === row.value)}
                                             onChange={(e) => this.handleRowScaleTypeChange(e, row)}
                                          />
                                       </div>
                                    )
                                 }
                              },
                              {
                                 Header: "דגם",
                                 accessor: "model",
                                 width: 150,
                                 Cell: row => {
                                    const menuPlacementValue = "auto"; // set to 'top' to make the dropdown move up.
                                    const options = modelsOptions.filter(x => x.scaleType === row.original.scaleType);
                                    return (
                                       <div className='reacttable_select_align' >
                                          <Select
                                             noOptionsMessage={() => 'אין דגמים'}
                                             menuPlacement={menuPlacementValue}
                                             options={options}
                                             placeholder='בחר דגם..'
                                             searchable={false}
                                             value={options.find(x => x.value === row.value)}
                                             onChange={(e) => this.handleRowModelChange(e, row)}
                                          />
                                       </div>
                                    )
                                 }
                              },
                              {
                                 Header: "כתובת IP",
                                 accessor: "ipAddr",
                                 width: 130,
                                 Cell: this.renderEditable
                              },
                              {
                                 Header: 'מס"ד',
                                 accessor: "serialNumber",
                                 width: 80,
                                 Cell: this.renderEditable
                              },
                              {
                                 Header: 'כתובות MAC',
                                 accessor: "macAddr",
                                 Cell: this.renderEditable
                              }
                           ]}
                           showPageSizeOptions={false}
                           defaultPageSize={7}
                           getTrProps={(state, rowInfo, column) => {
                              return {
                                 style: {
                                    height: '45px'
                                 }
                              };
                           }}
                           className="-striped -highlight"
                        />
                     </div>

                  </form>
               </div>
               <div className={classes.buttons}>
                  <div className={classes.right_buttons} >
                     <button className="green-btn" onClick={(e) => this.handleAddScale(e)} >הוסף משקל</button>
                     <button className="green-btn" type="submit" onClick={(e) => this.handleAccept(e, data)} >עדכן</button>
                  </div>
                  <button className="grey-btn" onClick={this.handleClose} >ביטול</button>
               </div>
            </div>
            <AdvDeleteItemPopup
               visible={showDeletePopup}
               title={deletePopupTitle}
               onClick={this.handlePopupDelete}
            />
            <WarningPopup
               visible={showWarningPopup}
               title='חובה להקליד מספר משקל'
               keys={['close']}
               labels={[{ key: 'close', label: 'אישור' }]}
               onClick={this.handleWarningClick}
            />
         </div>
      )
   }
}
