import React from 'react'
import { PropTypes } from 'prop-types'
import Formsy from 'formsy-react'
// import Select from 'react-select'
import FormSelect from '../../components//form/FormSelect'
import FormRadioGroup from '../../components//form/FormRadioGroup'
import isObject from 'lodash/isObject'


class SortDashboard extends React.Component {
  state = {
    windowWidth: window.innerWidth
  }
  
  handleResize = () => {
    this.setState({windowWidth: window.innerWidth})
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  
  render() {
    const props = this.props
    const texts = props.texts
    const onChange = (model) => {
      props.submit({
        branches: model.branches ? model.branches.Value : 'all',
        duration: isObject(model.duration) ? model.duration.value : model.duration
      })
    }
    
    const times = [
      {
        label: texts.OneYear,
        value: '1 Year'
      },
      {
        label: texts.SixMonths,
        value: '6 Months'
      },
      {
        label: texts.ThreeMonths,
        value: '3 Months'
      },
      {
        label: texts.OneWeek,
        value: '1 Week'
      },
      {
        label: texts.OneDay,
        value: '1 Day'
      }
    ]
    
    
    const model = this.refs.SortDashboard ? this.refs.SortDashboard.getModel() : {branches: null}

    return (
      <Formsy 
        ref="SortDashboard"
        className="sort"
        onChange={onChange}>
        <div className="row">
          <div className="col-md-8">
            <p className="sort__descr">{texts.SelectPeriod}:</p>
            {this.state.windowWidth > 767 ? (
              <FormRadioGroup 
                items={times} 
                itemClassName="sort__radio"
                listClassName="sort__list"
                name="duration"
                value="3 Months" />
            ) : (
              <div className="sort__mobile">
                <div className="custom-select">
                  {/*<Select
                    name="form-field-name"
                    value="1 year"
                    placeholder="1 year"
                    options={times}
                  />*/}
                
                  <FormSelect
                    labelKey="label"
                    name="duration"
                    options={times}
                    valueKey="value"
                    value={times[2]} />
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4">
            <div className="choose__select">
              <div className="custom-select">
                <FormSelect
                  labelKey="Name"
                  name="branches"
                  options={[{Name: texts.All, Value: 'all'}, ...props.branches.map(item => ({...item, Value: item.Name}))]}
                  placeholder={texts.PleaseChooseBranch}
                  valueKey="Name"
                  value={model.branches ? model.branches.Value === 'all' ? {Name: texts.All, Value: 'all'} : model.branches : null} />
              </div>
            </div>
          </div>
        </div>
      </Formsy>
    )
  }
}

SortDashboard.propTypes = {
  branches: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired
}

export default SortDashboard
