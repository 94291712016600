import * as actionTypes from '../../actions/actionTypes';
import { getLocal } from '../../../utils/LocalStorage'
import customFilterFunction from '../../../utils/customFilterFunction'
import toggleCheckItem from '../../../utils/toggleCheckItem'
import toggleCheckAllItems from '../../../utils/toggleCheckAllItems'
import customSortFunction from '../../../utils/customSortFunction'
import toggleShowSelected from '../../../utils/toggleShowSelected'
import map from 'lodash/map'
import filter from 'lodash/filter'
import some from 'lodash/some'
import toString from 'lodash/toString'
import moment from 'moment'

const dataName = 'pluItemList'

const initialState = {
   ItemCodes: [],
   CategoryCodes: [],
   branchList: [],
   branchListKeys: [],
   hasUpdate: false,
   reportCriterionData: {
      CreatedDate: moment(),
      UpdateDate: moment(),
      IsActive: true,
      Name: ''
   },
   [dataName]: [],
   Id: 1,
   results: [],
   allSelected: false,
   fetching: false,
   includedGroups: true,
   query: '',
   sort: '',
   sortAscending: '',
   showSelected: false
}

export default function Sales(state = initialState, action) {
   switch (action.type) {
      case actionTypes.SALE_ITEMS_SET:
         return {
            ...state,
            pluItemList: action.data,
            results: action.data
         }
      case actionTypes.SALE_ITEMS_SET_FILTER: return customFilterFunction({ state, action, dataName })
      case actionTypes.SALE_ITEMS_TOGGLE_CHECK_ITEM: return toggleCheckItem(state, dataName, 'id', action.id, action.isGroup)
      case actionTypes.SALE_ITEMS_TOGGLE_CHECK_ALL_ITEMS: return toggleCheckAllItems(state, dataName)
      case actionTypes.SALE_ITEMS_TOGGLE_SHOW_SELECTED: return toggleShowSelected(state, dataName)
      case actionTypes.SALE_ITEMS_UNCHECK_ALL_ITEMS:
         return {
            ...state,
            results: state.results.map(item => {
               return { ...item, IsChecked: false }
            }),
            pluItemList: state.pluItemList.map(item => {
               return { ...item, IsChecked: false }
            }),
            showSelected: false
         }


      default:
         return state
   }
}
