import React, {Component} from 'react'
import {PropTypes} from 'prop-types'
import {withFormsy} from 'formsy-react'

class FormRadioGroup extends Component {
  state = {
    value: this.props.value
  }

   changeValue(obj) { 
     this.props.setValue(obj.value)
     this.setState({ value: obj.value })
   }

  render() {
    const { itemClassName, listClassName, name, items } = this.props
    return (
      <div className={`form__radio-list ${listClassName}`}>
        {items.map((item, i) => {
          return (
            <div className={`form__radio ${itemClassName}`} key={i}>
              <input 
                defaultChecked={this.state.value === item.value}
                className="form__input" 
                name={name}
                id={item.value}
                onChange={this.changeValue.bind(this, item)}
                type="radio"  />
              <label 
                htmlFor={item.value} 
                className="form__label">
                {item.label}
              </label>
            </div>
          )
        })}
      </div>
    )
  }
}

FormRadioGroup.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string
}

export default withFormsy(FormRadioGroup)
