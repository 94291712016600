import React, { Component } from 'react'

class ViewTemplatePopup extends Component {

   render() {
      const { url, visible, onClick } = this.props;

      if (!visible || url === null)
         return null;

      return (
         <div className="user-text popup shown-popup">
            <div className="pop-up-wrapper">
               <h2>תצוגת מבצע בצד לקוח</h2>
               <br />
               <iframe
                  scrolling="no"
                  className="template-wrapper"
                  style={{ overflow:"hidden" }}
                  title="template"
                  src={url}>
                  <p>Your browser does not support iframes.</p>
               </iframe>
               <br />
               <br />
               <br />
               <div className="pop-up-buttons">
                  <button
                     onClick={onClick}
                     className="grey-btn cancel-popup">
                     סגור
                  </button>
               </div>
            </div>
         </div>
      )
   }
}

export default ViewTemplatePopup
