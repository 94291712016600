import React from 'react'
import { PropTypes } from 'prop-types'

const SearchInput = (props) => {
  const onChange = (e) => props.onChange(e.currentTarget.value)  
  
  return (
    <div className="form form__search">
      <div className="form__input-wrap">
        <input 
          type="search" 
          className="form__input" 
          placeholder={props.placeholder}
          onChange={onChange}
          value={props.value} />
        <i className="input__icon icon-search"></i>
      </div>
    </div>
  )
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default SearchInput
