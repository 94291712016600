import React from 'react'
import {PropTypes} from 'prop-types'

const FilterByQuery = (props) => {
  const onChange = (e) => props.setFilter(e.currentTarget.value)  
  return (
    <div className="form form__search">
      <div className="form__input-wrap">
        <input 
          className="form__input" 
          type="search" 
          placeholder={props.texts.Search}
          onChange={onChange} />
        <i className="input__icon icon-search"></i>
      </div>
    </div>
  )
}

FilterByQuery.propTypes = {
  setFilter: PropTypes.func.isRequired
}

export default FilterByQuery
