import React, { Component } from 'react'
import Http from '../../utils/Http';
import Modal from 'react-modal';
import SaleCodesImage from '../../assets/images/sale_codes.svg';
import Checkbox from '../../components/Checkbox'

const SearchInput = (props) => {
   const onChange = (e) => props.onChange(e.currentTarget.value, props.items)

   return (
      <div className="form form__search sale_search">
         <div className="form__input-wrap">
            <input
               type="search"
               className="form__input sale_input"
               placeholder={props.placeholder}
               onChange={onChange}
               value={props.value} />
            <i className="input__icon icon-search"></i>
         </div>
      </div>
   )
}

class ViewSaleItems extends Component {

   state = {
      items: [],
      searchedItems: [],
      selectedItems: [],
      checkedItems: new Map(),
      currentIndex: -1,
   };

   componentWillReceiveProps(nextProps) {

      if (nextProps.currentIndex !== this.state.currentIndex) {
         let checkedItems = new Map();
         nextProps.selected.forEach(item => checkedItems.set(item.name, true));
         this.setState({
            currentIndex: nextProps.currentIndex,
            selectedItems: nextProps.selected,
            checkedItems: checkedItems,
            searchedItems: this.state.items
         });
      }
      if (!nextProps.visible) {
         this.setState({
            currentIndex: -1,
            searchedItems: this.state.items
         });
      }
   }

   filterList = (value, items) => {
      let updatedList = items;

      if (isNaN(value)) {
         updatedList = updatedList.filter(function (item) {
            return item.name.toLowerCase().search(value.toLowerCase()) !== -1;
         });
      }
      else if (value !== '') {
         updatedList = updatedList.filter(function (item) {
            return item.id.toString().search(value.toString()) !== -1;
         });
      }
      this.setState({ searchedItems: updatedList });
   }

   createItem = (item) => (
      <label
         className="item-label">
         {item.id + ' | ' + item.name}
      </label>
   )

   handleChange = (e, item) => {
      const isChecked = e.target.checked;
      let selectedItems = this.state.selectedItems;
      if (isChecked)
         selectedItems.push(item);
      else
         selectedItems = selectedItems.filter(el => el.id !== item.id);   

      this.setState(prevState => ({
         checkedItems: prevState.checkedItems.set(item.name, isChecked),
         selectedItems: selectedItems
      }));
   }

   render() {
      const { visible, onClick } = this.props;
      const { items, searchedItems, selectedItems } = this.state;

      const searchedItemsView = searchedItems.map(item => (
         <li key={item.id}>
            <div className="item-wrapper">
               <Checkbox
                  name={item.name}
                  label={'   ' + item.id + ' | ' + item.name}
                  checked={this.state.checkedItems.get(item.name)}
                  onChange={(e) => this.handleChange(e, item)}
               />
            </div>
         </li>
      ))
      const selectedItemsView = selectedItems.map(item => (
         <li key={item.id}>
            <div className="item-wrapper">
               {this.createItem(item)}
            </div>
         </li>
      ))

      return (
         <Modal
            isOpen={visible}
            style={{
               content: {
                  position: 'absolute',
                  top: '150px',
                  left: '20%',
                  right: '20%',
                  height: '75%',
                  borderRadius: '10px',
               }
            }}
         >
            <div className="edit-items">
               <img className="header-image" alt="" src={SaleCodesImage} />
               <h2 className="header-text">הגדרת מוצרים למבצע</h2>
               <div className="search header-search">
                  <SearchInput
                     placeholder={'הקש טקסט לחיפוש..'}
                     onChange={this.filterList}
                     items={items}
                  />
               </div>
               <section className="searched">
                  <ul>
                     {searchedItemsView}
                  </ul>
               </section>
               <h1 className="sub-header-text">פריטים שנבחרו</h1>
               <section className="selected">
                  <ul>
                     {selectedItemsView}
                  </ul>
               </section>
               <section className="pop-up-buttons center-text">
                  <button className="green-btn" onClick={(e) => onClick('update-items-selection', selectedItems)}>הוספה</button>
                  <button className="grey-btn" onClick={(e) => onClick('hide-items-selection', e)}>ביטול</button>
               </section>
            </div>
         </Modal>
      )
   }
}

export default ViewSaleItems
