import React from 'react'
import { PropTypes } from 'prop-types'

const Profile = (props) => {
  return (
    <div className="profile">
      <div className="profile__icon icon-user"></div>
      <p className="profile__descr">{props.UserName}</p>
    </div>
  )
}

Profile.propTypes = {
  UserName: PropTypes.string.isRequired
}

export default Profile
