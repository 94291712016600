import React from 'react'
import { PropTypes } from 'prop-types'

const InfoItem = (props) => {
  return (
    <div className="info__item">
      <div className={`info__icon icon-${props.icon}`}></div>
      <div className="info__descr">
        <h4 className="info__title">{props.title}</h4>
        <p className="info__val">{props.value}{props.currency}</p>
      </div>
    </div>
  )
}

InfoItem.propTypes = {
  currency: PropTypes.string.isRequired,
  icon:  PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired
  ]).isRequired
}

export default InfoItem
