import React from 'react'
import {PropTypes} from 'prop-types'
import TableItem from '../../components/table/TableItem'

const TableFoot = (props) => {
  return (
    <tfoot className="table__footer summary">
      <tr className="table__row">
        {props.row.map((item, i) => {
          return (
            <TableItem key={i} item={item}/>
          )
        })}
      </tr>
    </tfoot>
  )
}

TableFoot.propTypes = {
  row: PropTypes.array.isRequired
}

export default TableFoot
