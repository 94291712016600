import React from 'react'
import Checkbox from '../Checkbox'
import Children from './Children'

export default class Parent extends React.Component {
  state = {
    isOpen : false
  }
  
  render() {
   const onChange = () => this.props.setBranchList(this.props.item.Id)
   const onChildChange = () => this.props.setBranchDeptList(this.props.item.Id)
   const toggle = () => this.setState({isOpen: !this.state.isOpen})
    
    return (
      <div className="tree__parent">
        <span 
          className={`tree__childs_${this.state.isOpen ? 'opened' : 'closed'}`}
          onClick={toggle}>plus</span>
        <Checkbox 
          checked={this.props.item.IsChecked}
          label={this.props.item.Name} 
          name={this.props.item.Name}
          onChange={onChange} />
        {this.state.isOpen ? (
          <div className="tree__childs" style={{paddingLeft: 30, paddingRight: 30}}>
            {this.props.item.Departments.map((item, i) => {
              return <Children 
                key={i} 
                item={item} 
                parentID={this.props.item.Id}
                setBranchDeptList={this.props.setBranchDeptList} 
                onchange={this.props.onChildChange}
               />
            })}
          </div>
        ) : null }
      </div>
    )
  }
}
