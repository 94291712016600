/** @jsx jsx */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import Switch from "react-switch";
import { jsx, css } from '@emotion/core';
import Select from 'react-select';
import produce from "immer";

import http from '../../utils/Http';
import httpJSON from '../../utils/HttpJSON';

import * as actions from '../../store/actions/index';
import * as actionTypes from '../../store/actions/actionTypes';
import Icon from '../../utils/Icon';
import ScalesGroups from '../../components/ScalesGroups'

import { ReactComponent as AddIcon } from '../../assets/images/new-document.svg';
import { ReactComponent as EditIcon } from '../../assets/images/details.svg';
import { ReactComponent as DelIcon } from '../../assets/images/delete-file-symbol.svg';
import { ReactComponent as SaveIcon } from '../../assets/images/export-file.svg';

import AdvNewGroupPopup from '../../containers/Advertising/AdvSaleNamePopup';
import AdvDeleteGroupPopup from "../../components/advertising/AdvDeleteItemPopup"

class Parameters extends Component {
   state = {
      scalesParamenters: [],     // parameters for all scales types and models.
      groupsParameters: [],      // all groups parameters
      selectedGroup: null,       // selected group 
      selectedSubGroup: null,    // selected sub group from right pane
      showSaveButton: false,
      showDeleteGroupPopup: false,
      showNewGroupPopup: false,
      showEditGroupPopup: false,
      selectedScaleTypes: null,
      selectValue: null,
      deleteItemTitle: '',
      scaleTypes: [],
      info: null,
   }
   async componentDidMount() {
      const scalesParamenters = await http('GetScalesParameters', 'GET');
      const groupsParameters = await http('GetGroupsParameters', 'GET');
      const scaleTypes = await http('GetGroupScaleTypes', 'GET');
      this.setState({ scalesParamenters, groupsParameters, scaleTypes });
      if (this.props.routeParametersChanged) {
         this.props.actions.routeChanged(actionTypes.ENTERED_PARAMETERS_ROUTE);
         this.setState({ selectedGroup: null });
      }
   }

   componentWillUnmount() {
      this.props.actions.routeChanged(actionTypes.EXITED_PARAMETERS_ROUTE);
   }

   handleFieldChange = (field, value) => {
      const selectedGroup = produce(this.state.selectedGroup, draft => {
         const parameters = draft.parameters.filter(x => x.field !== field);
         parameters.push({ field, value });
         draft.parameters = parameters;
      });
      this.setState({ selectedGroup, showSaveButton: true });
   }

   handleGroupClick = (group) => {
      this.setState({ selectedSubGroup: group });
   }

   save = () => {
      this.props.actions.UpdateParamsSelectedGroup(this.state.selectedGroup);
   }

   showParamInfo = info => {
      this.setState({ info });
   }

   handleEditGroupNamePopup = async (fields) => {
      const groupsParameters = await http(`UpdateGroupParameterName?id=${this.state.selectedGroup.id}&name=${fields['name']}`, 'POST');
      this.setState({ showEditGroupPopup: false, groupsParameters });
   }

   handleNewGroupPopup = async (fields) => {
      let max = { id: 0 };
      if (this.state.groupsParameters.length) {
         max = this.state.groupsParameters.reduce(function (prev, current) {
            return (prev.id > current.id) ? prev : current
         });
      }
      const scaleType = this.state.scaleTypes.find(x => x.id == fields['scaleType'])
         ? this.state.scaleTypes.find(x => x.id == fields['scaleType']).name
         : '';
      const groupsParameters = await http(`AddGroupParameter?id=${max.id + 1}&name=${fields['name']}&scaleType=${scaleType}&model=${fields['model'] || 'all'}`, 'POST');
      this.setState({ showNewGroupPopup: false, groupsParameters });
   }

   handleNewGroupPopupValueChanged = (field, value) => {
      if (field.toString() === 'scaleType') {
         this.setState({ selectedScaleTypes: this.state.scaleTypes.find(x => x.id === value.toString()) })
      }
   }

   handleGroupSave = async () => {
      const groupsParameters = await httpJSON(`UpdateGroupParameter`, 'POST', this.state.selectedGroup);
      this.setState({ showSaveButton: false });
   }

   handleGroupDeletePopup = async (action) => {
      if (action === 'delete' && this.state.deleteItemType === 'delete-group' && this.state.selectedGroup !== null) {
         const groupsParameters = await http(`DeleteGroupParameter?id=${this.state.selectedGroup.id}`, 'POST');
         this.setState({ groupsParameters, selectedGroup: null, selectValue: null });
      }
      this.setState({ showDeleteGroupPopup: false });
   }

   render() {

      const {
         scalesParamenters,
         groupsParameters,
         showSaveButton,
         selectedGroup,
         selectedSubGroup,
         showDeleteGroupPopup,
         showNewGroupPopup,
         showEditGroupPopup,
         deleteItemTitle,
         scaleTypes,
         selectedScaleTypes,
         selectValue,
         info } = this.state;

      //console.log('Parameters render', selectedGroup, selectedSubGroup, groupsParameters, scalesParamenters);

      const fields = () => {

         if (selectedGroup === null || selectedSubGroup === null || (Object.entries(selectedGroup).length === 0 && selectedGroup.constructor === Object))
            return null;

         return (

            // iterate all parameters and show only matching parameters,based on selected group.
            scalesParamenters.map((scalesParamenter, index) => (() => {

               // exit if scale type differ and model not exist
               if (scalesParamenter.name !== selectedSubGroup.name ||
                  selectedGroup.scaleType !== scalesParamenter.scaleType &&
                  !scalesParamenter.models.includes(selectedGroup.model))
                  return null;

               return scalesParamenter.parameters.map(param => {

                  // group param value
                  const groupParamenter = selectedGroup.parameters.find(x => x.field == param.field);
                  // default value from param definition
                  let defaultParameter = param.models.find(x => x.value === selectedGroup.model);
                  if (defaultParameter === undefined)
                     defaultParameter = { defaultValue: '' };
                  //console.log('groupParamenter', selectedGroup.parameters, param, groupParamenter, defaultParameter)
                  const showTooltip = param.info.length > 0 ? true : false;
                  const tooltip = showTooltip &&
                     <div className="param-tooltip">
                        <p className="tooltiptext">{param.info}</p>
                        <i className="parameters__field-icon">
                           <Icon width="15px" height="15px" icon={"info"} />
                        </i>
                     </div>

                  if (param.type === 'text') {
                     const value = groupParamenter !== undefined ? groupParamenter.value : defaultParameter.defaultValue;
                     return (
                        <div
                           key={param.field}
                           className="parameters__field parameters__field--small">
                           <div className="param-tooltip-wrapper">
                              <label htmlFor={param.field}>{param.name}</label>
                              {tooltip}
                           </div>
                           <input
                              type="text"
                              className="form-control"
                              name={param.field}
                              placeholder={param.info}
                              id={param.field}
                              value={value}
                              onChange={(e) => this.handleFieldChange(param.field, e.target.value)} />
                        </div>
                     )
                  }
                  else if (param.type === 'price') {
                     const value = groupParamenter !== undefined ? parseFloat(groupParamenter.value).toFixed(2) : parseFloat(defaultParameter.defaultValue).toFixed(2);
                     return (
                        <div
                           key={param.field}
                           className="parameters__field parameters__field--small">
                           <div className="param-tooltip-wrapper">
                              <label htmlFor={param.field}>{param.name}</label>
                              {tooltip}
                           </div>
                           <input
                              type="number"
                              min={param.range.min}
                              max={param.range.max}
                              step="0.10"
                              className="form-control"
                              name={param.field}
                              placeholder={param.info}
                              value={value}
                              onChange={(e) => this.handleFieldChange(param.field, e.target.value)} />
                        </div>
                     )
                  }
                  else if (param.type === 'number') {
                     const value = groupParamenter !== undefined ? groupParamenter.value : defaultParameter.defaultValue;
                     return (
                        <div
                           key={param.field}
                           className="parameters__field parameters__field--small">
                           <div className="param-tooltip-wrapper">
                              <label htmlFor={param.field}>{param.name}</label>
                              {tooltip}
                           </div>
                           <input
                              type="number"
                              min={param.range.min}
                              max={param.range.max}
                              step="1"
                              className="form-control"
                              name={param.field}
                              placeholder={param.info}
                              value={value}
                              onChange={(e) => this.handleFieldChange(param.field, e.target.value)} />
                        </div>
                     )
                  }
                  else if (param.type === 'textarea') {
                     return (
                        <div
                           key={param.field}
                           className="parameters__field parameters__small">
                           <div className="param-tooltip-wrapper">
                              <label htmlFor={param.field}>{param.name}</label>
                              {tooltip}
                           </div>
                           <textarea
                              field={param.field}
                              id={param.field}
                              className="form-control"
                              rows="5" />
                        </div>
                     )
                  }
                  else if (param.type === 'checkbox') {
                     const checked = groupParamenter !== undefined ? groupParamenter : defaultParameter.defaultValue;
                     const indent = param.depends.length > 0 ? 'indent' : '';
                     return (
                        <div
                           key={param.field}
                           className={`parameters__field--row parameters__field--large ${indent}`}>
                           <label htmlFor={param.field} >
                              <Switch
                                 className="react-switch"
                                 id={param.field}
                                 onChange={(checked) => this.handleFieldChange(param.field, checked.toString())}
                                 checked={checked.value === "true"} />
                              {param.name}
                           </label>
                           {tooltip}
                        </div>
                     )
                  }
                  else if (param.type === 'combo') {
                     const value = groupParamenter !== undefined ? groupParamenter.value : defaultParameter.defaultValue;
                     return (
                        <div
                           key={param.field}
                           className="parameters__field parameters__field--small">
                           <div className="param-tooltip-wrapper">
                              <label htmlFor={param.field}>{param.name}</label>
                              {tooltip}
                           </div>
                           <select
                              name={param.field}
                              id={param.field}
                              className="form-control"
                              value={value}
                              onChange={(e) => this.handleFieldChange(param.field, e.target.value)} >
                              {param.options.map((option, index) =>
                                 <option key={index} value={option.value}>{option.name}</option>
                              )}
                           </select>
                        </div>
                     )
                  }
               })
            })())
         )
      }

      let parametersOptions = [];
      if (groupsParameters) {
         groupsParameters.forEach(function (parameter) {
            parametersOptions.push({ value: parameter.id, label: `${parameter.id} | ${parameter.name}` })
         });
      }

      // create new group fields.
      let selectedTypeModelOptions = [];
      if (selectedScaleTypes) {
         selectedScaleTypes.models.forEach(model => {
            selectedTypeModelOptions.push({ key: model, value: model, name: "", title: model });
         });
      }
      const scaleTypeOptions = scaleTypes.map(scaleType => ({ key: 'option' + scaleType.id, value: scaleType.id, name: "", title: scaleType.name }));
      const popupFields = [
         { field: 2, type: "combo", name: "scaleType", title: "סוג משקל", info: "", options: scaleTypeOptions }
      ];
      if (selectedTypeModelOptions.length)
         popupFields.push({ field: 3, type: "combo", name: "model", title: "דגם", info: "", options: selectedTypeModelOptions });

      return (
         <section className="managment-system">
            <div className="container">
               <div className="row">

                  <div css={css`
								grid-area: groups;
								width: 70%;}`}>

                     <div css={css`
									display:flex;
									align-items: center;
									`}>
                        <label css={css`
									width: 200px;
									font-size: 12px;
									color: #0f2649;
									transition: background-color 0.5s ease;
									font-weight: 400;
									margin-bottom: 5px;`}>
                           בחירת קבוצת פרמטרים
									<Select css={css`width: 200px;`}
                              autosize={false}
                              clearable={false}
                              simpleValue
                              placeholder="בחר קבוצת פרמטרים לעריכה.."
                              noOptionsMessage={() => 'לא הוגדרו קבוצות פרמטרים'}
                              value={selectValue}
                              onChange={newValue => this.setState(prevState => ({ selectValue: newValue, selectedGroup: prevState.groupsParameters.find(x => x.id == newValue.value) }))}
                              options={parametersOptions}
                           />
                        </label>
                        <button css={css`position:relative;right:10px;top:5px;`}>
                           <AddIcon title='הוספת קבוצת פרמטרים חדשה' width='25' height='25' css={css`fill:orange;`} onClick={() => this.setState({ showNewGroupPopup: true })} />
                        </button>
                        {selectedGroup &&
                           <>
                              <button css={css`position:relative;right:10px;top:5px;`}>
                                 <EditIcon title='עריכת שם קבוצה' width='25' height='25' css={css`fill:orange;`} onClick={() => this.setState({ showEditGroupPopup: true })} />
                              </button>
                              <button css={css`position:relative;right:15px;top:5px;`}>
                                 <DelIcon title='מחיקת קבוצת פרמטרים' width='25' height='25' css={css`fill:red;`}
                                    onClick={() => this.setState({
                                       deleteItemTitle: 'למחוק את קבוצת הפרמטרים?',
                                       deleteItemType: 'delete-group',
                                       showDeleteGroupPopup: true
                                    })}
                                 />
                              </button>
                           </>
                        }
                        {showSaveButton &&
                           <button css={css`position:relative;right:22px;top:6px;`}>
                              <SaveIcon title='שמור שינויים' width='25' height='25' css={css`fill:green;`}
                                 onClick={this.handleGroupSave}
                              />
                           </button>
                        }
                     </div>
                  </div>

                  <div className="parameters__container">
                     <div className="parameters__right-pane">
                        {scalesParamenters.map(group => {

                           if (selectedGroup === null || !group.models.includes(selectedGroup.model))
                              return null;

                           const selected = selectedSubGroup !== null
                              ? group.name === selectedSubGroup.name
                                 ? 'parameters__name--selected'
                                 : ''
                              : '';

                           return (
                              <a
                                 key={group.name}
                                 className={`parameters__name ${selected}`}
                                 onClick={() => this.handleGroupClick(group)} >
                                 {group.name}
                              </a>
                           )
                        })}
                     </div>
                     <div className="parameters__fields">
                        {fields()}
                     </div>
                  </div>

                  <AdvNewGroupPopup
                     visible={showNewGroupPopup}
                     type={'new-preset-group'}
                     fields={popupFields}
                     onAccept={this.handleNewGroupPopup}
                     onChange={this.handleNewGroupPopupValueChanged}
                     onClose={() => this.setState({ showNewGroupPopup: false })}
                  />

                  <AdvNewGroupPopup
                     visible={showEditGroupPopup}
                     type={'edit-preset-group'}
                     onAccept={this.handleEditGroupNamePopup}
                     onChange={() => { }}
                     onClose={() => this.setState({ showEditGroupPopup: false })}
                  />

                  <AdvDeleteGroupPopup
                     visible={showDeleteGroupPopup}
                     title={deleteItemTitle}
                     onClick={this.handleGroupDeletePopup}
                  />

               </div>
            </div>
         </section>
      )
   }
}

function mapStateToProps(state) {
   return {
      selectedGroupId: state.App.selectedGroupId,
      selectedGroup: state.App.selectedGroup,
      selectedGroupParameters: state.App.selectedGroupParameters,
      routeParametersChanged: state.App.routeParametersChanged,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: bindActionCreators(actions, dispatch)
   }
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Parameters);

