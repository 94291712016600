import React, {Component} from 'react'
import {PropTypes} from 'prop-types'
import Profile from './nav/Profile'
import ProfileLogout from './nav/ProfileLogout'
import Breadcrumbs from './nav/Breadcrumbs'
import isEmpty from 'lodash/isEmpty'

export default class Nav extends Component {
  render() {
    const {app} = this.props
    const texts = !isEmpty(app.Texts) && app.Texts.Navigation[app.lang]
    const breadTexts = !isEmpty(app.Texts) && app.Texts.Breadcrumbs[app.lang]
    
    return (
      <nav className="nav">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-xs-6 he__profile">
              <Profile {...this.props.UserProfile} />
            </div>
            <div className="col-md-2 col-md-push-8 col-xs-6 he__logout">
              <ProfileLogout 
                systemLogOut={this.props.systemLogOut}
                text={texts.LogOut} />
            </div>
            <div className="nav__separator visible-sm visible-xs"></div>
            <div className="col-md-1 col-md-pull-2 col-xs-2 he__menu">
              <div className="menu menu_open">
                <button 
                  className={`menu__button icon-${this.props.docked ? 'close' : 'menu'}`}
                  onClick={() => this.props.toggleMenuView()} >
                </button>
              </div>
            </div>
            <div className="col-md-7 col-md-pull-2 col-xs-10 he__breadcrumbs">
              {/* <Breadcrumbs 
                texts={breadTexts}
                params={this.props.params}
                routes={this.props.routes} /> */}
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

Nav.propTypes = {
  systemLogOut: PropTypes.func.isRequired
}
