import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Griddle, { RowDefinition, ColumnDefinition, plugins } from 'griddle-react';
import isEmpty from 'lodash/isEmpty';

import * as actions from '../../store/actions/index';

import GetTableFootRow from '../../utils/tableFoots/ReportTrans';
import { EnglishVersionPDFTrans } from '../../utils/reports/EnglishPDF';
import { HebrewVersionPDFTrans } from '../../utils/reports/HebrewPDF';
import { ReportExcelTrans } from '../../utils/reports/ExcelPDF';

import SearcInput from '../../components/SearchInput';
import TableFoot from '../../components/table/TableFoot';
import Checkbox from '../../components/Checkbox'

// const enhancedWithRowData = connect((state, props) => {
//    return {
//       rowData: state.ReportTrans.data[props.griddleKey]
//    };
// });

const rowDataSelector = (state, { griddleKey }) => {
   return state
      .get('data')
      .find(r => r.get('griddleKey') === griddleKey)
      .toJSON();
};

const enhancedWithRowData = connect((state, props) => {
   return {
      rowData: rowDataSelector(state, props),
   };
});

class ReportTrans extends React.Component {
   componentDidMount() {
      this.props.actions.getSellerReportTrans(this.props.modalProps)
   }

   saveToPDF() {
      let checkedData = this.props.reportTrans.results.filter(item => item.IsChecked)
      if (checkedData.length) {
         if (this.props.modalProps.lang === 'en') {
            EnglishVersionPDFTrans(checkedData)
         } else {
            HebrewVersionPDFTrans(this.props.app.Texts.Vendors.ReportTrans.he, checkedData, this.props.modalProps.exportToPDF)
         }
         this.props.actions.isChecked(true)
      } else { this.props.actions.isChecked() }
   }

   exportToExcel() {
      let checkedData = this.props.reportTrans.results.filter(item => item.IsChecked)
      let { en, he } = this.props.app.Texts.Vendors.ReportTrans
      if (checkedData.length) {
         ReportExcelTrans(this.props.modalProps.lang, checkedData, en, he)
         this.props.actions.isChecked(true)
      } else { this.props.actions.isChecked() }
   }

   render() {
      const { app, reportTrans, actions } = this.props
      const texts = !isEmpty(app.Texts) && app.Texts.Vendors.ReportTrans[app.lang]
      const tableTexts = !isEmpty(app.Texts) && app.Texts.Table[app.lang]
      const errors = !isEmpty(app.Texts) && app.Texts.Errors[app.lang]
      let total = 0
      let paid = 0
      reportTrans.results.forEach(item => total += +item.Amount)
      reportTrans.results.forEach(item => paid += item.IsPaid ? 1 : 0)

      const styleConfig = {
         icons: {
            TableHeadingCell: {
               sortDescendingIcon: <small>(desc)</small>,
               sortAscendingIcon: <small>(asc)</small>,
            },
         },
         classNames: {
            Table: 'table',
            TableBody: 'table__row',
            TableHeading: 'table__row',
            TableHeadingCell: 'table__item',
            Row: 'table__row'
         },
         styles: {
            Filter: { fontSize: 18 },
         }
      }

      console.log('ReportTrans render', this.props)
      return (
         <div className="trans__table">
            <div className="trans__top">
               <div className="row">
                  <div className="col-sm-6">
                     <SearcInput placeholder={texts.Search} onChange={actions.reportTransSetFilter} />
                  </div>
                  <div className="col-sm-3 hidden-xs">
                     <a
                        href="#"
                        id="excel_button_trance"
                        className="btn report__button"
                        onClick={this.exportToExcel.bind(this)}
                     >Excel</a>
                  </div>
               </div>
            </div>
            {reportTrans.errorMessage && (
               <div className="form-group">
                  <div className="text-danger">{errors[reportTrans.errorMessage]}</div>
               </div>
            )}
            <div className="table__found-items text-right">
               <span className="table__found-item">
                  {reportTrans.fetching ? `${texts.Loading}...` : `${reportTrans.results.length} ${texts.Items}`}
               </span>
            </div>
            <div className="table__wrap">
               <Griddle
                  styleConfig={styleConfig}
                  data={reportTrans.results}
                  plugins={[plugins.LocalPlugin, plugins.PositionPlugin({ tableHeight: 400 })]}
                  pageProperties={{
                     currentPage: 1,
                     pageSize: 100
                  }}
                  components={{
                     Layout: ({ Table }) => <Table />
                  }}
                  noDataMessage={tableTexts.NoData} >
                  <RowDefinition>
                     <ColumnDefinition
                        id='IsChecked'
                        title=' '
                        cssClassName='table__item text-center'
                        customHeadingComponent={() => {
                           return <Checkbox
                              name="reportPage__allSelected"
                              checked={reportTrans.allSelected}
                              onChange={() => actions.reportTransToggleCheckAllItems()} />
                        }}
                        customComponent={enhancedWithRowData((props) => {
                           return <Checkbox
                              name={props.rowData.TranNumber}
                              checked={props.rowData.IsChecked}
                              onChange={() => actions.reportTransToggleCheckItem(props.rowData.TranNumber)} />
                        })}
                     />
                     <ColumnDefinition
                        id='ScaleTranDate'
                        title={texts.DataScale}
                        cssClassName='table__item'
                     />
                     <ColumnDefinition
                        id='TranDate'
                        title={texts.DataCashRegister}
                        cssClassName='table__item'
                     />
                     <ColumnDefinition
                        id='SellerFirstName'
                        title={texts.VendorName}
                        cssClassName='table__item'
                     />
                     <ColumnDefinition
                        id='ItemCode'
                        title={texts.ProductCode}
                        cssClassName='table__item'
                     />
                     <ColumnDefinition
                        id='ItemName'
                        title={texts.ProductName}
                        cssClassName='table__item'
                     />
                     <ColumnDefinition
                        id='Quantity'
                        title={texts.WeightUnits}
                        cssClassName='table__item'
                     />
                     <ColumnDefinition
                        id='Amount'
                        title={texts.Total}
                        cssClassName='table__item'
                     />
                     <ColumnDefinition
                        id='IsPaid'
                        title={texts.Paid}
                        cssClassName='table__item text-center'
                        customComponent={enhancedWithRowData((props) => {
                           return <Checkbox
                              checked={props.rowData.IsPaid}
                              onChange={() => false} />
                        })}
                     />
                     <ColumnDefinition
                        id='TranNumber'
                        title={texts.Trance}
                        cssClassName='table__item'
                     />
                  </RowDefinition>
               </Griddle>
               {reportTrans.results.length && !reportTrans.fetching ? (
                  <div className="table__foot">
                     <table className="table">
                        <TableFoot
                           row={GetTableFootRow({ total, paid, texts })} />
                     </table>
                  </div>
               ) : null}
            </div>
         </div>
      )
   }
}

ReportTrans.propTypes = {
   message: PropTypes.string
}

const mapStateToProps = state => ({
   reportTrans: state.ReportTrans
})

const mapDispatchToProps = dispatch => ({
   actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportTrans)
