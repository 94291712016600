export default function ReportTransFoot(props) {
  return [
    {label: '', className: ''},
    {label: '', className: ''},
    {label: '', className: ''},
    {label: '', className: ''},
    {label: '', className: ''},
    {label: '', className: ''},
    {className: 'summary__title', label: props.texts.Summary},
    {label: props.total.toLocaleString('en-US', { minimumFractionDigits: 2}), className: 'summary__item'},
    {label: props.paid, className: 'summary__item'},
    // {label: '', className: ''},
    {label: '', className: ''}
  ]
}
