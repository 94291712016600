import React from 'react';
import pick from 'lodash/pick';

import Nav from '../components/Nav';
import Menu from '../components/Menu';

const sideDrawer = props => {

    return (
      <div>
        <Nav
          docked={props.app.docked}
          systemLogOut={props.actions.systemLogOut}
          app={pick(props.app, ['Texts', 'lang'])}
          params={props.params}
          routes={props.routes}
          toggleMenuView={props.actions.toggleMenuView}
          UserProfile={props.app.UserProfile} />

        <div className="container content__wrap">
          <div className="row">
            {props.app.docked && (
              <div className="col-sm-2 menu__wrap">
                <Menu 
                  app={pick(props.app, ['Texts', 'lang', 'activeModules'])}
                  openSidebar = {props.app.openSidebar}
                  onToggle = {name => props.onToggle(name)}/>
              </div>
            )}
            <div className={props.app.docked ? 'col-sm-9 main__content-wrap' : 'col-sm-12'}>
              {React.cloneElement(props.children, { app: pick(props.app, ['Texts', 'lang']), actions: props.actions })}
            </div>
          </div>
        </div>
      </div>
    )
}

export default sideDrawer;