import * as actionTypes from '../actions/actionTypes';

//import {getLocal} from '../utils/LocalStorage'
import moment from 'moment'
import produce from 'immer';

const initialState = {
   isLogined: false,
   activeModules: [],
   docked: false,
   //lang: getLocal('lang') || 'he',
   lang: 'he',
   lastUpdate: '',
   selectedGroupId: null,
   selectedGroupParameters: null,
   selectedGroupPresets: null,
   showPresetsGroup: false,
   showDashboard: false,
   routeParametersChanged: false,
   modal: {
      isOpen: false
   },
   openSidebar: {
      vendors: false,
      media: false
   },
   SessionKey: '',
   Texts: {},
   UserProfile: {
      UserName: ''
   },
   selectedSale: {
      active: false,
      activeDays: 0,
      duration: 0,
      startDate: "",
      startTime: "",
      endtDate: "",
      endTime: "",
      groupId: 0,
      name: "",
      userHebrewText: "",
      userRussianText: "",
      userArabicText: "",
      items: []
   },
   selectedPromo: {
      startDate: "",
      endDate: "",
      startHour: "",
      endHour: "",
      days: 0,
      groupId: 0,
      selectDates: false,
      selectDays: false,
      selectHours: false,
      files: [{
         duration: 0,
         fileName: ""
      }],
   },

   errorMessage: ''
}

export default function Auth(state = initialState, action) {
   switch (action.type) {
      case actionTypes.INIT_SYSTEM:
         return {
            ...state,
            ...action.payload,
            errorMessage: ''
         }
      case actionTypes.SET_TEXTS:
         return {
            ...state,
            Texts: action.payload
         }
      case actionTypes.CHANGE_LANG:
         return {
            ...state,
            isFetching: false,
            lang: action.lang
         }
      case actionTypes.TOGGLE_MODAL:
         return {
            ...state,
            modal: action.modal
         }
      case actionTypes.TOGGLE_MENU_VIEW:
         return {
            ...state,
            docked: !state.docked
         }
      case actionTypes.TOGGLE_SIDEBAR_VIEW:
         return {
            ...state,
            openSidebar: {
               ...state.openSidebar,
               [action.name]: !state.openSidebar[action.name]
            }
         }
      case actionTypes.GET_LAST_UPDATE:
         return {
            ...state,
            lastUpdate: moment(action.payload).format('HH:MM')
         }
      case actionTypes.LOGIN_FAIL:
         return {
            ...state,
            errorMessage: action.errorMessage
         }

      case actionTypes.SELECT_SCALE_GROUP:
         return {
            ...state,
            selectedGroupId: action.payload.groupId,
            selectedGroup: action.payload.group,
         }

      case actionTypes.UPDATE_DEPARTMENTS:
         return {
            ...state,
            Departments: action.payload,
         }

      case actionTypes.UPDATE_BRANCHES:
         return {
            ...state,
            Branches: action.payload,
         }

      case actionTypes.UPDATE_USERS:
         return {
            ...state,
            Users: action.payload,
         }

      case actionTypes.UPDATE_MEDIA:
         const updatedMedia = JSON.parse(JSON.stringify( state.Media ));
         //console.log("actionTypes.UPDATE_MEDIA", state, updatedMedia, state.Media === updatedMedia);
         return {
            ...state,
            Media: updatedMedia,
         }

      case actionTypes.DELETE_MEDIA:
      case actionTypes.GET_MEDIA:
         return {
            ...state,
            Media: action.payload,
         }

      case actionTypes.UPDATE_GROUPS:
         return {
            ...state,
            Groups: action.payload,
         }

      case actionTypes.UPDATE_USER_TEXT:
         return {
            ...state,
            UserText: action.payload
         }

      case actionTypes.UPDATE_SELECTED_GROUP:
         return {
            ...state,
            selectedGroupId: action.payload,
            selectedPromo: {},
         }

      case actionTypes.HIDE_TEMPLATE:
         return {
            ...state,
            templateViewPopup: false
         }

      case actionTypes.SHOW_TEMPLATE:
         return {
            ...state,
            templateView: action.payload,
            templateViewPopup: true
         }

      case actionTypes.ADD_ADVERTISING:
      case actionTypes.DELETE_ADVERTISING:
      case actionTypes.UPDATE_CURRENT_ADVERTISING:
      case actionTypes.NEW_PROMO_DATA:
      case actionTypes.NEW_SALE_DATA:
      case actionTypes.REFRESH_ADVERTISING:
         return {
            ...state,
            selectedPromo: action.payload,
         }

      case actionTypes.GET_GROUP_PRESETS_DATA:
         return {
            ...state,
            selectedGroupId: action.payload,
            showPresetsGroup: false,
         }

      case actionTypes.SHOW_GROUP_PRESETS_DATA:
         return {
            ...state,
            showPresetsGroup: true,
         }

      case actionTypes.GET_GROUP_PARAMETERS_DATA:
      case actionTypes.SET_GROUP_PARAMETERS_DATA:
         return {
            ...state,
            selectedGroupParameters: action.payload,
         }

      case actionTypes.ENTERED_PARAMETERS_ROUTE:
         return {
            ...state,
            routeParametersChanged: false,
         }

      case actionTypes.EXITED_PARAMETERS_ROUTE:
         return {
            ...state,
            routeParametersChanged: true,
            selectedGroupParameters: null,
            selectedGroupId: null,
         }

      default:
         return state
   }
}
