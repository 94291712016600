import some from 'lodash/some';

import Http from '../../../utils/Http';
import HttpJSON from '../../../utils/HttpJSON';
import { getLocal } from '../../../utils/LocalStorage';
import * as actionTypes from '../actionTypes';


export function allocateSellerReportCriterion() {
  return function (dispatch) {
    const user = getLocal('UserProfile')
    Http(`AllocateSellerReportCriterion/${getLocal('SessionKey')}`, 'GET')
      .then(
        response => {
          dispatch({
            type: actionTypes.CREATE_REPORT_ALLOCATE_SELLER_REPORT_CRITERION,
            payload: response
          })
          return
        }
      )
  }
}

export function getSellerReportCriterion(id) {
  return function (dispatch) {
    const user = getLocal('UserProfile')
    Http(`GetSellerReportCriterionData/${getLocal('SessionKey')}`, 'GET', { id })
      .then(
        response => {
          dispatch({
            type: actionTypes.CREATE_REPORT_GET_SELLER_REPORT_CRITERION,
            payload: response
          })
          return response
        }
      )
  }
}

export function getItemCategoryList() {
  return function (dispatch) {
    const user = getLocal('UserProfile')
    Http(`GetItemCategoryList/${getLocal('SessionKey')}`, 'GET', { id: user.UserAccountSysId, status: 'all' })
      .then(
        response => {
          dispatch({
            type: actionTypes.CREATE_REPORT_GET_ITEM_CATEGORY_LIST,
            payload: response.map(item => ({ ...item, IsGroup: true }))
          })
        }
      )
  }
}

export function addSellerReportCriterionData(data) {
  return function (dispatch) {
    HttpJSON(`AddSellerReportCriterionData/${getLocal('SessionKey')}`, 'POST', data)
      .then(
        response => {
          dispatch({
            type: actionTypes.CREATE_REPORT_ADD_SELLER_REPORT_CRITERION_DATA,
            payload: response,
            path: '/vendors/reportList'
          })
        }
      )
  }
}

export function updateSellerReportCriterionData(data) {
  return function (dispatch) {
    HttpJSON(`UpdateSellerReportCriterionData/${getLocal('SessionKey')}`, 'POST', data)
      .then(() => {
        dispatch({
          type: actionTypes.CREATE_REPORT_UPDATE_SELLER_REPORT_CRITERION_DATA,
          path: '/vendors/reportList'
        })
      })
  }
}

export function getPluItemList() {
  return function (dispatch) {
    const user = getLocal('UserProfile')
    Http(`GetPluItemList/${getLocal('SessionKey')}`, 'GET', { id: user.UserAccountSysId })
      .then(
        response => {
          dispatch({
            type: actionTypes.CREATE_REPORT_GET_PLU_ITEM_LIST,
            payload: response
          })
        }
      )
  }
}

export function createReportChangeSort(sort) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CREATE_REPORT_TO_CHANGE_SORT,
      sort
    })
  }
}

export function createReportSetFilter(query) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CREATE_REPORT_SET_FILTER,
      query
    })
  }
}

export function createReportToggleCheckItem(id, isGroup) {
  console.log('createReportToggleCheckItem', id, isGroup);
  return function (dispatch) {
    dispatch({
      type: actionTypes.CREATE_REPORT_TOGGLE_CHECK_ITEM,
      id,
      isGroup
    })
  }
}

export function createReportToggleCheckAllItems() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CREATE_REPORT_TOGGLE_CHECK_ALL_ITEMS
    })
  }
}

export function toggleShowSelected() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CREATE_REPORT_TOGGLE_SHOW_SELECTED
    })
  }
}

export function setBranchList(id) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CREATE_REPORT_SET_BRANCH_LIST,
      id
    })
  }
}

export function setBranchDeptList(data) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CREATE_REPORT_SET_BRANCH_DEPT_LIST,
      payload: data
    })
  }
}
