/* eslint no-unused-vars: */

import React from 'react';
import { PropTypes } from 'prop-types';
import { NavLink } from 'react-router-dom';

import logo from '../assets/images/logo.png';

const Header = (props) => {
  const options = [
      { value: 'en', label: props.texts && props.texts.en.lang },
      { value: 'he', label: props.texts && props.texts.he.lang }
  ]
  
  const openHelpModal = () => {
    props.openModal({
      className: 'modal__help',
      title: props.helpTexts && props.helpTexts[props.lang].ModalTitle,
      type: 'Help',
      texts: props.helpTexts && props.helpTexts[props.lang].texts
    })
  }
  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="col-sm-2 col-xs-6">
            <NavLink 
              to="/" >
              <img className="logo" src={logo} alt=""/>
            </NavLink>
          </div>
{/*  TEMP REMOVE LANGUAGE AND HELP OPTIONS
          <div className="col-sm-4 col-sm-offset-6 col-xs-6 text-right">
            <div className="header__language">
              <div className="custom-select">
                <Select
                  name="form-field-name"
                  value={props.lang}
                  options={options}
                  searchable={false}
                  onChange={(model) => props.changeLang(model.value)} />
              </div>
            </div>
            <span 
              className="header__help"
              onClick={openHelpModal}>
              <i className="icon-question"></i>
            </span>
          </div>
*/}        
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  lang: PropTypes.string.isRequired,
  texts: PropTypes.object,
  changeLang: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
}



export default Header
