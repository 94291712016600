import React from 'react'
import FormInput from '../../components/form/FormInput'
import FormDatepicker from '../../components/form/FormDatepicker'
import FormCheckbox from '../../components/form/FormCheckbox'

class CreateReportTop extends React.Component {
  render() {
    const {texts} = this.props
    return (
      <div className="create-report_top">
        <div className="row">
        <div className="col-sm-8" />
          <div className="col-sm-4">
            <FormInput
              label={texts.CriterionName}
              labelClassName="icon-report"
              name="Name"
              placeholder={texts.InsertReportName}
              value={this.props.data.Name ? this.props.data.Name : undefined}
              validations="isExisty"
              validationError={texts.ValidationMessage} />
          </div>
          {/* <div className="col-sm-3 col-xs-6">
            <FormDatepicker
              disabled={true}
              icon="data"
              label={texts.CreateDate}
              name="CreatedDate"
              value={this.props.data.CreatedDate} />
          </div>
          <div className="col-sm-3 col-xs-6">
            {this.props.isUpdate ? (
              <FormDatepicker
                disabled={true}
                icon="update"
                label={texts.UpdateDate}
                name="UpdatedDate"
                value={this.props.data.UpdateDate} />
            ) : null}
          </div> */}
        </div>
        <FormCheckbox
          label={texts.Active}
          name="IsActive"
          value={this.props.data.IsActive} />
      </div>
    )
  }
}

export default CreateReportTop
