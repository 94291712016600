import React from 'react'

const TableItem = ({item, style}) => {
  return (
    <td className={`table__item ${item.className}`} style={style}>
      {item.component
        ? item.component 
        : item.label}
    </td>
  )
}

export default TableItem
