import * as actionTypes from '../actions/actionTypes';

const initialState = {
  Branches: [],
  BranchData: [],
  DeptData: [],
  SellerData: [],
  TotalPaid: 0,
  TotalSales: 0,
  TotalTransactions: 0,
  branches: 'All'
}

export default function Dashboard(state = initialState, action) {
  switch(action.type) {
    case actionTypes.GET_DASHBOARD_DETAILS:
      return {
        ...state,
        ...action.payload,
        branches: action.branches
      } 
        
    default:
      return state
  }
}
