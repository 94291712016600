import {getData, getColumns, createIMG} from './PDF/forReport'
import {getColumnsTrans, getDataTrans} from './PDF/forTrance'

export function EnglishVersionPDF(texts, results) {
}
export function EnglishVersionPDFTrans(checkedData) {
}
/*
import jsPDF from 'jspdf'
require('jspdf-autotable')


export function EnglishVersionPDF(texts, results) {
  let docPDF = new jsPDF('p', 'pt')
  let images = []
  let imgElements = results.map(item => {
    let elem = document.createElement('img')
    elem.src = createIMG(item.SellerFirstName)
    return elem
  })
  docPDF.autoTable(getColumns(texts), getData(results), {
    drawCell: function(cell, opts) {

      if (opts.column.dataKey === 'name') {
        let img = imgElements[opts.row.index]
        images.push({
          elem: img,
          x: cell.textPos.x,
          y: cell.textPos.y
        })
      }
    },
    drawHeaderCell: function (cell) {
        if (cell.raw === 'ID') {//paint.Name header red
            cell.styles.fontSize= 15;
           cell.styles.textColor = [255,0,0];
        } else {
            cell.styles.textColor = 255;
            cell.styles.fontSize = 10;

        }
    },
    afterPageContent: function() {
      for(var i = 0; i < images.length; i++) {
        docPDF.addImage(images[i].elem, 'png', images[i].x, images[i].y)
      }
    }
   })
  docPDF.save('reportList.pdf')
}


export function EnglishVersionPDFTrans(checkedData) {
  let docPDF = new jsPDF('p', 'pt')
  let images = []
  let imgElements = checkedData.map(item => {
    let elemV = document.createElement('img')
    elemV.src = createIMG(item.SellerFirstName)
    let elemP = document.createElement('img')
    elemP.src = createIMG(item.ItemName)
    return [elemV, elemP]
  })
  docPDF.autoTable(getColumnsTrans(), getDataTrans(checkedData), {
    drawCell: function(cell, opts) {
      let img
      if (opts.column.dataKey === 'vd_name') {
        img = imgElements[opts.row.index][0]
        images.push({
          elem: img,
          x: cell.textPos.x,
          y: cell.textPos.y
        })
      }
      if (opts.column.dataKey === 'pd_name') {
        img = imgElements[opts.row.index][1]
        images.push({
          elem: img,
          x: cell.textPos.x,
          y: cell.textPos.y
        })
      }
    },
    afterPageContent: function() {
      for(var i = 0; i < images.length; i++) {
        docPDF.addImage(images[i].elem, 'png', images[i].x, images[i].y)
      }
    }
   })
  docPDF.save('reportTranceList.pdf')
}
*/