import React from 'react'
import {PropTypes} from 'prop-types'
import {Chart} from 'react-google-charts'

// const options3 = [
//     { value: 'Meat', label: 'Meat' },
//     { value: 'Bakery', label: 'Bakery' },
//     { value: 'Fish', label: 'Fish' },
//     { value: 'Spice', label: 'Spice' },
//     { value: 'Chicken', label: 'Chicken' }
// ];

const DeptSales = (props) => {
    let dataArray = [['task', 'Dept Sales']]
    
    props.data.forEach((item) => {
      dataArray.push([item.DeptName, item.TotalSales])
    })
    
    return (
      <div className="graph">
        <h2 className="title">{props.text}</h2>
        {/*<div className="graph__category">
          <div className="custom-select">
            <Select
              name="form-field-name"
              placeholder="Category"
              options={options3}
            />
          </div>
        </div>*/}
        <div className="graph__image">
          <Chart 
            chartType = "PieChart" 
            data = {dataArray} 
            options = {{
              chartArea:{
                width:'90%',
                height:'90%'
              }
            }} 
            graph_id = "PieChart"  
            width={"100%"} 
            height={"300px"}  
            legend_toggle={true} />
        </div>
      </div>
    )
}

DeptSales.propTypes = {
  data: PropTypes.array.isRequired
}

export default DeptSales
