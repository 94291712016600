import React from 'react';

export default class Node extends React.Component {
  // Props: depth, text, children

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(state => ({ expanded: !state.expanded }));    
  }

  divStyle() {
    const indent = this.props.depth;
    const selected = this.props.selected;
    
    return {
      marginRight: (indent * 10) + "px",
      marginLeft: (indent * 10) + "px",
      marginTop: '10px',
      backgroundColor: selected ? 'lightblue' : 'transparent',
    };
  }

  render() {
    const { text } = this.props;

    return (
      <div style={this.divStyle()}>
      {React.Children.count(this.props.children) > 0 && <button
          onClick={this.toggle}
        >
          {this.state.expanded ? "-" : "+"}
        </button>
      }

        <span onClick={this.props.onClick}>{text}</span>

        {this.state.expanded && React.Children.map(this.props.children, (child) => (
          React.cloneElement(child, { depth: this.props.depth + 1 })
        ))}
      </div>
    );
  }
}
