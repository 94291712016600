
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import ReactPlayer from 'react-player';
import Select from 'react-select';
import moment from "moment";

import AdvRadioGroup from "../../components/advertising/AdvRadioGroup.js";
import AdvCheckbox from "../../components/advertising/AdvCheckbox.js";
import AdvDateInput from "../../components/advertising/AdvDateInput.js";

import largeImagePlaceholder from "../../assets/images/image-placeholder-large.png";
import largeMoviePlaceholder from "../../assets/images/movie-placeholder-large.png";

function ImageView(props) {

   const {
      visible,
      image,
      onClick,
      onMovieClick,
      playerPlaying,
   } = props;

   var durations = [
      { value: '5', label: '5 שניות' },
      { value: '10', label: '10 שניות' },
      { value: '15', label: '15 שניות' },
      { value: '20', label: '20 שניות' },
      { value: '25', label: '25 שניות' },
      { value: '30', label: '30 שניות' },
   ];

   if (!visible || !image)
      return null;

   if (image && image.includes('mp4')) {

      if (playerPlaying) {

         return (
            <div className="big-image">
               <ReactPlayer
                  url={image}
                  playing={true}
                  onEnded={() => onMovieClick('end')}
                  width='390px'
                  height='252px'
               />
            </div>
         )
      }

      return (
         <div className="big-image">
            <a href="#" onClick={() => onMovieClick('start')}>
               <img src={largeMoviePlaceholder} alt="" />
            </a>
         </div>
      )
   }
   if (image) {

      const duration = typeof props.duration !== 'number' ? props.duration : durations.find(x => x.value === props.duration.toString()) 
      return (
         <div className="big-image">
            <a href="#" onClick={() => onClick('set-image')}>
               <img src={image} alt="" />
            </a>
            <div className="duration">
               <label>
                  משך הצגה
                  <Select className="select-duration"
                     value={duration}
                     autosize={false}
                     clearable={false}
                     simpleValue
                     placeholder="בחר זמן.."
                     onChange={(newValue) => onClick('set-duration', newValue)}
                     options={durations}
                  />
               </label>
            </div>
         </div>
      )
   }
   else {
      return (
         <div className="big-image">
            <img src={largeImagePlaceholder} alt="" />
         </div>
      )
   }
}

class ViewDetails extends Component {

   constructor(props) {
      super(props)
      this.state = {
         playerPlaying: false,
      }
   }
   handleDaysChange = (name, e) => {

      var data = { name: name, value: e.target.checked };
      this.props.onClick('set-days', data)
   }

   handleDateChange = ({ startDate, endDate }) => {
      startDate = startDate || this.props.data.View.startDate;
      endDate = endDate || this.props.data.View.endDate;
      if (moment(endDate).isBefore(startDate) || endDate === null) {
         endDate = startDate;
         console.log('set end date as start date');
      }
      var data = { startDate, endDate };
      this.props.onClick('set-date', data);
   }

   handleMovieClick = (type) => {
      switch (type) {
         case 'start':
            this.setState({ playerPlaying: true });
            break;

         case 'end':
            this.setState({ playerPlaying: false });
            break;

         default:
            break
      }
   }

   render() {

      var hours = [
         { value: '8:00', label: '8:00' },
         { value: '10:00', label: '10:00' },
         { value: '12:00', label: '12:00' },
         { value: '14:00', label: '14:00' },
         { value: '16:00', label: '16:00' },
         { value: '18:00', label: '18:00' },
         { value: '20:00', label: '20:00' },
         { value: '22:00', label: '22:00' },
         { value: '23:00', label: '23:00' },
      ];
      const {
         showImage,
         data,
         onClick,
         onSubmit,
         showSaveButton
      } = this.props;

      const {
         playerPlaying,
      } = this.state;

      const {
         days,
         startHour,
         endHour,
         startDate,
         endDate,
         selectDates,
         selectDays,
         selectHours
      } = this.props.data.View;

      let style = "details";

      let refreshButtonStyle = {
         position: 'absolute',
         left: '-208px',
         top: '-20px',
         width: '200px',
      };
      let saveButtonStyle = {
         position: 'absolute',
         left: '10px',
         top: '-20px',
         width: '200px',
      };

      if (showImage) {

         style = "col-md-5 col-xs-12 details";
         refreshButtonStyle = {
            position: 'absolute',
            left: '-130px',
            top: '-60px',
            width: '200px',
         };
         saveButtonStyle = {
            position: 'absolute',
            left: '80px',
            top: '-60px',
            width: '200px',
         };
      }

      //console.log('ViewDetails render', data)
      if (data === undefined || data.Id === 0) {
         return (
            <div className={style}>
               <button className="green-btn" style={refreshButtonStyle} onClick={this.props.onDistributePromotion}>
                  רענן משקלים
            </button>
            </div>
         )
      }

      return (
         <div className={style}>
            <button className="green-btn" style={refreshButtonStyle} onClick={this.props.onDistributePromotion}>
               רענן משקלים
            </button>
            {showSaveButton &&
               <button className="green-btn" style={saveButtonStyle} type="submit" onClick={(e) => onSubmit(data, e)}>
                  שמירת תצוגה פעילה
                    </button>
            }
            <h2>פרטי תצוגה</h2>
            <ImageView
               visible={showImage}
               image={data.Display.image}
               playerPlaying={playerPlaying}
               duration={data.Display.duration}
               onClick={onClick}
               onMovieClick={this.handleMovieClick}
            />
            <form onSubmit={onSubmit}>
               <fieldset>

                  <div className="radio-options">
                     <AdvRadioGroup
                        id="dates-always"
                        checked={!selectDates}
                        name="choose-dates"
                        onChange={() => onClick('select-all-dates')}
                        value="always"
                        label="הצג באופן קבוע"
                     />
                     <AdvRadioGroup
                        id="dates-specific"
                        checked={selectDates}
                        name="choose-dates"
                        onChange={() => onClick('select-specific-dates')}
                        value="dates"
                        label="הצג בתאריכים ספציפיים"
                     />
                     <div className="radio-group">
                        <div className="date-picker-inputs">
                           <AdvDateInput
                              name="from-date"
                              label="הצג בין התאריך:"
                              startDate={startDate}
                              visible={selectDates}
                              onChange={(startDate) => this.handleDateChange({ startDate })}
                           />
                           <AdvDateInput
                              name="until-date"
                              label="לתאריך:"
                              startDate={endDate}
                              minDate={startDate}
                              visible={selectDates}
                              onChange={(endDate) => this.handleDateChange({ endDate })}
                           />
                        </div>
                     </div>
                  </div>
               </fieldset>

               <fieldset>
                  <div className="radio-options">
                     <AdvRadioGroup
                        id="hours-always"
                        checked={!selectHours}
                        name="choose-hours"
                        onChange={() => onClick('select-all-hours')}
                        label="הצג בכל שעות היום"
                     />
                     <AdvRadioGroup
                        id="hours-specific"
                        checked={selectHours}
                        name="choose-hours"
                        onChange={() => onClick('select-specific-hours')}
                        label="הצג בשעות נבחרות"
                     />

                     {selectHours &&
                        <div className="radio-group">
                           <div className="hours-picker-inputs">
                              <div className="from-time">
                                 <label>
                                    משעה:
                                    <Select
                                       className='select-time-container'
                                       classNamePrefix='select-time'
                                       name="from-date"
                                       value={startHour === null ? undefined : { label: startHour, value: startHour }}
                                       autosize={false}
                                       clearable={false}
                                       simpleValue
                                       placeholder=" "
                                       onChange={(newValue) => onClick('set-start-hour', newValue.value)}
                                       options={hours}
                                    />
                                 </label>
                              </div>
                              <div className="until-time">
                                 <label>
                                    לשעה:
                                    <Select
                                       className='select-time-container'
                                       classNamePrefix='select-time'
                                       name="until-date"
                                       value={endHour === null ? undefined : { label: endHour, value: endHour }}
                                       autosize={false}
                                       clearable={false}
                                       simpleValue
                                       placeholder=" "
                                       onChange={(newValue) => onClick('set-end-hour', newValue.value)}
                                       options={hours}
                                    />
                                 </label>
                              </div>
                           </div>
                        </div>
                     }
                  </div>
               </fieldset>

               <fieldset>

                  <div className="radio-options">
                     <AdvRadioGroup
                        id="all-dayes"
                        checked={!selectDays}
                        name="choose-days"
                        onChange={() => onClick('select-all-days')}
                        value="always"
                        label="הצג בכל ימות השבוע"
                     />
                     <AdvRadioGroup
                        id="dayes-specific"
                        checked={selectDays}
                        name="choose-days"
                        onChange={() => onClick('select-specific-days')}
                        value="dates"
                        label="הצג בימים נבחרים"
                     />

                     {selectDays &&
                        <div className="radio-group">
                           <div className="days-picker-inputs">
                              <AdvCheckbox
                                 name="day-1"
                                 checked={days & 1 ? true : false}
                                 label="א"
                                 onChange={(e) => this.handleDaysChange('day-1', e)}
                              />
                              <AdvCheckbox
                                 name="day-2"
                                 checked={days & 2 ? true : false}
                                 label="ב"
                                 onChange={(e) => this.handleDaysChange('day-2', e)}
                              />
                              <AdvCheckbox
                                 name="day-3"
                                 checked={days & 4 ? true : false}
                                 label="ג"
                                 onChange={(e) => this.handleDaysChange('day-3', e)}
                              />
                              <AdvCheckbox
                                 name="day-4"
                                 checked={days & 8 ? true : false}
                                 label="ד"
                                 onChange={(e) => this.handleDaysChange('day-4', e)}
                              />
                              <AdvCheckbox
                                 name="day-5"
                                 checked={days & 16 ? true : false}
                                 label="ה"
                                 onChange={(e) => this.handleDaysChange('day-5', e)}
                              />
                              <AdvCheckbox
                                 name="day-6"
                                 checked={days & 32 ? true : false}
                                 label="ו"
                                 onChange={(e) => this.handleDaysChange('day-6', e)}
                              />
                              <AdvCheckbox
                                 name="day-7"
                                 checked={days & 64 ? true : false}
                                 label="שבת"
                                 onChange={(e) => this.handleDaysChange('day-7', e)}
                              />
                           </div>
                        </div>
                     }
                  </div>

               </fieldset>

            </form>
         </div>
      )
   }
}

ViewDetails.propTypes = {
   checked: PropTypes.bool,
   name: PropTypes.string,
   value: PropTypes.string,
   onChange: PropTypes.func
}

ViewDetails.defaultProps = {
   checked: false
}

export default ViewDetails
