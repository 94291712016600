import * as actionTypes from '../../actions/actionTypes';
import { getLocal } from '../../../utils/LocalStorage'
import customFilterFunction from '../../../utils/customFilterFunction'
import toggleCheckItem from '../../../utils/toggleCheckItem'
import toggleCheckAllItems from '../../../utils/toggleCheckAllItems'
import customSortFunction from '../../../utils/customSortFunction'
import toggleShowSelected from '../../../utils/toggleShowSelected'
import map from 'lodash/map'
import filter from 'lodash/filter'
import some from 'lodash/some'
import toString from 'lodash/toString'
import moment from 'moment'

const dataName = 'pluItemList'

const initialState = {
   results: [],
   [dataName]: [],
   query: '',
}

export default function Presets(state = initialState, action) {
   switch (action.type) {
      case actionTypes.PRESET_ITEMS_SET: 
      return {
         ...state,
         results: action.data
      }
      case actionTypes.PRESET_ITEMS_SET_FILTER: return customFilterFunction({ state, action, dataName })

      default:
         return state
   }
}
