import React from 'react'
import { PropTypes } from 'prop-types'
import Griddle from 'griddle-react'

const TopSellers = (props) => {
    const texts= props.texts
  
  const columnMetaData = [
    {
      columnName: 'SellerName',
      cssClassName: 'table__item',
      displayName: texts.Name
    },
    {
      columnName: 'BranchName',
      cssClassName: 'table__item',
      displayName: texts.Branch
    },
    {
      columnName: 'TotalSales',
      displayName: texts.Income,
      cssClassName: 'table__item',
      customComponent: (props) => <span>{props.data.toLocaleString('en-US', { minimumSignificantDigits: 5})}</span>
    }
  ]

  const rowMetaData = {
    headerCssClassName: 'table__row',
    bodyCssClassName: 'table__row'
  }
  
  return (
    <div className="table__wrap">
      <h2 className="title">{texts.TopSellers}</h2>
        <Griddle 
          columns={['SellerName', 'BranchName', 'TotalSales']}
          columnMetadata={columnMetaData}
          rowMetadata={rowMetaData}
          results={props.data}
          resultsPerPage={10} 
          showPager={false}
          tableClassName="table" 
          useGriddleStyles={false}  />
    </div>
  )
}

TopSellers.propTypes = {
  data: PropTypes.array.isRequired
}

export default TopSellers
