import React from 'react';
import { PropTypes } from 'prop-types';
import DatePicker from "react-datepicker";
import he from 'date-fns/locale/he';
import moment from "moment";

const AdvDateInput = ({ name, onChange, startDate, minDate, label, visible }) => {

  if (!visible === true)
    return null;

  const selected = startDate !== null ? moment(startDate).toDate() : new Date();
  const minimum = minDate !== null ? moment(minDate).toDate() : new Date();
  return (
    <div
      className={name}>
      <label>
        {label}
      </label>
      <DatePicker
        selected={selected}
        minDate={minimum}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
        locale={he}
      />
      <i
        className="bolet-icon-calendar"
      />
    </div>
  )
}

AdvDateInput.propTypes = { 
  name: PropTypes.string,
  onChange: PropTypes.func
}

export default AdvDateInput;
