export default function toggleCheckAllItems(state, dataName) {
  if (state.allSelected) {
    return {
      ...state,
      allSelected: false,
      [dataName]: state[dataName].map(item => {
        return {...item, IsChecked: false}
      }),
      results: state.results.map(item => {
        return {...item, IsChecked: false}
      })
    }
  } else {
    return {
      ...state,
      allSelected: true,
      [dataName]: state[dataName].map(item => {
        return {...item, IsChecked: true}
      }),
      results: state.results.map(item => {
        return {...item, IsChecked: true}
      })
    }
  }
}
