import customFilterFunction from '../utils/customFilterFunction'

export default function toggleShowSelected(state, dataName) {
  //console.log('toggleShowSelected', dataName, state[dataName])
  const action = {
    query: state.query
  }
  if (state.showSelected) {
    const filtered = customFilterFunction({state, action, dataName})
    return {
      ...state,
      ...filtered,
      showSelected: false
    }
  } else {
    return {
      ...state,
      results: state[dataName].filter(item => (item.IsChecked === true) && item),
      showSelected: true,
      query: ''
    }
  } 
}
