import React, { Component } from 'react';
import reactCSS from 'reactcss';
import { TwitterPicker } from 'react-color';

class AdvColorPicker extends Component {

  state = {
    displayColorPicker: false,
    color: {},
  };

  componentDidMount() {
    const { color } = this.props;
    this.setState({ color: color })
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    const { id, onSelect } = this.props;
    this.setState({ color: color.rgb, displayColorPicker: false })
    onSelect(id, color);
  };

  render() {

    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '22px',
          borderRadius: '2px',
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        swatch: {
          background: '#fff',
          padding: '5px',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div className='sale-color-picker'>
        <div style={styles.swatch} onClick={this.handleClick}>
          <span><label>{this.props.title}</label></span>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? <div style={styles.popover}>
          <div style={styles.cover} onClick={this.handleClose} />
          <TwitterPicker
            triangle='top-right'
            color={this.state.color}
            onChange={this.handleChange}
          />
        </div>
          : null}
      </div>
    )
  }
}

export default AdvColorPicker;
