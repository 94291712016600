import React from 'react'
import { PropTypes } from 'prop-types'

const RemoveButton = (props) => {
   const onClick = () => props.onClick()
   return (
      <div
         className="icon-close table__del"
         onClick={onClick}>
      </div>
   )
}

RemoveButton.propTypes = {
   onClick: PropTypes.func.isRequired
}

export default RemoveButton
