import React from 'react';
import { utils, selectors } from 'griddle-react';

const logRowData = griddleKey => (dispatch, getState) => {
    const state = getState();
    const rowData = selectors.rowDataSelector(state, { griddleKey });
    console.log('rowData', rowData);
    return {...rowData};
};

const RowEnhancer = OriginalRow =>
    utils.connect(mapStateToProps, mapDispatchToProps)(({ logRowData, ...props }) => (
        <OriginalRow
        {...logRowData(props.griddleKey)}
         {...props}
            
            // onClick={() => {
            //     console.log(`Click Row ${props.griddleKey}`);
            //     console.log('props', props);
            //     logRowData(props.griddleKey);
            // }}
        />
    ));

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
    logRowData: griddleKey => dispatch(logRowData(griddleKey)),
});

export default RowEnhancer;
