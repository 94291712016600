import React from 'react';
import { PropTypes } from 'prop-types';

const AdvRadioGroup = ({ checked, id, name, value, onChange, label }) => {
    return (

        <div className="radio-group">
            <input
                id={id}
                name={name}
                value={value}
                checked={checked ? 'checked' : false}
                type="radio"
                onChange={onChange}
            />
            <label className="radio-option-label" htmlFor={id}>{label}</label>
        </div>
    )
}

AdvRadioGroup.propTypes = {
    checked: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
}

AdvRadioGroup.defaultProps = {
    checked: false
}

export default AdvRadioGroup;
