import React from 'react';
import { PropTypes } from 'prop-types';
import pick from 'lodash/pick';
import { ReportExcel } from '../../utils/reports/ExcelPDF';
import { HebrewVersionPDF } from '../../utils/reports/HebrewPDF';
import { EnglishVersionPDF } from '../../utils/reports/EnglishPDF';

const Exports = (props) => {
   const viewTrans = () => {
      if (props.results.length) {
         props.openModal({
            className: `modal__trans app__language_${props.lang}`,
            title: props.texts.TableTrans,
            type: 'ReportTrans',
            modalProps: pick(props, ['criterionId', 'dates', 'sellers', 'isChecked', 'lang', 'exportToPDF'])
         })
         props.isChecked(true)
      } else {
         props.isChecked()
      }
   }

   const exportAll = () => {
      let data = {
         Type: 'exportAll',
         Id: props.criterionId,
         StartDate: props.dates.scalestartdate.format("YYYY-MM-DD"),
         EndDate: props.dates.scaleenddate.format("YYYY-MM-DD")
      };
      props.exportToExcel(data)
   }

   const exportToExcel = () => {
      if (props.results.length) {
         ReportExcel(props.lang, props.texts, props.results)
         props.isChecked(true)
      } else { props.isChecked() }
   }

   const saveToPDF = () => {
      if (props.results.length) {
         if (props.lang === 'he') {
            HebrewVersionPDF(props.texts, props.results, props.exportToPDF)

         } else {
            EnglishVersionPDF(props.texts, props.results)
            props.isChecked(true)
         }
      } else { props.isChecked() }
      return true
   }

   return (
      <div className="row buttons__group">
         <div className="col-sm-3">
            <span
               className="btn report__button"
               onClick={viewTrans}>
               {props.texts.ViewTrans}
            </span>
         </div>
         <div className="col-sm-3">
            <span
               className="btn report__button"
               onClick={exportAll}>
               {'יצוא כל העסקאות'}
            </span>
         </div>
         {/* <div className="col-sm-3 col-xs-6">
        <a
          id="pdf_button"
          target="_blank"
          className="btn report__button"
          onClick={saveToPDF}>
        PDF</a>
      </div> */}
         <div className="col-sm-3 col-xs-6">
            <a
               id="excel_button"
               className="btn report__button"
               onClick={exportToExcel}>
               Excel</a>
         </div>
      </div>
   )
}

Exports.propTypes = {
   criterionId: PropTypes.string.isRequired,
   dates: PropTypes.object.isRequired,
   sellers: PropTypes.array.isRequired,
   openModal: PropTypes.func.isRequired
}

export default Exports
