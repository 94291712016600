import React from 'react';
import DeleteItem from "../../assets/images/DeleteItem.png";

const AdvDeleteItemPopup = (props) => {

  if (!props.visible)
    return null;

  return (
    <div className="advertising-popup popup shown-popup">
      <div className="pop-up-wrapper">
        <div className="popup-image">
          <img src={DeleteItem} alt="" />
        </div>
        <div className="pop-up-text">
          <h2>{props.title}</h2>
        </div>
        <div className="pop-up-empty-fields">
        </div>
        <div className="pop-up-buttons">
          <button
            className="red-btn"
            onClick={(e) => props.onClick('delete', e)}
            type="submit">
            מחק
          </button>
          <button
            onClick={(e) => props.onClick('cancel', e)}
            className="grey-btn">
            ביטול
          </button>
        </div>
      </div>
    </div>
  )
}

AdvDeleteItemPopup.defaultProps = {
  checked: false
}

export default AdvDeleteItemPopup;
