
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import ScalesGroups from '../../components/ScalesGroups'
import ViewDetails from './ViewDetails'
import ViewMedia from './ViewPromoMedia'
import ViewMediaGallery from "./ViewMediaGallery";
import AdvDeleteItemPopup from "../../components/advertising/AdvDeleteItemPopup"

import * as actionTypes from '../../store/actions/actionTypes';
import * as actions from '../../store/actions/index';

class Promotion extends React.Component {

   constructor(props) {
      super(props)
      this.state = {
         selected: false,
         currentIndex: 0,
         deleteId: 0,
         deleteItemTitle: '',
         deleteType: '',
         countAdvertising: 0,
         showDeleteItemPopup: false,
         showMediaGalleryPopup: false,
         showSaveButton: false,
         showMedia: true,
      }
   }

   componentWillReceiveProps(nextProps) {
      const { countAdvertising } = this.state;

      if (countAdvertising !== nextProps.selectedPromo.length) {

         switch (nextProps.action) {

            case actionTypes.ADD_ADVERTISING:
               console.log(nextProps.action)
               break;

            case actionTypes.DELETE_ADVERTISING:
               console.log(nextProps.action)
               break;

            case actionTypes.UPDATE_CURRENT_ADVERTISING:
               console.log(nextProps.action)
               break;

            default:
               break;
         }
         this.setState({ countAdvertising: nextProps.selectedPromo.length });
      }
   }

   handleDetailsClick = (type, data = null) => {

      var { actions, selectedPromo } = this.props;
      const { currentIndex } = this.state;

      switch (type) {
         case 'select-all-dates':
            selectedPromo[currentIndex].View.selectDates = false;
            break;

         case 'select-specific-dates':
            selectedPromo[currentIndex].View.selectDates = true;
            break;

         case 'select-all-days':
            selectedPromo[currentIndex].View.selectDays = false;
            break;

         case 'select-specific-days':
            selectedPromo[currentIndex].View.selectDays = true;
            break;

         case 'select-all-hours':
            selectedPromo[currentIndex].View.selectHours = false;
            break;

         case 'select-specific-hours':
            selectedPromo[currentIndex].View.selectHours = true;
            break;

         case 'update-current-index':
            if (this.state.showSaveButton)
               this.handleSumbit();
            var index = selectedPromo.findIndex(x => x.Id === data);
            this.setState({ currentIndex: index });
            break;

         case 'set-duration':
            selectedPromo[currentIndex].Display.duration = data;
            break;

         case 'update-playlist':
            if (data.length === undefined) {
               actions.updateCurrentAdvertising(
                  {
                     Type: 'new-playlist',
                     Group: selectedPromo[currentIndex].Group,
                     Display: {
                        image: data.url,
                     }
                  });
            } else {
               data.forEach(item => {
                  actions.updateCurrentAdvertising(
                     {
                        Type: 'new-playlist',
                        Group: selectedPromo[currentIndex].Group,
                        Display: {
                           image: item,
                        }
                     });
               });
            }
            break;

         case 'set-image':
            this.setState({ showMediaGalleryPopup: true });
            break;

         case 'delete-playlist-item':
            this.setState(oldState => ({
               deleteId: data,
               deleteItemTitle: 'למחוק את הפרסום?',
               deleteType: type,
               showDeleteItemPopup: true
            }));
            break;

         case 'set-date':
            selectedPromo[currentIndex].View.startDate = data.startDate;
            selectedPromo[currentIndex].View.endDate = data.endDate;
            break;

         case 'set-days':
            let days = selectedPromo[currentIndex].View.days;
            switch (data.name) {
               case 'day-1':
                  if (data.value)
                     days |= 0x01;
                  else
                     days &= 0xfe;
                  break;

               case 'day-2':
                  if (data.value)
                     days |= 0x02;
                  else
                     days &= 0xfd;
                  break;

               case 'day-3':
                  if (data.value)
                     days |= 0x04;
                  else
                     days &= 0xfb;
                  break;

               case 'day-4':
                  if (data.value)
                     days |= 0x08;
                  else
                     days &= 0xf7;
                  break;

               case 'day-5':
                  if (data.value)
                     days |= 0x10;
                  else
                     days &= 0xef;
                  break;

               case 'day-6':
                  if (data.value)
                     days |= 0x20;
                  else
                     days &= 0xdf;
                  break;

               case 'day-7':
                  if (data.value)
                     days |= 0x40;
                  else
                     days &= 0xbf;
                  break;

               default:
                  break;
            }

            selectedPromo[currentIndex].View.days = days;
            break;

         case 'set-start-hour':
            selectedPromo[currentIndex].View.startHour = data;

            if (selectedPromo[currentIndex].View.startHour !== null && selectedPromo[currentIndex].View.endHour !== null &&
               parseInt(selectedPromo[currentIndex].View.startHour.split(':')[0]) > parseInt(selectedPromo[currentIndex].View.endHour.split(':')[0]))
               selectedPromo[currentIndex].View.endHour = selectedPromo[currentIndex].View.startHour;
            break;

         case 'set-end-hour':
            selectedPromo[currentIndex].View.endHour = data;

            if (selectedPromo[currentIndex].View.startHour !== null && selectedPromo[currentIndex].View.endHour !== null &&
               parseInt(selectedPromo[currentIndex].View.startHour.split(':')[0]) > parseInt(selectedPromo[currentIndex].View.endHour.split(':')[0]))
               selectedPromo[currentIndex].View.endHour = selectedPromo[currentIndex].View.startHour;
            break;

         default:
            alert(type)
      }

      if (type.includes('set') || type.includes('select'))
         this.setState({ showSaveButton: true });
      else
         this.setState({});
   }

   handleItemDelete = (type, e) => {
      if (type === 'delete') {
         const { deleteId, deleteType } = this.state;
         const { selectedPromo, actions } = this.props;

         switch (deleteType) {
            case 'delete-playlist-item':
               let index = selectedPromo.findIndex(x => x.Id === deleteId);
               actions.deleteAdvertising(selectedPromo[index].Group, deleteId);
               break;

            default:
               break;
         }
      }
      this.setState(prevState => ({
         deleteId: 0,
         showDeleteItemPopup: false
      }));
   }

   handleSumbit = (data, e = null) => {
      if (e !== null)
         e.preventDefault();
      const { actions, selectedPromo } = this.props;
      const { currentIndex } = this.state;
      actions.updateCurrentAdvertising(selectedPromo[currentIndex]);
      this.setState({ showSaveButton: false });
   }

   handleMediaGalleryClosed = () => {
      console.log('handleMediaGalleryClosed')
      this.setState({ showMediaGalleryPopup: false });
   }

   handleMediaGallerySubmited = (data, e) => {
      console.log('handleMediaGallerySubmited')
      const { selectedPromo } = this.props;
      const { currentIndex } = this.state;
      selectedPromo[currentIndex].Display.image = data.url;
      this.setState({ showMediaGalleryPopup: false });
   }

   handleDistributePromotion = () => {
      this.props.actions.DistributePromotion();
   }

   render() {

      const {
         currentIndex,
         deleteItemTitle,
         showDeleteItemPopup,
         showMediaGalleryPopup,
         showSaveButton
      } = this.state;

      const {
         selectedPromo,
         selectedGroupId
      } = this.props;

      if (!selectedGroupId || (selectedGroupId && typeof selectedPromo[currentIndex] === 'undefined')) {
         return (
            <section className="managment-system">
               <div className="container">
                  <div className="row">
                     <div className="col-md-2 col-xs-12">
                        <ScalesGroups source="promo" />
                     </div>
                  </div>
               </div>
            </section>
         )
      }
      else {
         return (
            <section className="managment-system">
               <div className="container">
                  <div className="row">
                     <div className="col-md-2 col-xs-12">
                        <ScalesGroups source="promo" />
                     </div>
                     <div className="col-md-5 col-xs-12">
                        <ViewMedia
                           data={selectedPromo}
                           currentIndex={currentIndex}
                           onClick={this.handleDetailsClick}
                        />
                        <ViewDetails
                           showImage={true}
                           data={selectedPromo[currentIndex]}
                           showSaveButton={showSaveButton}
                           onClick={this.handleDetailsClick}
                           onSubmit={this.handleSumbit}
                           onDistributePromotion={this.handleDistributePromotion}
                        />
                        <AdvDeleteItemPopup
                           visible={showDeleteItemPopup}
                           title={deleteItemTitle}
                           onClick={this.handleItemDelete}
                        />
                        <ViewMediaGallery // used for selecting image by pressing the 'empty image' placeholder.
                           visible={showMediaGalleryPopup}
                           singleSelection={true}
                           imageFilter='!logo'
                           //media={media !== undefined ? media.filter(x => !x.url.includes('logo')) : undefined}
                           onClose={this.handleMediaGalleryClosed}
                           onSubmit={this.handleMediaGallerySubmited}
                        />
                     </div>
                  </div>
               </div>
            </section>
         )
      }
   }
}

function mapStateToProps(state) {
   return {
      selectedGroupId: state.App.selectedGroupId,
      selectedPromo: state.App.selectedPromo,
      action: state.App.action,
      media: state.App.Media,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: bindActionCreators(actions, dispatch)
   }
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Promotion);
