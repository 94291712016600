import React from 'react'
import {PropTypes} from 'prop-types'
import {Chart} from 'react-google-charts'
import moment from 'moment'

import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import map from 'lodash/map'
import find from 'lodash/find'
// import forEach from 'lodash/forEach'

class  HourlyTrans extends React.Component {  
  state = {
    windowWidth: window.innerWidth
  }
  

  handleResize() {
    this.setState({windowWidth: window.innerWidth})
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this))
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this))
  }
  
  
  render() {
    const props = this.props
    var options = {
      hAxis: {
        format: ':0',
        baseline: 6,
        baselineColor: 'white',
        ticks: [{v: 6, f: '6:00'}, {v: 8, f: '8:00'}, {v: 10, f: '10:00'}, {v: 12, f: '12:00'}, {v: 14, f: '14:00'}, {v: 16, f: '16:00'}, {v: 18, f: '18:00'}, {v: 20, f: '20:00'}, {v: 22, f: '22:00'}, {v: 24, f: '24:00'}],
        gridlines: {color: '#ddd'}
      },
      vAxis: {
        gridlines: {color: 'transparent'},
        minValue: 0,
        ticks: [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500]
      },
      isStacked: true,
      pointSize: 5,
      legend: {
        position: this.state.windowWidth > 730 ? 'right' : 'top'
      }
    }

    const convertedTime = map(props.data, item => ({...item, Time: Math.round(+moment(item.Time).format('HH.mm')) === 0 ? 24 : Math.round(+moment(item.Time).format('HH.mm'))}))
    const uniqId = uniq(map(props.data, item => item.BranchId))
    const uniqTime = uniq(map(convertedTime, 'Time'))
    const uniqNames = map(uniqBy(props.data, 'BranchName'), item => item.BranchName)

    const data = [
      ['Time', ...uniqNames],
      ...map(uniqTime, item => [item, ...map(uniqId, value => find(convertedTime, {Time: item, BranchId: value}).TotalSales)])
    ]

    return (
      <div className="graph">
        <h2 className="title">{props.text}</h2>
        {props.data.length && (
          <div className="responsive-chart">
            <Chart
              chartType = "LineChart"
              data={data}
              options={options}
              graph_id = "ScatterChart"
              width={this.state.windowWidth > 730 ? '100%' : '680px'}
              height={'400px'}
              legend_toggle={true} />
          </div>
        )}
      </div>
    )
  }
}

HourlyTrans.propTypes = {
  data: PropTypes.array.isRequired
}

export default HourlyTrans
