import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect, Link } from 'react-router-dom';

import asyncComponent from './hoc/asyncComponent/asyncComponent';
import * as actions from './store/actions/index'
import moment from 'moment'

import SignIn from './components/SignIn'
import Layout from './hoc/Layout/Layout';
import Logout from './containers/Auth/Logout/Logout';
import ReportPage from './containers/Vendors/ReportPage';


import asyncStatus from './containers/Scales/Status';
import asyncUsers from './containers/Settings/Users';
import asyncScaleGroups from './containers/Settings/ScaleGroups';
import asyncCreateReport from './containers/Vendors/CreateReport'
import asyncCheckout from './containers/Checkout/Checkout'
import asyncDashboard from './containers/Dashboard'
import asyncReportList from './containers/Vendors/ReportList'
import asyncPromotions from './containers/Advertising/Promotion'
import asyncSales from './containers/Advertising/Sales'
import asyncScales from './containers/Scales/Scales'
import asyncPresets from './containers/Scales/Presets'
import asyncPresetsScales from './containers/Scales/presetsScales'
import asyncParameters from './containers/Scales/Parameters'
import asyncDepartments from './containers/Settings/Departments'
import asyncBranches from './containers/Settings/Branches'

/*
const asyncCreateReport = asyncComponent(() => {
  return import('./containers/Vendors/CreateReport');
});
const asyncCheckout = asyncComponent(() => {
  return import('./containers/Checkout/Checkout');
});
const asyncDashboard = asyncComponent(() => {
  return import('./containers/Dashboard');
});
const asyncReportList = asyncComponent(() => {
  return import('./containers/Vendors/ReportList');
});
const asyncPromotions = asyncComponent(() => {
  return import('./containers/Advertising/Promotion');
});
const asyncSales = asyncComponent(() => {
  return import('./containers/Advertising/Sales');
});
const asyncScales = asyncComponent(() => {
  return import('./containers/Scales/Scales');
});
const asyncPresets = asyncComponent(() => {
  return import('./containers/Scales/Presets');
});
const asyncParameters = asyncComponent(() => {
  return import('./containers/Scales/Parameters');
});
const asyncDepartments = asyncComponent(() => {
  return import('./containers/Settings/Departments');
});
const asyncBranches = asyncComponent(() => {
  return import('./containers/Settings/Branches');
});
const asyncUsers = asyncComponent(() => {
  return import('./containers/Settings/Users');
});
const asyncScaleGroups = asyncComponent(() => {
  return import('./containers/Settings/ScaleGroups');
});
const asyncStatus = asyncComponent(() => {
  return import('./containers/Scales/Status');
});
*/
global.log = (...msgs) => { if (process.env.NODE_ENV === 'development') console.log(...msgs) }

class App extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  componentDidMount() {
    this.props.actions.initSystem()
    this.props.actions.getLastUpdate()
  }

  render() {
    //console.log('App render');
    const { app, actions } = this.props;

    let routes = (
      <Switch>
        {/* <Route path="/auth" component={asyncAuth} /> */}
        <Route path="/" exact component={SignIn} />
        <Redirect to="/" />
      </Switch>
    );

    if (app.isLogined) {
      routes = (
        <Switch>
          <Route path="/checkout" component={asyncCheckout} />
          <Route path="/logout" component={Logout} />

          <Route exact path="/vendors/reportlist" component={asyncReportList} />
          <Route path='/vendors/reportlist/:id/:name' component={ReportPage} />
          <Route path="/vendors/createreport" component={asyncCreateReport} />

          <Route path="/promotions/playlist" component={asyncPromotions} />
          <Route path="/promotions/sales" component={asyncSales} />

          <Route path="/scales/presets" component={asyncPresets} />
          <Route path="/scales/parameters" component={asyncParameters} />
          <Route path="/scales/presetsScales" component={asyncPresetsScales} />
          <Route path="/scales/status" component={asyncStatus} />

          <Route path="/settings/departments" component={asyncDepartments} />
          <Route path="/settings/branches" component={asyncBranches} />
          <Route path="/settings/users" component={asyncUsers} />
          <Route path="/settings/scalegroups" component={asyncScaleGroups} />

          <Route path="/" exact component={asyncDashboard} />
        </Switch>
      );
    }

    moment.locale(app.lang);

    return (
      <div>
        <Layout>
          {routes}
        </Layout>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    app: state.App
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

App.propTypes = {
  app: PropTypes.shape({
    isLogined: PropTypes.bool.isRequired
  }).isRequired,
  actions: PropTypes.shape({
    closeModal: PropTypes.func.isRequired,
    initSystem: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    systemLogIn: PropTypes.func.isRequired,
    systemLogOut: PropTypes.func.isRequired
  }).isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
