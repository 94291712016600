import React from 'react'
import { PropTypes } from 'prop-types'

const Checkbox = ({ checked, name, onChange, label, className }) => {
  return (
    <div className={`checkbox ${className}`}>
      <input
        id={name}
        checked={checked ? 'checked' : false}
        className="checkbox__input"
        type="checkbox"
        onChange={(e) => onChange(e)} />
      <label className="checkbox__label" htmlFor={name}>{label}</label>
    </div>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func
}

Checkbox.defaultProps = {
  checked: false
}

export default Checkbox
