import React from 'react';
import { PropTypes } from 'prop-types';

const ErrorHandler = (props) => {
  return (
    <div className="modal-body">
      <p>{props.message}</p>
    </div>
  )
}

ErrorHandler.propTypes = {
  message: PropTypes.string
}

export default ErrorHandler;
