import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Griddle, { RowDefinition, ColumnDefinition, plugins } from 'griddle-react';
import { Redirect } from 'react-router-dom';
import Formsy from 'formsy-react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

import * as actions from '../../store/actions/index';

import CreateReportTop from '../../layout/createReport/CreateReportTop';
import SearchInput from '../../components/SearchInput';
import Tree from '../../components/tree/Tree';
import Checkbox from '../../components/Checkbox';

// const enhancedWithRowData = connect((state, props) => {
//   return {
//     rowData: state.CreateReport.results[props.griddleKey]
//   };
// });

const rowDataSelector = (state, { griddleKey }) => {
  return state
    .get('data')
    .find(r => r.get('griddleKey') === griddleKey)
    .toJSON();
};

const enhancedWithRowData = connect((state, props) => {
  return {
    rowData: rowDataSelector(state, props),
  };
});

const NoResults = (text) => {
  text = 'אין נתונים זמינים';
  return (
    <h3>{text}</h3>
  )
}

export class CreateReport extends React.Component {
  state = {
    BranchList: [],
    isUpdate: !isEmpty(this.props.match.params.id),
    saved: false
  }

  static contextTypes = {
    router: PropTypes.object
  }

  componentDidMount() {
    const id = this.props.match.params.id
    if (id) {
      this.props.actions.getSellerReportCriterion(id)
    } else {
      this.props.actions.allocateSellerReportCriterion()
    }
  }

  setBranchList(item) {
    this.props.actions.setBranchList(item.Id)
  }

  submit(model) {

    const BranchList = map(filter(this.props.createReport.branchList, item => item.IsChecked && item), value => ({
      Id: value.Id,
      Name: value.Name,
      IsChecked: value.IsChecked,
      Departments: map(value.Departments.filter(scale => scale.IsChecked), item => (item.Id))
    }))

    const data = {
      ...model,
      BranchList,
      Id: this.props.createReport.Id,
      ItemCodes: map(filter(this.props.createReport.results, item => (item.IsChecked && !item.IsGroup) === true && item), value =>
      (
        value.Id
      )),
    }
    this.props.actions.updateSellerReportCriterionData(data);
    this.setState({ saved: true });
  }

  render() {
    const { createReport, actions, app } = this.props
    const { isUpdate } = this.state
    const texts = !isEmpty(app.Texts) && app.Texts.Vendors.CreateReport[app.lang]
    const tableTexts = !isEmpty(app.Texts) && app.Texts.Table[app.lang]

    const styleConfig = {
      icons: {
        TableHeadingCell: {
          sortDescendingIcon: <small>(desc)</small>,
          sortAscendingIcon: <small>(asc)</small>,
        },
      },
      classNames: {
        Table: 'table',
        TableBody: 'table__row',
        TableHeading: 'table__row',
        TableHeadingCell: 'table__item',
        Row: 'table__row'
      },
      styles: {}
    }

    //console.log('CreateReport render', this.props);
    return (
      <div className="vendors">
        <div className="report-list__wrapper">
          <div className="create-report">
            {this.state.saved && <Redirect to='/vendors/reportlist' />}
            <Formsy onValidSubmit={this.submit.bind(this)}>
              <CreateReportTop
                texts={pick(texts, ['Active', 'CreateDate', 'CriterionName', 'InsertReportName', 'UpdateDate', 'ValidationMessage'])}
                isUpdate={isUpdate}
                data={this.props.createReport.reportCriterionData} />
              <hr />
              <div className="row ">
                <div className="col-sm-2 "></div>
                <div className="col-sm-6 col-sm-push-4">
                  <div className="choose__filter_mobile">
                    <h3 className="title">{texts.ChooseBranchesAndDepts}</h3>
                    <Tree
                      data={this.props.createReport.branchList}
                      setBranchList={this.props.actions.setBranchList}
                      setBranchDeptList={this.props.actions.setBranchDeptList} />
                  </div>

                  <div className="pluitem-list__filter search clearfix">
                    <SearchInput placeholder={texts.ItemSearch} onChange={actions.createReportSetFilter} value={createReport.query} />
                    <div className="checkbox">
                      <input id="showSelected" className="checkbox__input" type="checkbox" onChange={actions.toggleShowSelected} />
                      <label className="checkbox__label" htmlFor="showSelected">{texts.ShowSelected}</label>
                    </div>
                    <div className="table__found-items text-right">
                      <span className="table__found-item">{createReport.results.length} {texts.Items}</span>
                    </div>
                  </div>
                  <div className="table__wrap">
                    <Griddle
                      styleConfig={styleConfig}
                      data={createReport.results}
                      plugins={[plugins.LocalPlugin, plugins.PositionPlugin({ tableHeight: 400 })]}
                      pageProperties={{
                        currentPage: 1,
                        pageSize: 100
                      }}
                      components={{
                        Layout: ({ Table }) => <Table />,
                        NoResults: (tableTexts) => <NoResults text={tableTexts.NoData} />
                      }}
                    >
                      <RowDefinition>
                        <ColumnDefinition
                          id='IsChecked'
                          title=' '
                          cssClassName='table__item text-center'
                          customHeadingComponent={() => {
                            return <Checkbox
                              name="reportPage__allSelected"
                              checked={createReport.allSelected}
                              onChange={() => actions.createReportToggleCheckAllItems()} />
                          }}
                          customComponent={enhancedWithRowData((props) => {
                            return <Checkbox
                              name={props.rowData.Name}
                              checked={props.rowData.IsChecked}
                              onChange={() => actions.createReportToggleCheckItem(props.rowData.Id)} />
                          })}
                        />
                        <ColumnDefinition
                          id='Id'
                          title={texts.ID}
                          cssClassName='table__item'
                        />
                        <ColumnDefinition
                          id='Name'
                          title={texts.Name}
                          cssClassName='table__item'
                        />
                        {/* <ColumnDefinition
                              id='IsGroup'
                              title={texts.Group}
                              cssClassName='table__item'
                              customComponent={enhancedWithRowData((props) => <Checkbox checked={props.rowData.IsGroup} />)}
                           /> */}
                      </RowDefinition>
                    </Griddle>
                  </div>

                  <div className="row submit__block">
                    <div className="col-sm-8 col-sm-offset-4">
                      <button className="btn btn-primary report__button">
                        {isUpdate ? texts.UpdateReport : texts.SaveReport}
                      </button>
                      {createReport.hasUpdate
                        ? <div className="text-success text-center">The report has been updated</div>
                        : null}
                    </div>
                  </div>

                </div>
                <div className="col-sm-4 col-sm-pull-8 hidden-xs">
                  <h3 className="title">{texts.ChooseBranchesAndDepts}</h3>
                  <Tree
                    data={this.props.createReport.branchList}
                    setBranchList={this.props.actions.setBranchList}
                    setBranchDeptList={this.props.actions.setBranchDeptList} />
                </div>
              </div>
            </Formsy>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    app: state.App,
    createReport: state.CreateReport,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateReport)
