export function dataJSON(headers, rows) {
  return {
    Header: headers.toString(),
    Rows: rows
  }
}

function extendArray(a, b){
    for (let i = 0; i < b.length; i++) {
        a.push(b[i].toString());
    }
    return a;
}

export function fromObjectToArray(data) {
  let tmpArr = [];
  data.map(item => extendArray(tmpArr, Object.values(item)))
  return tmpArr
}
