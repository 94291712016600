import React, { Component } from 'react'
import AddSaleImage from "../../assets/images/add-sale-pop.png";
import EditSaleImage from "../../assets/images/edit-sale-pop.png";
import AddLayoutImage from "../../assets/images/add-layout.png";

import classes from './AdvSaleNamePopup.module.scss';

export default class AdvCreateSalePopup extends Component {

   state = {
      fields: {},
      errorStr: '',
   }

   handleKeyPressed = (count, e) => {
      if (e.key === 'Enter' && Object.keys(this.state.fields).length === count) {
         this.props.onAccept(this.state.fields, e);
      }
   }

   handleChange = (field, e) => {
      this.props.onChange(field, e.target.value);
      let { fields, errorStr } = this.state;
      fields[field] = e.target.value;
      if (field === 'id' && isNaN(e.target.value) === true)
         errorStr = "קוד לא תקין";
      else
         errorStr = "";
      this.setState({ fields, errorStr });
   }

   Parameters = (parameters, onChange, onKeyPressed) => {

      if (parameters === undefined)
         return null;

      return (
         parameters.map((param, index) => (() => {

            if (param.type === 'text') {
               return (
                  <div key={'text' + param.field} className="row">
                     <div className="form-group">
                        <label htmlFor={param.field}>{param.title}</label>
                        <input
                           type="text"
                           autoFocus={param.field === 1 ? true : false}
                           className="form-control"
                           name={param.field}
                           placeholder={param.info}
                           id={param.field}
                           onChange={(e) => onChange(param.name, e)}
                           onKeyPress={(e) => onKeyPressed(parameters.length, e)}
                        />
                     </div>
                  </div>
               )
            }
            else if (param.type === 'combo') {
               return (
                  <div key={'combo' + param.field} className="row">
                     <div className="form-group">
                        <label htmlFor={param.field}>{param.title}</label>
                        <select
                           defaultValue='000'
                           name={param.field}
                           id={param.field}
                           className="form-control"
                           onChange={(e) => onChange(param.name, e)}
                        >
                           <option value="000" disabled hidden>בחר אפשרות..</option>
                           {param.options.map((option, index) =>
                              <option key={option.key} value={option.value}>{option.title}</option>
                           )}
                        </select>
                     </div>
                  </div>
               )
            }
            else return null;
         })())
      )
   }

   render() {
      const { visible, type, name, fields, onClose, onAccept } = this.props;

      if (!visible)
         return null;

      let data;

      switch (type) {

         case 'edit-sale-name':
            data = {
               header: 'עריכת מבצע',
               okBtn: 'עדכן',
               image: EditSaleImage,
               parameters: [
                  { field: 1, type: "text", name: "name", title: "שם המבצע", info: "" },
               ]
            };
            break;

         case 'new-sale':
            data = {
               header: 'יצירת מבצע',
               okBtn: 'הוספה',
               image: AddSaleImage,
               parameters: [
                  { field: 1, type: "text", name: "name", title: "שם המבצע", info: "" },
               ]
            };
            break;

         case 'new-layout':
            data = {
               header: 'יצירת שבלונה',
               okBtn: 'הוספה',
               image: AddLayoutImage,
               parameters: [
                  { field: 1, type: "text", name: "name", title: "שם שבלונה", info: "" },
               ]
            };
            break;

         case 'duplicate-layout':
            data = {
               header: 'שכפול שבלונה',
               okBtn: 'הוספה',
               image: AddLayoutImage,
               parameters: [
                  { field: 1, type: "text", name: "name", title: "שם שבלונה", info: "" },
               ]
            };
            break;

         case 'edit-layout':
            const currentName = name === null ? '' : `${name.label}`;
            data = {
               header: 'עריכת שבלונה',
               subHeader: currentName,
               okBtn: 'עדכן',
               image: AddLayoutImage,
               parameters: [
                  { field: 1, type: "text", name: "name", title: "שם שבלונה", info: "" },
               ]
            };
            break;

         case 'new-preset-group':
            data = {
               header: 'יצירת קבוצת פרמטרים',
               okBtn: 'הוספה',
               image: AddLayoutImage,
               parameters: [
                  { field: 1, type: "text", name: "name", title: "שם קבוצה", info: "" },
                  ...fields
               ]
            };
            break;

         case 'edit-preset-group':
            data = {
               header: 'עריכת קבוצת פרמטרים',
               okBtn: 'עדכן',
               image: AddLayoutImage,
               parameters: [
                  { field: 1, type: "text", name: "name", title: "שם קבוצה", info: "" },
               ]
            };
            break;

         case 'new-movie':
            data = {
               header: 'שם סרטון',
               okBtn: 'הוספה',
               image: AddSaleImage,
               parameters: [
                  { field: 1, type: "text", name: "name", title: "שם הסרטון", info: "" },
               ]
            };
            break;

         case 'new-group':
            data = {
               header: 'יצירת קבוצה',
               okBtn: 'הוספה',
               image: AddSaleImage,
               parameters: [
                  { field: 1, type: "text", name: "name", title: "שם קבוצה", info: "" },
                  {
                     field: 1, type: "combo", name: "type", title: "סיווג", info: "", options: [
                        { key: 'option1', value: 1, name: "", title: "פרסום" },
                        { key: 'option2', value: 2, name: "", title: "מבצעים ודרבון" },
                        { key: 'option3', value: 3, name: "", title: "פרמטרים ופריסטים" },
                     ]
                  },

               ]
            };
            break;

         default:
      }

      return (
         <div className="advertising-popup popup shown-popup">
            <div className={classes.wrapper}>
               <div className={classes.image}>
                  <img src={data.image} alt="" />
               </div>
               <div className={classes.text}>
                  <h2>{data.header}</h2>
                  <h2>{data.subHeader}</h2>
               </div>
               <div className={classes.fields}>
                  <form action="">
                     {this.Parameters(data.parameters, this.handleChange, this.handleKeyPressed)}
                     <div className={classes.error}>
                        <label >{this.state.errorStr}</label>
                     </div>
                  </form>
               </div>
               <div className={classes.buttons}>
                  {this.state.errorStr === '' &&
                     <button className="green-btn" type="submit" onClick={(e) => onAccept(this.state.fields, e)} >
                        {data.okBtn}
                     </button>}
                  <button className="grey-btn" onClick={onClose} >
                     ביטול
                  </button>
               </div>
            </div>
         </div>
      )
   }
}
