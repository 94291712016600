import Http from '../../utils/Http'
import {getLocal} from '../../utils/LocalStorage'
import * as actionTypes from '../actions/actionTypes';

export function getDashboardDetails(data) {
  return function(dispatch) {
    Http(`GetDashboardDetails/${getLocal('SessionKey')}`, 'GET', data)
      .then(
        response => {
          dispatch({
            type: actionTypes.GET_DASHBOARD_DETAILS,
            payload: response,
            branches: data.branches
          })
        }
      )
  }
}
