import * as actionTypes from '../actions/actionTypes';

function dispatchWrap(dispatch, message) {
  dispatch({
    type: 'TOGGLE_MODAL',
    modal: {
      isOpen: true,
      message,
      title: 'Error handler',
      type: 'ErrorHandler'
      
    }
  })
}

 const errorHandler = store => next => action => {
  if (action.type === actionTypes.REQUEST_FAIL) {
    switch (action.payload.status) {
      case 400:
        return dispatchWrap(store.dispatch, action.payload.statusText)
    
      default:
        return dispatchWrap(store.dispatch, action.payload.ErrorCode)
      }
  }

  return next(action)
}

export default errorHandler
