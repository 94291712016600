import * as actionTypes from './actionTypes';
import Http from '../../utils/Http'
import HttpJSON from '../../utils/HttpJSON'
import moment from "moment";
import {
   getLocal,
   setLocal
} from '../../utils/LocalStorage';

import 'isomorphic-fetch';
import texts from '../../texts.json';
import produce from 'immer';

const Configuration = require('../../config.json');

function _login(dispatch) {
   dispatch({
      type: actionTypes.INIT_SYSTEM,
      payload: {
         isLogined: true,
         SessionKey: getLocal('SessionKey'),
         UserProfile: getLocal('UserProfile'),
         Configuration: Configuration
      },
      path: '/'
   })
}

function _logout(dispatch) {
   localStorage.removeItem('SessionKey')
   localStorage.removeItem('UserProfile')
   const port = process.env.NODE_ENV === 'development' ? '80' : window.location.port;
   dispatch({
      type: actionTypes.INIT_SYSTEM,
      payload: {
         isLogined: false,
         apiUrl: `${window.location.protocol}//${window.location.hostname}:${port}/service/service.svc`,
         showDashboard: Configuration.showDashboard,
         activeModules: Configuration.activeModules
      },
      path: '/'
   })
}

export function initSystem() {
   return function (dispatch) {
      dispatch({
         type: actionTypes.SET_TEXTS,
         payload: texts//response
      })
      _logout(dispatch)
   }
}

export function changeLang(lang) {
   console.log('changeLang', lang);
   return function (dispatch) {
      dispatch({ type: actionTypes.CHANGE_LANG, lang })
      setLocal('lang', lang)
   }
}

export function systemLogIn(data) {
   return function (dispatch) {
      Http('SystemLogIn', 'GET', data)
         .then(
            (response) => {
               if (response.ErrorCode !== 'NoError') {
                  dispatch({ type: actionTypes.LOGIN_FAIL, errorMessage: response.ErrorCode })
               } else {
                  setLocal('SessionKey', response.SessionKey)
                  setLocal('UserProfile', response.UserProfile)
                  _login(dispatch)
               }
            },
            response => dispatch({ type: actionTypes.LOGIN_FAIL, payload: response })
         )
   }
}

export function systemLogOut() {
   return function (dispatch) {
      _logout(dispatch)
   }
}

export function openModal(params) {
   return function (dispatch) {
      dispatch({
         type: actionTypes.TOGGLE_MODAL,
         modal: {
            isOpen: true,
            ...params
         }
      })
   }
}

export function closeModal() {
   return function (dispatch) {
      dispatch({
         type: actionTypes.TOGGLE_MODAL,
         modal: {
            isOpen: false
         }
      })
   }
}

export function toggleMenuView() {
   return function (dispatch) {
      dispatch({
         type: actionTypes.TOGGLE_MENU_VIEW
      })
   }
}

export function toggleSidebarView(name) {
   return function (dispatch) {
      dispatch({
         type: actionTypes.TOGGLE_SIDEBAR_VIEW,
         name
      })
   }
}

export function getLastUpdate() {
   return function (dispatch) {
      Http(`GetLastUpdate/${getLocal('SessionKey')}`, 'GET')
         .then(response => dispatch({ type: actionTypes.GET_LAST_UPDATE, payload: response }))
   }
}

// Action Creator

// Thunk
export function selectScaleGroup(groupId, group, source) {
   return async (dispatch, getstate) => {

      dispatch({ type: actionTypes.SELECT_SCALE_GROUP, payload: { groupId, group } });

      let result;
      switch (source) {
         case 'promo':
            result = await Http(`GetAdvertising`, 'GET', { id: groupId });
            dispatch({ type: actionTypes.NEW_PROMO_DATA, payload: result });
            break;

         case 'sales':
            result = await Http(`GetAdvertising`, 'GET', { id: groupId });
            dispatch({ type: actionTypes.NEW_SALE_DATA, payload: result });
            break;

         case 'scale':
            result = await Http(`GetParameters`, 'GET', { id: groupId });
            dispatch({ type: actionTypes.GET_GROUP_PARAMETERS_DATA, payload: result });
            dispatch({ type: actionTypes.GET_GROUP_PRESETS_DATA, payload: groupId });
            break;
      }
   }
}

export function deleteAdvertising(group, id) {
   return async (dispatch, getstate) => {
      const result = await HttpJSON(`deleteAdvertising`, 'POST', { Group: group, Id: id });
      dispatch({ type: actionTypes.DELETE_ADVERTISING, payload: result });
   }
}

export function hideTemplatePopup(url) {
   return async (dispatch, getstate) => {
      await Http(`deleteAdvertisingTemplateUrl`, 'GET', { url: url });
      dispatch({ type: actionTypes.HIDE_TEMPLATE });
   }
}

export function getAdvertisingTemplateView(id) {
   return async (dispatch, getstate) => {
      const result = await Http(`getAdvertisingTemplateView`, 'GET', { id: id });
      dispatch({ type: actionTypes.SHOW_TEMPLATE, payload: result.url });
   }
}

export function DistributePromotion() {
   return async (dispatch, getstate) => {
      await Http(`DistributePromotion`, 'GET');
      alert("משקלים עודכנו");
      dispatch({ type: actionTypes.DISTRIBUTE_PROMOTION });
   }
}

export function updateAdvertising(group, id, type, name) {
   return async (dispatch, getstate) => {
      const result = await HttpJSON(`updateAdvertising`, 'POST', { Group: group, Id: id, Type: type, Name: name });
      dispatch({ type: actionTypes.ADD_ADVERTISING, payload: result });
   }
}

export function refreshAdvertising(data) {
   return async (dispatch, getstate) => {
      dispatch({ type: actionTypes.REFRESH_ADVERTISING, payload: data });
   }
}

export function updateCurrentAdvertising(data) {
   if (data.View !== undefined) {
      data = produce(data, draft => {
         if (data.View.startDate !== undefined)
            draft.View.startDate = moment(data.View.startDate).format('YYYY-MM-DD')
         if (data.View.endDate !== undefined)
            draft.View.endDate = moment(data.View.endDate).format('YYYY-MM-DD')
      });
   }
   return async (dispatch, getstate) => {
      const result = await HttpJSON(`updateAdvertising`, 'POST', data);
      dispatch({ type: actionTypes.UPDATE_CURRENT_ADVERTISING, payload: result });
   }
}

export const toggleTodo = id => {
   return {
      type: 'TOGGLE_TODO',
      id
   }
}

export function UpdateDepartments(data) {
   return async (dispatch, getstate) => {
      const result = await HttpJSON(`UpdateDepartments`, 'POST', data);
      dispatch({ type: actionTypes.UPDATE_DEPARTMENTS, payload: result });
   }
}

export function GetMedia(data) {
   return async (dispatch, getstate) => {
      const result = await HttpJSON(`GetMedia`, 'GET');
      dispatch({ type: actionTypes.GET_MEDIA, payload: result });
   }
}

export function UpdateMedia(data) {
   return async (dispatch, getstate) => {
      dispatch({ type: actionTypes.UPDATE_MEDIA, payload: data });
   }
}

export function DeleteMedia(data) {
   const fileName = data.substring(data.lastIndexOf("/") + 1);
   console.log('delete', fileName, data)
   return async (dispatch, getstate) => {
      const result = await HttpJSON(`DeleteMedia?fileName=${fileName}`, 'GET');
      dispatch({ type: actionTypes.DELETE_MEDIA, payload: result });
   }
}

export function UpdateBranches(data) {
   return async (dispatch, getstate) => {

      try {
         const result = await HttpJSON(`UpdateBranches`, 'POST', data);
         dispatch({ type: actionTypes.UPDATE_BRANCHES, payload: result });
      } catch (err) {
         console.log('error UpdateBranches - ' + err.statusText);
      }
   }
}

export function UpdateUsers(data) {
   return async (dispatch, getstate) => {
      const result = await HttpJSON(`UpdateUsers`, 'POST', data);
      dispatch({ type: actionTypes.UPDATE_USERS, payload: result });
   }
}

export function UpdateGroup(data) {
   return async (dispatch, getstate) => {
      const result = await HttpJSON(`UpdateGroup`, 'POST', data);
      dispatch({ type: actionTypes.UPDATE_GROUPS, payload: result });
   }
}

export function UpdateBranchesData(data) {
   return async (dispatch, getstate) => {
      dispatch({ type: actionTypes.UPDATE_BRANCHES, payload: data });
   }
}

export function AddGroup(name, type) {
   return async (dispatch, getstate) => {
      const result = await Http(`AddGroup?name=${name}&type=${type}`, 'POST');
      dispatch({ type: actionTypes.UPDATE_GROUPS, payload: result });
   }
}

export function DeleteGroup(id) {
   return async (dispatch, getstate) => {
      const result = await Http(`DeleteGroup?id=${id}`, 'POST');
      dispatch({ type: actionTypes.UPDATE_GROUPS, payload: result });
   }
}

export function UpdateSelectedGroup(id) {
   return async (dispatch, getstate) => {
      dispatch({ type: actionTypes.UPDATE_SELECTED_GROUP, payload: id });
   }
}

export function UpdateUserText(data) {
   return async (dispatch, getstate) => {
      dispatch({ type: actionTypes.UPDATE_USER_TEXT, payload: data });
   }
}

export function UpdateStoreSelectedGroup(data) {
   return (dispatch) => {
      dispatch({
         type: actionTypes.UPDATE_CURRENT_ADVERTISING,
         payload: data
      })
   }
}

export function ShowPresetsGroup() {
   return (dispatch) => {
      dispatch({
         type: actionTypes.SHOW_GROUP_PRESETS_DATA,
         payload: null
      })
   }
}

export function SetParamsSelectedGroup(data) {
   return (dispatch) => {
      dispatch({
         type: actionTypes.SET_GROUP_PARAMETERS_DATA,
         payload: data
      })
   }
}

export function UpdateParamsSelectedGroup(data) {
   return async (dispatch, getstate) => {
      const result = await HttpJSON(`UpdateParameters`, 'POST', data);
      dispatch({ type: actionTypes.UPDATE_GROUP_PARAMETERS_DATA, payload: result });
   }
}

export function routeChanged(type) {
   return dispatch => {
      dispatch({ type: type });
   }
}
