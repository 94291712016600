import React, { Component } from 'react';

import http from '../../utils/Http';
import httpJSON from '../../utils/HttpJSON';

import red from "../../assets/images/red-icon.png";
import scale from "../../assets/images/scale.png";
import scales from "../../assets/images/scales.png";
import largeScale from "../../assets/images/large-scale.png";
import { ReactComponent as SyncIcon } from '../../assets/images/sync.svg';

const header = (startCol, data, onClick) => {
   return (
      <div className="status__grid-header">
         <div className="status__grid-header-fixed">סניף</div>
         {data.departments.slice(startCol).slice(0, 10).map(item => (
            <div
               key={item.id}
               className="status__grid-header-item">
               {item.name}
            </div>
         ))}
         <button className="status__grid-header-button" onClick={onClick}>>></button>
      </div>
   )
}

const branchDepartment = (key, scaleCount, scalesCount, color, tooltip, state) => {
   const image = (scalesCount > 1)
      ? scales
      : scale
   const scaleLabel = (scalesCount > 1)
      ? <p className="status__item-text">{scaleCount}/{scalesCount}</p>
      : null;
   const tooltipInfo = (tooltip.length > 0)
      ? <span className="tooltiptext"><pre>{tooltip}</pre></span>
      : null;
   const className = `status__grid-item status__item--${color} item-tooltip`;
   const imgSizeClassName = (scalesCount > 1) ? 'large' : 'small';

   if (scalesCount === 0 ||
      (color === 'green' && !state.showGreenStatus) ||
      (color === 'yellow' && !state.showYellowStatus) ||
      (color === 'red' && !state.showRedStatus)) {
      return (
         <div key={key}
            className='status__grid-item status__item--gray'></div>
      )
   }
   else {
      return (
         <div key={key} className={className}>
            {tooltipInfo}
            <img className={`status__item-image status__item-image--${imgSizeClassName}`} src={image}></img>
            {scaleLabel}
         </div>
      )
   }
}

class Status extends Component {
   state = {
      data: {},
      selectedBranch: {},
      selectedScale: {},
      showGreenStatus: true,
      showYellowStatus: true,
      showRedStatus: true,
      showReturnButton: false,
      showDetails: false,
      showRefreshButton: true,
      startCol: 0
   };

   async componentDidMount() {
      const branches = await http('GetScalesStatus?type=all', 'GET');
      this.setState({ data: branches })
   }

   handleFilterChange = (filter) => {
      let newFilters = Object.assign({}, this.state.filters);
      if (filter.filterTerm) {
         newFilters[filter.column.key] = filter;
      } else {
         delete newFilters[filter.column.key];
      }

      this.setState({ filters: newFilters });
   };

   handleRefresh = async () => {
      this.setState({ showRefreshButton: false });
      const branches = await http('GetScalesStatus?type=refresh', 'GET');
      this.setState({ data: branches })
   }

   showBranchDetails = (id) => {
      this.setState({ showDetails: true, selectedBranch: this.state.data.details.branches.find(x => x.id === id) });
   }

   selectScale = async function (branchId, scaleId) {
      const selectedScale = await http(`GetScaleStatus?branchId=${branchId}&scaleId=${scaleId}`, 'GET');
      console.log('selectScale', branchId, scaleId, selectedScale);
      this.setState({ selectedScale });
   }

   openVNC = (url) => {
      //window.open(`microsoft-edge:http://${url}:5800`);
   }

   statusClicked = source => {
      if (this.state.showDetails == false) {
         if (source === 'green')
            this.setState(prevState => ({ showGreenStatus: !prevState.showGreenStatus }));
         else if (source === 'yellow')
            this.setState(prevState => ({ showYellowStatus: !prevState.showYellowStatus }));
         else if (source === 'red')
            this.setState(prevState => ({ showRedStatus: !prevState.showRedStatus }));
      }
   }

   handlePageReturn = () => {
      this.setState({ showDetails: false });
   }

   handleUpdateStartCol = () => {
      if (this.state.startCol === 0)
         this.setState({ startCol: 10 });
      else
         this.setState({ startCol: 0 });
   }

   render() {
      const {
         data,
         startCol,
         showGreenStatus,
         showYellowStatus,
         showRedStatus,
         showDetails,
         showRefreshButton,
         selectedBranch,
         selectedScale } = this.state;

      if (Object.entries(data).length === 0 && data.constructor === Object)
         return null;

      console.log('status data', data);
      const table =
         <div className="status__grid-container">
            {data.branches.map((branch, index) => {
               let showDepartment = 0;
               branch.scales.forEach(obj => {
                  if ((showGreenStatus && obj.status === 'green') ||
                     (showYellowStatus && obj.status === 'yellow') ||
                     (showRedStatus && obj.status === 'red')) {
                     showDepartment++;
                  }
               });

               if (showDepartment) {
                  const departments = branch.scales.slice(startCol).slice(0, 10).map(scale => (
                     branchDepartment(branch.key + scale.key, scale.count, scale.total, scale.status, scale.tooltip, this.state)));
                  const len = 10 - departments.length;
                  for (let i = 0; i < len; i++)
                     departments.push(<div key={i + index} className='status__grid-item status__item--gray'></div>);
                  return (
                     <React.Fragment key={index}>
                        <div className="status__grid-header-fixed" onClick={() => this.showBranchDetails(branch.id)}>{branch.name}</div>
                        {departments}
                     </React.Fragment>
                  )
               }
               return null;
            })}
         </div>

      let scaleStatus = null;
      if (Object.entries(selectedScale).length !== 0) {
         scaleStatus =
            <div className="details__left">
               <div className="details__left-image-container">
                  <img className="details__left-image" src={largeScale} onClick={() => this.openVNC(selectedScale.url)} />
               </div>
               <div className="details__left-text-container">
                  <a href="#" className={`details__left-button status__button--${selectedScale.status}`}></a>
                  <p className="details__left-name">{`משקל ${selectedScale.id}`}</p>
                  <p className="details__left-text">
                     <span>&#9679; אתחול אחרון</span>
                     <span><strong>{selectedScale.reset}</strong></span>
                     <span>&#9679; כיבוי והדלקה אחרון</span>
                     <span><strong>{selectedScale.restart}</strong></span>
                     <span>&#9679; תקלת מדפסת</span>
                     <span><strong>{selectedScale.printerError}</strong></span>
                     <span>&#9679; עדכון קטלוג</span>
                     <span><strong>{selectedScale.catalogUpdated}</strong></span>
                     <span>&#9679; עדכון פריסטים</span>
                     <span><strong>{selectedScale.scalesUpdated}</strong></span>
                     <span>&#9679; עדכון פרמטרים</span>
                     <span><strong>{selectedScale.paramstUpdated}</strong></span>
                  </p>
               </div>
            </div>
      }

      let details = null;
      if (Object.entries(selectedBranch).length !== 0) {
         details =
            <div>
               <div className="details__name">{`סניף ${selectedBranch.name} | ${selectedBranch.id}`}</div>
               <div className="details__container">
                  <div className="details__right"></div>
                  <div className="details__center">
                     {selectedBranch.departments.map(department => {

                        const departments = department.scales.map(scale => (
                           <div className="row__item">
                              <a href="#" className={`row__item-button row__item-button--${scale.status}`} onClick={() => this.selectScale(selectedBranch.id, scale.id)}>{scale.id}</a>
                           </div>
                        ));

                        return (
                           <>
                              <div className="row__name">{department.name}</div>
                              {departments}
                              <div className="line"></div>
                           </>
                        )
                     })}
                  </div>
                  {/* {scaleStatus} */}
               </div>
            </div>
      }
      return (
         <section className="managment-system">
            <div className="status__header_wrapper">
               <div className="status__header">
                  <h4 className="status__buttons-header">סטאטוס</h4>
                  <a href="#" className={`status__button status__button--${showGreenStatus ? 'green' : 'gray'}`} onClick={() => this.statusClicked('green')}>תקין</a>
                  <a href="#" className={`status__button status__button--${showYellowStatus ? 'yellow' : 'gray'}`} onClick={() => this.statusClicked('yellow')}>לשים לב</a>
                  <a href="#" className={`status__button status__button--${showRedStatus ? 'red' : 'gray'}`} onClick={() => this.statusClicked('red')}>לא תקין</a>
                  {!showDetails &&
                     <h3 className="status__sum">{`| תקינים: ${data.working} | דורשים התייחסות: ${data.needAttention} |`}</h3>
                  }
                  {showDetails &&
                     <a href="#" className='return-button' onClick={this.handlePageReturn}>חזרה למסך קודם</a>}

               </div>
               {(!showDetails) &&
                  <a href="#" onClick={() => this.handleRefresh()}>
                     <SyncIcon title='רענון נתונים' width="50" height="40" />
                  </a>}

            </div>
            {!this.state.showDetails && header(startCol, data, this.handleUpdateStartCol)}
            {!this.state.showDetails && table}
            {this.state.showDetails && details}

         </section>
      );
   }
}

export default Status;
