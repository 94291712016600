import React from 'react'
import {PropTypes} from 'prop-types'
import InfoItem from './InfoItem.js'

const InfoBlock = (props) => {
  const texts = props.texts
  return (
    <div className="info">
      <div className="row">
        <div className="col-sm-4">
          <InfoItem
            currency="$"
            icon="sales"
            title={texts.Sales}
            value={props.dashboard.TotalSales.toLocaleString('en-US', { minimumSignificantDigits: 6})} />
        </div>
        <div className="col-sm-4">
          <InfoItem
            currency="%"
            icon="passed"
            title={texts.Passed}
            value={props.dashboard.TotalPaid} />
        </div>
        <div className="col-sm-4">
          <InfoItem
            currency="#"
            icon="transactions"
            title={texts.Transactions}
            value={props.dashboard.TotalTransactions.toLocaleString('en-US', { minimumSignificantDigits: 6})} />
        </div>
      </div>
    </div>
  )
}

InfoBlock.propTypes = {
  dashboard: PropTypes.object.isRequired
}

export default InfoBlock
