import some from 'lodash/some';

import Http from '../../../utils/Http';
import HttpJSON from '../../../utils/HttpJSON';
import { getLocal } from '../../../utils/LocalStorage';
import * as actionTypes from '../actionTypes';

export function saleItemsSet(data) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.SALE_ITEMS_SET,
      data
    }) 
  }
}

export function saleItemsSetFilter(query) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.SALE_ITEMS_SET_FILTER,
      query
    }) 
  }
}

export function saleItemsToggleCheckItem(id, isGroup) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.SALE_ITEMS_TOGGLE_CHECK_ITEM,
      id,
      isGroup
    })
  }
}

export function saleItemsToggleCheckAllItems() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.SALE_ITEMS_TOGGLE_CHECK_ALL_ITEMS
    })
  }
}

export function saleItemsToggleShowSelected() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.SALE_ITEMS_TOGGLE_SHOW_SELECTED
    })
  }
}

export function saleItemsUncheckAllItems() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.SALE_ITEMS_UNCHECK_ALL_ITEMS
    })
  }
}

