import React from 'react';
import { PropTypes } from 'prop-types'
import isEmpty from 'lodash/isEmpty'

export default class Footer extends React.Component {
  static propTypes = {
    name: PropTypes.string
  };

  render() {
    const { app } = this.props
    const texts = !isEmpty(app.Texts) && app.Texts.Footer[app.lang]
    return (
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-sm-push-6 text-right">
              <div className="update">
                <div className="update__icon icon-clock"></div>
                <p className="update__descr">{texts.LastUpdate} {app.lastUpdate}</p>
              </div>
            </div>
            <div className="col-sm-6 col-sm-pull-6">
              <p className="copyright">Bolet Industries (1984) LTD &#169; 2021 <span style={{ paddingLeft: "30px" }}>V2.10</span></p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
