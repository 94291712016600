import moment from 'moment';
import HttpJSON from '../../../utils/HttpJSON';
import { getLocal } from '../../../utils/LocalStorage';
import * as actionTypes from '../actionTypes';

function changeDateFormat(item, field) {
  var date = item[field]
  var arrDate = date.split('/')
  var arrTime = arrDate[2].split(' ')
  return item[field] = `${arrTime[0]}/${arrDate[1]}/${arrDate[0]} ${arrTime[1]}`
}

export function getSellerReportTrans(model) {
  return function (dispatch) {
    const data = {
      'Sellers': model.sellers,
      'criterionId': model.criterionId,
      'startDate': moment(model.dates.scalestartdate).format('YYYY-MM-DD'),
      'endDate': moment(model.dates.scaleenddate).format('YYYY-MM-DD')
    }

    dispatch({ type: actionTypes.REPORT_TRANS_REQUEST })
    HttpJSON(`GetSellerReportTrans/${getLocal('SessionKey')}`, 'POST', data)
      .then(
        response => {
          dispatch({
            type: actionTypes.REPORT_TRANS_GET_SELLER_REPORT_TRANS,
            payload: response
          })
        }
      )
      .catch(error => {
        console.error('Error loading GetSellerReportTrans:', error);
      });
  }
}

export function reportTransChangeSort(sort) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.REPORT_TRANS_TO_CHANGE_SORT,
      sort
    })
  }
}

export function reportTransSetFilter(query) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.REPORT_TRANS_SET_FILTER,
      query
    })
  }
}

export function reportTransToggleCheckItem(id) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.REPORT_TRANS_TOGGLE_CHECK_ITEM,
      id
    })
  }
}

export function reportTransToggleCheckAllItems() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.REPORT_TRANS_TOGGLE_CHECK_ALL_ITEMS
    })
  }
}

export function reportTransIsChecked(good) {
  return function (dispatch) {
    if (good) {
      dispatch({
        type: actionTypes.REPORT_TRANS_ERROR,
        errorMessage: ''
      })
    } else {
      dispatch({
        type: actionTypes.REPORT_TRANS_ERROR,
        errorMessage: 'CheckRow'
      })
    }
  }
}
