import Http from '../../../utils/Http';
import HttpJSON from '../../../utils/HttpJSON';
import { getLocal } from '../../../utils/LocalStorage';
import * as actionTypes from '../actionTypes';

export function getAdvancedDetailsSellerReport(data) {
   return function (dispatch) {
      dispatch({ type: actionTypes.REPORT_PAGE_REQUEST })

      Http(`GetAdvancedDetailsSellerReport/${getLocal('SessionKey')}`, 'GET', data)
         .then(
            response => {
               dispatch({
                  type: actionTypes.REPORT_PAGE_GET_ADVENCED_DETAILS_SELLER_REPORT,
                  payload: response
               })
            },
            () => {
               dispatch({
                  type: actionTypes.REPORT_PAGE_ERROR,
                  errorMessage: 'Timeout'
               })
            }
         )
   }
}

export function changeSort(sort) {
   return function (dispatch) {
      // console.log(sort)
      dispatch({
         type: actionTypes.REPORT_PAGE_CHANGE_SORT,
         sort
      })
   }
}

export function reportPageIsChecked(good) {
   return function (dispatch) {
      if (good) {
         dispatch({
            type: actionTypes.REPORT_PAGE_ERROR,
            errorMessage: ''
         })
      } else {
         dispatch({
            type: actionTypes.REPORT_PAGE_ERROR,
            errorMessage: 'CheckRow'
         })
      }
   }
}

export function reportPageSetFilter(query) {
   return function (dispatch) {
      dispatch({
         type: actionTypes.REPORT_PAGE_SET_FILTER,
         query
      })
   }
}

export function reportPageToggleCheckItem(id) {
   return function (dispatch) {
      if (!id || typeof (id) !== 'string') throw new Error('Problem with "id"')
      dispatch({
         type: actionTypes.REPORT_PAGE_TOGGLE_CHECK_ITEM,
         id
      })
   }
}

export function reportPageToggleCheckAllItems() {
   return function (dispatch) {
      dispatch({
         type: actionTypes.REPORT_PAGE_TOGGLE_CHECK_ALL_ITEMS
      })
   }
}

export function toggleSumBranches() {
   return function (dispatch) {
      dispatch({
         type: actionTypes.REPORT_PAGE_TOGGLE_SUM_BRANCHES
      })
   }
}

export function exportToPDF(data) {
   return function () {
      return HttpJSON('GetHebrewPdf', 'POST', data)
   }
}

export function exportToExcel(data) {
   return async (dispatch, getstate) => {
      const result = await HttpJSON('GetExcelFile', 'POST', data);
      dispatch({ type: actionTypes.REPORT_PAGE_EXPORT_EXCEL, payload: result });
   }
}
