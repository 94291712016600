import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
import errorHandler from './store/middleware/errorHandler'
import rootReducer from './store/reducers'
import App from './App'

import 'react-datepicker/dist/react-datepicker.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'pretty-checkbox/dist/pretty-checkbox.css'
import "react-table/react-table.css";
import './assets/scss/app.scss'
import './assets/images/bg1.png';

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(
    thunk)));

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker();
