import React, {Component} from 'react'
import {PropTypes} from 'prop-types'
import Select from 'react-select'
import {withFormsy} from 'formsy-react'

class FormSelect extends Component {
  changeValue(value) {
    this.props.setValue(value)
  }

  render() {
    // const error = this.props.isFormSubmitted() ? this.props.getErrorMessage() : null
    return (
      <div className="default-select">
        <Select
          labelKey={this.props.labelKey || 'name'}
          options={this.props.options}
          placeholder={this.props.placeholder}
          searchable={false}
          value={this.props.getValue()}
          valueKey={this.props.valueKey || 'id'}
          onChange={this.changeValue.bind(this)}
          disabled={this.props.disabled} />
        {/*<div className="text-danger">{error}</div>*/}
      </div>
    )
  }
}

FormSelect.propTypes = {
  labelKey: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  valueKey: PropTypes.string
}

export default withFormsy(FormSelect)
