import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';
import Griddle, { RowDefinition, ColumnDefinition, plugins } from 'griddle-react';
import { Route, Switch } from 'react-router-dom';

import * as actions from '../../store/actions/index';
import SortReportList from '../../layout/reportList/SortReportList';
import SearchInput from '../../components/SearchInput';
import WrapLink from '../../components/WrapLink';
import RemoveButton from '../../components/table/RemoveButton';
import RowEnhancer from '../../utils/RowEnhancer';

const rowDataSelector = (state, { griddleKey }) => {
  return state
    .get('data')
    .find(r => r.get('griddleKey') === griddleKey)
    .toJSON();
};

const enhancedWithRowData = connect((state, props) => {
  return {
    rowData: rowDataSelector(state, props),
  };
});

export class ReportList extends React.Component {
  componentDidMount() {
    this.props.actions.getReportList({ status: 'Sales' })
  }


  render() {
    const { app, reportList, actions, match } = this.props
    const texts = !isEmpty(app.Texts) && app.Texts.Vendors.ReportList[app.lang]
    const tableTexts = !isEmpty(app.Texts) && app.Texts.Table[app.lang]

    const sendToInnactive = (item) => actions.sendToInnactive(item);
    const customActiveComponent = (props) => <RemoveButton onClick={() => sendToInnactive(props.rowData)} />

    const styleConfig = {
      icons: {
        TableHeadingCell: {
          sortDescendingIcon: <small>(desc)</small>,
          sortAscendingIcon: <small>(asc)</small>,
        },
      },
      classNames: {
        Table: 'table',
        TableBody: 'table__row',
        TableHeading: 'table__row',
        TableHeadingCell: 'table__item',
        Row: 'table__row'
      },
      styles: {
        Filter: { fontSize: 18 },
      }
    }

    //console.log('ReportList render', this.props)
    return (
      <div className="vendors">
        <div className="report-list__wrapper">
          <div className="report-list">
            <SortReportList
              texts={texts}
              submit={actions.getReportList} />
            <SearchInput placeholder={texts.Search} onChange={actions.reportListSetFilter} />
            <div className="table__wrap">
              <Griddle
                styleConfig={styleConfig}
                data={reportList.results}
                plugins={[plugins.LocalPlugin, plugins.PositionPlugin({ tableHeight: 400 })]}
                pageProperties={{
                  currentPage: 1,
                  pageSize: 100
                }}
                components={{
                  Layout: ({ Table }) => <Table />
                }}
                noDataMessage={tableTexts.NoData} >
                <RowDefinition>
                  <ColumnDefinition
                    id='Name'
                    title={texts.ReportName}
                    cssClassName='table__item'
                    customComponent={enhancedWithRowData(({ value, griddleKey, rowData }) =>
                      <WrapLink
                        label={rowData.Name}
                        path={`/vendors/reportList/${rowData.Id}/${rowData.Name}`} />
                    )}
                  />
                  <ColumnDefinition
                    id='Id'
                    title={texts.Edit}
                    cssClassName='table__item'
                    customComponent={(props) => <WrapLink icon="edit" path={`/vendors/createReport?id=${props.value}`} />}
                  />
                  <ColumnDefinition
                    id=''
                    title=''
                    cssClassName='table__item'
                    customComponent={enhancedWithRowData(customActiveComponent)}
                  />
                </RowDefinition>
              </Griddle>
            </div>
          </div>
        </div>


      </div >
    )
  }
}

const mapStateToProps = state => ({
  app: state.App,
  reportList: state.ReportList,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

ReportList.propTypes = {
  reportList: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportList)
