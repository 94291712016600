import moment from 'moment'

function isValidDate(d) {
   let input = new Date(d)
   return !isNaN(input.getTime())
}

function cellTypesExcelReport(texts) {
   let cellObject = {}
   for (let item in texts) {
      cellObject[texts[item]] = 'String'
   }
   return cellObject
}

export function getDataListReport(data, texts, lang) {
   return data.map(item => {
      if (lang === 'he') {
         return {
            [texts.NotPaidSum]: +item.NotPaidSum.toFixed(2),
            [texts.NotPaidCount]: item.NotPaidCounter,
            [texts.PaidSum]: +item.PaidSum.toFixed(2),
            [texts.PaidCount]: item.PaidCounter,
            [texts.Name]: item.SellerFirstName,
            [texts.ID]: item.SellerId,
            [texts.Branch]: item.BranchNetCode
         }
      } else {
         return {
            [texts.Branch]: item.BranchNetCode,
            [texts.ID]: item.SellerId,
            [texts.Name]: item.SellerFirstName,
            [texts.PaidCount]: item.PaidCounter,
            [texts.PaidSum]: +item.PaidSum.toFixed(2),
            [texts.NotPaidCount]: item.NotPaidCounter,
            [texts.NotPaidSum]: +item.NotPaidSum.toFixed(2)
         }
      }
   })
}

export function getDataTrance(data, texts, lang) {
   console.log('getDataTrance', data)
   return data.map(item => {
      if (lang === 'he') {
         return {
            [texts.Trance]: item.TranNumber,
            [texts.Paid]: item.IsPaid ? texts.Yes : '',
            [texts.Total]: item.Amount,
            [texts.WeightUnits]: item.Quantity,
            [texts.ProductName]: item.ItemName,
            [texts.ProductCode]: item.ItemCode,
            [texts.VendorName]: item.SellerFirstName,
            [texts.TimeCashRegister]: isValidDate(item.TranDate) ? moment(item.TranDate).format('HH:mm') : '',
            [texts.DataCashRegister]: isValidDate(item.TranDate) ? moment(item.TranDate).format('DD/MM/YYYY') : '',
            [texts.TimeScale]: isValidDate(item.ScaleTranDate) ? moment(item.ScaleTranDate).format('HH:mm') : '',
            [texts.DataScale]: isValidDate(item.ScaleTranDate) ? moment(item.ScaleTranDate).format('DD/MM/YYYY') : ''
         }
      } else {
         return {
            [texts.DataScale]: isValidDate(item.ScaleTranDate) ? moment(item.ScaleTranDate).format('DD/MM/YYYY') : '',
            [texts.TimeScale]: isValidDate(item.ScaleTranDate) ? moment(item.ScaleTranDate).format('HH:mm') : '',
            [texts.DataCashRegister]: isValidDate(item.TranDate) ? moment(item.TranDate).format('DD/MM/YYYY') : '',
            [texts.TimeCashRegister]: isValidDate(item.TranDate) ? moment(item.TranDate).format('HH:mm') : '',
            [texts.VendorName]: item.SellerFirstName,
            [texts.ProductCode]: item.ItemCode,
            [texts.ProductName]: item.ItemName,
            [texts.WeightUnits]: item.Quantity,
            [texts.Total]: item.Amount,
            [texts.Paid]: item.IsPaid ? texts.Yes : '',
            [texts.Trance]: item.TranNumber
         }
      }
   })
}

function emitXmlHeader(cellTypesExcel) {
   let headerRow = '<ss:Row>\n'
   for (let colName in cellTypesExcel) {
      headerRow += '  <ss:Cell>\n'
      headerRow += '    <ss:Data ss:Type="String">'
      headerRow += colName + '</ss:Data>\n'
      headerRow += '  </ss:Cell>\n'
   }
   headerRow += '</ss:Row>\n'
   return '<?xml version="1.0"?>\n' +
      '<ss:Workbook xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">\n' +
      '<ss:Worksheet ss:Name="Sheet1">\n' +
      '<ss:Table>\n\n' + headerRow
}

function emitXmlFooter() {
   return '\n</ss:Table>\n' +
      '</ss:Worksheet>\n' +
      '</ss:Workbook>\n'
}

export function convertToExcel(jsonObject, texts) {
   let row
   let col
   let xml
   let dataCell = cellTypesExcelReport(texts)
   let data = typeof jsonObject !== 'object' ? JSON.parse(jsonObject) : jsonObject
   xml = emitXmlHeader(dataCell)
   for (row = 0; row < data.length; row++) {
      xml += '<ss:Row>\n'
      for (col in data[row]) {
         xml += '  <ss:Cell>\n'
         xml += '    <ss:Data ss:Type="' + dataCell[col] + '">'
         xml += data[row][col] + '</ss:Data>\n'
         xml += '  </ss:Cell>\n'
      }
      xml += '</ss:Row>\n'
   }
   xml += emitXmlFooter()
   return xml
}
