import sortBy from 'lodash/sortBy'

// function toLowSort(obj, sort) { 
//   if (typeof(obj[sort]) == 'string') {
//     return obj[sort].toLowerCase()
//   } else {
//     return obj[sort]
//   }
//   
// }

export default function customSortFunction({state, action, dataName}) {
  let currentState = {
    ...state,
    sort: action.sort === state.reverse ? '' : action.sort,
    sortAscending: ((action.sort !== state.reverse && !state.sortAscending) || (action.sort !== state.sort)) ? action.sort : ''
    // reverse: action.sort == state.sortAscending && action.sort
  }
  
  // if (state.reverse) {
  //   return {
  //     ...currentState,
  //     results: state.saved,
  //     [dataName]:state.saved
  //   }
  // }
  
  if (action.sort === state.sortAscending) {
    return {
      ...currentState,
      results: sortBy(state.results, [{IsGroup: true}]).reverse(),
      [dataName]: sortBy(state[dataName], [{IsGroup: true}]).reverse()
    }
  }
  
  return {
    ...currentState,
    results: sortBy(state.results, [{IsGroup: true}]),
    [dataName]: sortBy(state[dataName], [{IsGroup: true}])
  }
}
