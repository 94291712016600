import React from 'react'
import Parent from './Parent'

export default class Tree extends React.Component {
  render() {
    return (
      <div className="tree">
        {this.props.data.map((item, i) => {
          return <Parent 
            key={i} 
            item={item}
            setBranchList={this.props.setBranchList}
            setBranchDeptList={this.props.setBranchDeptList} />
        })}
      </div>
    )
  }
}
