
import React from 'react';

import AddImage from '../../assets/images/plus.png';

const ViewSaleList = ({ currentIndex, data, onSelect, onDelete, onInsert, onEdit }) => {

   const id = data[currentIndex] !== undefined ? data[currentIndex].Id : -1;
   const items = data.filter(item => item.Display.type !== 'playlist' && item.Id !== 0).map((item, index) => {
      return (
         <div
            key={index}
            className={item.Id === id ? 'list__item selected' : 'list__item'}
            onClick={() => onSelect(item.Id)}>
            <span>{item.Name}</span>
            <div className="list__buttons">
               <button key={index + 'edit'} className="bolet-icon-edit" id={item.id} onClick={() => onEdit(item.Id)}></button>
               <button key={index + 'trash'} className="bolet-icon-trash" id={item.id} onClick={() => onDelete(item.Id)}></button>
            </div>
         </div>
      )
   })

   return (
      <section>
         <div className="col-md-5 col-xs-12 sales">
            <h2>מבצעים</h2>
            <button title="" className="btn-new" onClick={onInsert}>
               <img alt="" src={AddImage} />
            </button>
            <div className="list__items">
               {items}
            </div>
            {/* <button id="sale-list-btn-new"><p id="sale-list-btn-text">צור מבצע חדש</p></button> */}
         </div>
      </section>
   )
}

export default ViewSaleList;

/* https://www.npmjs.com/package/react-treebeard */