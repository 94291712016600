import $ from 'jquery/dist/jquery'

const port = process.env.NODE_ENV === 'development' ? '80' : window.location.port;
const apiUrl = `${window.location.protocol}//${window.location.hostname}:${port}/service/service.svc`;

const Http = (url, method, data) => {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: `${apiUrl}/${url}`,
      method: method,
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: response => resolve(response),
      error: (error) => reject(error)
    })
  })
}

export default Http
