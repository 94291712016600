import * as actionTypes from '../../actions/actionTypes';

import customFilterFunction from '../../../utils/customFilterFunction'
import customSortFunction from '../../../utils/customSortFunction'
import differenceBy from 'lodash/differenceBy'
import filter from 'lodash/filter'
import merge from 'lodash/merge'
import { getLocal, setLocal } from '../../../utils/LocalStorage'

const dataName = 'data'
const initialState = {
  [dataName]: [],
  fetching: false,
  inactive: getLocal('inactive') || [],
  query: '',
  results: [],
  // reverse: false,
  // saved: [],
  sort: '',
  sortAscending: '',
  status: 'Sales'
}

const filtered = (status, arr, inactive) => {
  if (status === 'Sales' || status === 'Custom') {
    return differenceBy(arr, inactive, 'Id').reverse()
  } else if (status === 'Inactive') {
    return merge(arr, inactive).reverse()
  } else {
    return arr.reverse()
  }
}

export default function ReportList(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REPORT_LIST_REQUEST:
      return { ...state, fetching: true }
    case actionTypes.REPORT_LIST_GET_SELLER_REPORT_CRITERION_LIST:
      //console.log('REPORT_LIST_GET_SELLER_REPORT_CRITERION_LIST', action.payload);
      return {
        ...state,
        [dataName]: filtered(action.status, action.payload, state.inactive),
        fetching: false,
        results: filtered(action.status, action.payload, state.inactive),
        status: action.status
      }
    case actionTypes.REPORT_LIST_SET_FILTER: return customFilterFunction({ state, action, dataName })
    case actionTypes.REPORT_LIST_TO_CHANGE_SORT: return customSortFunction({ state, action, dataName })
    case actionTypes.REPORT_LIST_SEND_TO_INNACTIVE: {
      const inactive = [...state.inactive, action.item]
      setLocal('inactive', inactive.reverse())
      return {
        ...state,
        inactive: state.status === 'Inactive'
          ? filter(state.inactive, item => item.Id !== action.item.Id)
          : inactive,
        [dataName]: state[dataName].filter(item => item.Id !== action.item.Id && item),
        results: state.results.filter(item => item.Id !== action.item.Id && item)
      }
    }



    default:
      return state
  }
}
