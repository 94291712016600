
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';

import Http from '../../utils/Http';
import * as actions from '../../store/actions/index';
import MovieImage from '../../assets/images/movie.jpg';
import AdvSaleNamePopup from '../../containers/Advertising/AdvSaleNamePopup';

class Checkbox extends Component {
   state = {
      isChecked: false,
   }

   toggleCheckboxChange = () => {
      const { handleCheckboxChange, label } = this.props;

      this.setState(({ isChecked }) => (
         {
            isChecked: !isChecked,
         }
      ));

      handleCheckboxChange(label);
   }

   render() {
      const { label } = this.props;
      const { isChecked } = this.state;

      return (
         <div className="checkbox">
            <input
               type="checkbox"
               id={label}
               value={label}
               checked={isChecked}
               onChange={this.toggleCheckboxChange}
            />
            <label htmlFor={label} />
         </div>
      );
   }
}

function ItemsView(props) {

   //console.log('ItemsView',props)
   let items;
   if (props.itemsType === 'image' && props.imageFilter === '!logo')
      items = props.items.filter(item => !item.url.substring(item.url.lastIndexOf('/') + 1).includes('logo'));
   else if (props.itemsType === 'image' && props.imageFilter === 'logo')
      items = props.items.filter(item => item.url.substring(item.url.lastIndexOf('/') + 1).includes('logo'));
   else items = props.items;

   var result = items.map((item, index) => (
      <li key={index}>
         <div className="image-wrapper">
            {(() => {
               if (props.singleSelection && item.type === 'image') {
                  var filename = item.url.substring(item.url.lastIndexOf('/') + 1);// REMOVE FILE EXTENSION .split('.').slice(0, -1).join('.');
                  if (props.imageFilter === '!logo' && filename.includes('logo'))
                     return null;
                  else if (props.imageFilter === 'logo' && !filename.includes('logo'))
                     return null;
                  return (
                     <>
                        <button className='gallery-image-box' onClick={e => props.onSubmit(item, e)}>
                           <img src={item.url} alt="" />
                        </button>
                        <div className="gallery-image-details">
                           <label>{filename}</label>
                           <a href="#">
                              <i className="bolet-icon-trash" id={item.url} onClick={props.DeleteImage} />
                           </a>
                        </div>
                     </>
                  )
               }
               else if (props.singleSelection && item.type === 'movie') {
                  return (
                     <>
                        <button onClick={e => props.onSubmit(item, e)}>
                           <img src={MovieImage} alt="" />
                        </button>
                        <div className="gallery-image-details">
                           <label>{item.title}</label>
                           <a href="#">
                              <i className="bolet-icon-trash" id={item.url} onClick={props.DeleteImage} />
                           </a>
                        </div>
                     </>
                  )
               }
               else {
                  if (item.type === 'image') {
                     var filename = item.url.substring(item.url.lastIndexOf('/') + 1);
                     console.log('ItemsView', item, filename)
                     return (
                        <div>
                           <img className='gallery-image-box' src={item.url} alt="" />
                           <div className="image-details">
                              {props.createCheckbox(item.url)}
                              <a href="#">
                                 <i className="bolet-icon-trash" id={item.url} onClick={props.DeleteImage} />
                              </a>
                           </div>
                        </div>
                     )
                  }
                  else {
                     return (
                        <div>
                           <label className='movie-label'>
                              <img src={MovieImage} alt="" />
                              {item.title}
                           </label>
                           <div className="image-details">
                              {props.createCheckbox(item.url)}
                              <a href="#">
                                 <i className="bolet-icon-trash" id={item.url} onClick={props.DeleteImage} />
                              </a>
                           </div>
                        </div>
                     )
                  }
               }
            })()}
         </div>

      </li>
   ))

   return (
      <div className="gallery-images">
         <ul>
            {result}
         </ul>
      </div>
   )
}

class ViewMediaGallery extends Component {

   state = {
      itemsInView: [],
      itemsType: 'image',
      showMovieNamePopup: false,
      media: [],
      selectedFile: null,
      progressBarValue: '0%',
      showImageProgressBar: false,
      showMovieProgressBar: false
   };

   async componentDidMount() {
      this.selectedCheckboxes = new Set();
      await this.props.actions.GetMedia();
      this.setSelectionType('image');
   }

   // static getDerivedStateFromProps(nextProps, prevState) {
   //    if (nextProps.media !== prevState.media)
   //       return { itemsInView: nextProps.media.filter(x => x.type == prevState.itemsType && !x.url.toLowerCase().includes('logo')) };
   //    else return null;
   // }

   toggleCheckbox = label => {
      if (this.selectedCheckboxes.has(label)) {
         this.selectedCheckboxes.delete(label);
      } else {
         this.selectedCheckboxes.add(label);
      }
   }

   createCheckbox = label => (
      <Checkbox
         label={label}
         handleCheckboxChange={this.toggleCheckbox}
         key={label}
      />
   )

   DeleteImage = (e) => {
      const url = e.target.id;
      this.props.actions.DeleteMedia(url);
      const items = this.state.itemsInView.filter(x => x.url !== url);
      this.setState({ itemsInView: items });
   }

   selectAllImages = () => {
      this.state.itemsInView.forEach((image) => {
         document.getElementById(image.url).checked = true;
      })
   }

   deselectAllImages = () => {
      this.state.itemsInView.forEach((image) => {
         document.getElementById(image.url).checked = false;
      })
   }

   updateSelectionType = async (type) => {
      const { actions } = this.props;

      actions.UpdateMedia(type);
      this.setSelectionType(type);
   };

   setSelectionType = type => {
      const { media } = this.props;
      if (type === 'image')
         this.setState({
            itemsInView: media.filter(x => x.type === 'image'),
            itemsType: 'image'
         });
      else
         this.setState({
            itemsInView: media.filter(x => x.type === 'movie'),
            itemsType: 'movie'
         });
   };

   isNameValid(file) {
      var regex = /^[a-zA-Z.-_]{2,30}$/; // TODO: if file.name have '-' then fail

      if (regex.test(file.name))
         return true;
      else
         return false;
   }

   onload2promise(obj) {
      return new Promise(resolve => obj.onload = () => resolve(obj));
   }

   async isImageSizeValid(file, minImageWidth, minImageHeight) {

      var image = new Image();
      image.src = window.URL.createObjectURL(file);
      const obj = await this.onload2promise(image);

      // Check if image is bad/invalid
      if (obj.width + obj.height === 0) {
         return false;
      }

      // Check the image resolution
      if (obj.width >= minImageWidth && obj.height >= minImageHeight) {
         return true;
      }

      else {
         return false;
      }
   }

   imageFileSelectHandler = async (event) => {
      const selectedFile = event.target.files[0]

      const remoteFileExist = await Http(`MediaFileExist?fileName=${selectedFile.name}`, 'GET');
      if (remoteFileExist) {
         alert('שם קובץ קיים בשרת,בחר שם חדש');
         return;
      }

      if (!this.isNameValid(selectedFile) || selectedFile.name.toLowerCase().startsWith('logo')) {
         alert('שם קובץ לא תקין (אסור רווחים ותווי מקף)');
         return;
      }
      const imageSizeValid = await this.isImageSizeValid(selectedFile,
         this.props.minImageWidth === undefined ? 1024 : this.props.minImageWidth,
         this.props.minImageHeight === undefined ? 768 : this.props.minImageHeight);
      if (!imageSizeValid) {
         alert('רזולוציה נמוכה מהנדרש');
         return;
      }

      this.setState({ showImageProgressBar: true });

      const fd = new FormData();
      fd.append('image', selectedFile, selectedFile.name)
      let res = await axios.post(this.props.apiUrl + '/UploadMediaFile', fd, {
         onUploadProgress: ProgressEvent => {
            this.setState({ progressBarValue: Math.round(ProgressEvent.loaded / ProgressEvent.total * 100) + '%' });
         }
      });
      await this.props.actions.GetMedia();
      this.updateSelectionType(this.state.itemsType);
      this.setState({ showImageProgressBar: false, progressBarValue: '0%' });

   }

   movieFileSelectHandler = async (event) => {
      const selectedFile = event.target.files[0]

      const remoteFileExist = await Http(`MediaFileExist?fileName=${selectedFile.name}`, 'GET');
      if (remoteFileExist) {
         alert('שם קובץ קיים בשרת,בחר שם חדש');
         return;
      }

      if (!this.isNameValid(selectedFile)) {
         alert('שם קובץ לא תקין (אסור רווחים ותווי מקף)');
         return;
      }

      // Show video 'name' popup
      this.setState({ showMovieNamePopup: true, selectedFile: selectedFile });
   }

   newMovieClosedHandler = () => {
      this.setState({ showMovieNamePopup: false });
   }

   newMovieAcceptedHandler = async (fields, e) => {
      const selectedFile = this.state.selectedFile;

      this.setState({ showMovieProgressBar: true });

      const fd = new FormData();
      fd.append('image', selectedFile, selectedFile.name + ',' + fields.name)
      let res = await axios.post(this.props.apiUrl + '/UploadMediaFile', fd, {
         onUploadProgress: ProgressEvent => {
            this.setState({ progressBarValue: Math.round(ProgressEvent.loaded / ProgressEvent.total * 100) + '%' });
         }
      });
      await this.props.actions.GetMedia();
      this.updateSelectionType(this.state.itemsType);
      this.setState({ showMovieNamePopup: false, showMovieProgressBar: false, progressBarValue: '0%' });
   }

   render() {

      const { visible, singleSelection, onSubmit, onClose, onlyImages, imageFilter, hideAddingImages } = this.props;
      const {
         itemsInView,
         itemsType,
         progressBarValue,
         showImageProgressBar,
         showMovieProgressBar } = this.state;

      if (!visible)
         return null;

      //console.log('render gallery', itemsInView);
      return (
         <div className="popup images-gallery-uploader shown-popup">
            <input
               type="file"
               accept="image/png, image/jpeg"
               style={{ display: 'none' }}
               value=""
               onChange={this.imageFileSelectHandler}
               ref={imageFileInput => (this.imageFileInput = imageFileInput)} />
            <input
               type="file"
               accept="video/mp4"
               style={{ display: 'none' }}
               value=""
               onChange={this.movieFileSelectHandler}
               ref={movieFileInput => (this.movieFileInput = movieFileInput)} />

            <AdvSaleNamePopup
               visible={this.state.showMovieNamePopup}
               type='new-movie'
               onClose={this.newMovieClosedHandler}
               onAccept={this.newMovieAcceptedHandler}
            />
            <div className="pop-up-wrapper">
               <h2>גלריית מדיה</h2>
               <div className="row">
                  <div className="col-sm-2">
                     <nav className="popup-navigation">
                        <ul>
                           {!hideAddingImages &&
                              <li>
                                 <a href="#" onClick={() => this.imageFileInput.click()}>הוסף תמונה</a>
                                 {showImageProgressBar &&
                                    <div className="progress">
                                       <div className="progress-bar progress-bar-info" role="progressbar" style={{ width: progressBarValue }} >
                                          {progressBarValue}
                                       </div>
                                    </div>
                                 }
                              </li>
                           }
                           {!onlyImages &&
                              <>
                                 <li>
                                    <a href="#" onClick={() => this.movieFileInput.click()}>הוסף סרטון</a>
                                    {showMovieProgressBar &&
                                       <div className="progress">
                                          <div className="progress-bar progress-bar-info" role="progressbar" style={{ width: progressBarValue }} >
                                             {progressBarValue}
                                          </div>
                                       </div>
                                    }
                                 </li>
                                 <li className={itemsType === 'image' ? "selected" : ""}>
                                    <a href="#" onClick={e => this.updateSelectionType('image')}>תמונות</a>
                                 </li>
                                 <li className={itemsType === 'movie' ? "selected" : ""}>
                                    <a href="#" onClick={e => this.updateSelectionType('movie')}>סרטונים</a>
                                 </li>
                              </>
                           }
                        </ul>
                     </nav>
                  </div>
                  <div className="col-sm-10">
                     <div className="images-tab">
                        <div className="galley-header">
                           <div className="commands">
                              <ul>
                                 <li>
                                    {!singleSelection && <a href="#" onClick={this.selectAllImages}>בחר הכל</a>}
                                 </li>
                                 <li>
                                    {!singleSelection && <a href="#" onClick={this.deselectAllImages}>נקה בחירה</a>}
                                 </li>
                              </ul>
                              <div className="btn-vertical-group">
                                 {!singleSelection && <button className="green-btn" onClick={(e) => onSubmit(this.selectedCheckboxes, e)}>הוספה</button>}
                                 <button className="grey-btn cancel-popup" onClick={onClose}>סגור</button>
                              </div>
                           </div>
                        </div>
                        <ItemsView
                           items={itemsInView}
                           imageFilter={imageFilter}
                           itemsType={itemsType}
                           singleSelection={singleSelection}
                           onSubmit={onSubmit}
                           DeleteImage={this.DeleteImage}
                           createCheckbox={this.createCheckbox}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}

ViewMediaGallery.propTypes = {
   visible: PropTypes.bool,
   onClose: PropTypes.func,
   onSubmit: PropTypes.func
}

ViewMediaGallery.defaultProps = {
   visible: false
}

function mapStateToProps(state) {
   return {
      selectedGroupId: state.App.selectedGroupId,
      selectedPromo: state.App.selectedPromo,
      apiUrl: state.App.apiUrl,
      media: state.App.Media
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: bindActionCreators(actions, dispatch)
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewMediaGallery);
