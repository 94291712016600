
/** @jsx jsx */

import React, { Component } from 'react'
import { jsx, css } from '@emotion/core';
import produce from "immer";

import { ReactComponent as OptionIcon } from '../../assets/images/details.svg';

import classes from './OptionsPopup.module.scss';

export default class OptionsPopup extends Component {

   state = {
      fields: {},
      errorStr: '',
      values: [],
   }

   componentDidMount() {
      this.setState({ values: this.props.values });
   }
   handleKeyPressed = (count, e) => {
      if (e.key === 'Enter' && Object.keys(this.state.fields).length === count) {
         this.props.onAccept(this.state.fields, e);
      }
   }

   handleChange = (field, e) => {
      const values = this.state.values.filter(x => x.Key !== field);
      values.push({ Key: field, Value: e.target.value });
      this.setState({ values });
   }

   Parameters = (parameters, onChange, onKeyPressed) => {

      if (parameters === undefined)
         return null;

      return (
         parameters.map((param, index) => (() => {

            if (param.type === 'text') {
               return (
                  <div key={'text' + param.field} className="row">
                     <div className="form-group">
                        <label htmlFor={param.field}>{param.title}</label>
                        <input
                           type="text"
                           autoFocus={param.field === 1 ? true : false}
                           className="form-control"
                           name={param.field}
                           placeholder={param.info}
                           id={param.field}
                           value={param.value}
                           onChange={(e) => onChange(param.name, e)}
                           onKeyPress={(e) => onKeyPressed(parameters.length, e)}
                        />
                     </div>
                  </div>
               )
            }
            else if (param.type === 'combo') {
               return (
                  <div key={'combo' + param.field} className="row">
                     <div className="form-group">
                        <label htmlFor={param.field}>{param.title}</label>
                        <select
                           name={param.field}
                           id={param.field}
                           className="form-control"
                           onChange={(e) => onChange(param.name, e)}
                        >
                           {param.options.map((option, index) =>
                              <option key={option.key} value={option.value}>{option.title}</option>
                           )}
                        </select>
                     </div>
                  </div>
               )
            }
            else return null;
         })())
      )
   }

   render() {
      const { options, onClose, onAccept } = this.props;

      const parameters = [];
      options.forEach(option => {
         //console.log('OptionsPopup options', this.state.values);
         if (this.state.values != null) {
            const value = this.state.values.find(value => value.Key === option.fieldName);
            parameters.push({ field: option.id, type: "text", name: option.fieldName, title: option.title, info: "", value: value ? value.Value : '' });
         }
      });

      const data = {
         header: 'עריכת פרמטרים לפריסט',
         parameters: parameters
      };

      return (
         <div className="advertising-popup popup shown-popup" css={css`direction:rtl;`} >
            <div className={classes.wrapper}>
               <div className={classes.image}>
                  <OptionIcon width="80px" height="80px" />
               </div>
               <div className={classes.text}>
                  <h3>{data.header}</h3>
               </div>
               <div className={classes.fields}>
                  <form action="">
                     {this.Parameters(data.parameters, this.handleChange, this.handleKeyPressed)}
                     <div className={classes.error}>
                        <label >{this.state.errorStr}</label>
                     </div>
                  </form>
               </div>
               <div className={classes.buttons}>
                  {this.state.errorStr === '' &&
                     <button className="green-btn" type="submit" onClick={(e) => onAccept(this.state.values, e)} >
                        שמור
                     </button>}
                  <button className="grey-btn" onClick={onClose} >
                     ביטול
                  </button>
               </div>
            </div>
         </div>
      )
   }
}
