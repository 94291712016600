import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withFormsy } from 'formsy-react';

class FormInput extends Component {
  changeValue(e) {
    this.props.setValue(e.currentTarget.value ? e.currentTarget.value : undefined)
  }

  render() {
    const error = this.props.isFormSubmitted() ? this.props.getErrorMessage() : null
    const {disabled, icon, label, labelClassName, name, placeholder, type} = this.props
    return (
      <div className="form__element">
        {label && (
          <label 
            className={`form__label ${labelClassName}`} 
            htmlFor={name}>
            {label}:
          </label>
        )}
        
        <div className="form__input-wrap">
          <input 
            autoComplete="off"
            className={`form__input ${error ? 'form__input_error' : '' }`}
            disabled={disabled}
            id={label}
            onChange={this.changeValue.bind(this)}
            placeholder={placeholder}
            type={type || 'text'}
            value={this.props.getValue() || ''} />
          <i className={`input__icon icon-${icon}`}></i>
        </div>
        <div className="text-danger">{error}</div>
      </div>
    )
  }
}

FormInput.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string
}

export default withFormsy(FormInput)
