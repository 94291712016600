import * as actionTypes from '../../actions/actionTypes';

import customFilterFunction from '../../../utils/customFilterFunction'
import toggleCheckItem from '../../../utils/toggleCheckItem'
import toggleCheckAllItems from '../../../utils/toggleCheckAllItems'
import customSortFunction from '../../../utils/customSortFunction'
import map from 'lodash/map'

const dataName = 'data'

const initialState = {
  [dataName]: [],
  results: [],
  // saved: [],
  allSelected: false,
  fetching: false,
  query: '',
  // reverse: false,
  sort: '',
  sortAscending: '',
  errorMessage: ''
}

export default function ReportTrans(state = initialState, action) {
  switch(action.type) {
    case actionTypes.REPORT_TRANS_REQUEST:
      return {...state, fetching: true, errorMessage: '' }
    case actionTypes.REPORT_TRANS_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage
      }
    case actionTypes.REPORT_TRANS_GET_SELLER_REPORT_TRANS:
      return {
        ...state,
        [dataName]: map(action.payload, item => ({...item, IsChecked: false})),
        fetching: false,
        results: map(action.payload, item => ({...item, IsChecked: false})),
        errorMessage: ''
      }
    case actionTypes.REPORT_TRANS_TO_CHANGE_SORT: return customSortFunction({state, action, dataName})
    case actionTypes.REPORT_TRANS_SET_FILTER:  return customFilterFunction({state, action, dataName})
    case actionTypes.REPORT_TRANS_TOGGLE_CHECK_ITEM: return toggleCheckItem(state, dataName, 'TranNumber', action.id)
    case actionTypes.REPORT_TRANS_TOGGLE_CHECK_ALL_ITEMS: return toggleCheckAllItems(state, dataName)

    default:
      return state
  }
}
