import React from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as DashboardActions from '../store/actions/Dashboard'
import InfoBlock from '../layout/dashboard/InfoBlock'
import SortDashboard from '../layout/dashboard/SortDashboard'
import DeptSales from '../layout/dashboard/DeptSales'
import TopSellers from '../layout/dashboard/TopSellers'
import HourlyTrans from '../layout/dashboard/HourlyTrans'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import CustomerLogo from '../assets/images/customer-logo.jpg'

export class Dashboard extends React.Component {
   componentDidMount() {
      this.props.dashboardActions.getDashboardDetails({
         duration: '3 Month',
         branches: this.props.dashboard.branches
      })
   }


   render() {
      if (!this.props.showDashboard) {
         return (
            <div className="dashboard">
               {/* <center><img src={CustomerLogo} alt="" /></center> */}
               <center><img src='customer-logo.jpg' alt="" /></center>
            </div>
         )
      }

      const { app, dashboard, dashboardActions } = this.props
      const texts = !isEmpty(app.Texts) && app.Texts.Dashboard[app.lang]

      if (!app.activeModules.includes('vendors')) {
         return (
            <div className="dashboard">
            </div>
         )
      }
      return (
         <div className="dashboard">
            <SortDashboard
               branch={dashboard.branches}
               texts={pick(texts, ['OneYear', 'SixMonths', 'ThreeMonths', 'OneWeek', 'OneDay', 'PleaseChooseBranch', 'All', 'SelectPeriod'])}
               branches={dashboard.Branches}
               submit={dashboardActions.getDashboardDetails} />
            <InfoBlock
               dashboard={dashboard}
               texts={pick(texts, ['Sales', 'Passed', 'Transactions'])} />
            <HourlyTrans
               text={texts.HourlyTrans}
               data={dashboard.BranchData}
               currentBranch={this.props.dashboard.branches} />
            <div className="row category__graph">
               <div className="col-sm-6 graph__wrap">
                  <DeptSales
                     data={dashboard.DeptData}
                     text={texts.DeptSales} />
               </div>
               <div className="col-sm-6 griddle__wrap">
                  <TopSellers
                     data={dashboard.SellerData}
                     texts={pick(texts, ['TopSellers', 'Name', 'Branch', 'Income'])} />
               </div>
            </div>
         </div>
      )
   }
}

const mapStateToProps = state => {
   return {
      dashboard: state.Dashboard,
      app: state.App,
      showDashboard: state.App.Configuration.showDashboard
   }
}

const mapDispatchToProps = dispatch => ({
   dashboardActions: bindActionCreators(DashboardActions, dispatch)
})

Dashboard.propTypes = {
   dashboard: PropTypes.object.isRequired,
   dashboardActions: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
