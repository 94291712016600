
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import isEmpty from 'lodash/isEmpty';

import * as actions from '../store/actions/index';

import FormInput from '../components/form/FormInput';

export class SignIn extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  submit(model) {
    this.props.actions.systemLogIn(model)
  }

  render() {
    const { app } = this.props;
    const texts = !isEmpty(app.Texts) && app.Texts.SignIn[app.lang];
    const errors = !isEmpty(app.Texts) && app.Texts.Errors[app.lang];

    return (
      <div className="sign-in">
        <Formsy
          className="form form__login"
          autoComplete="off"
          onValidSubmit={this.submit.bind(this)}>
          <h2 className="form__title">{texts.signIn}</h2>
          <div className="form__body">
            <FormInput
              icon="user"
              label={texts.userName}
              name="user"
              placeholder="Please enter user name"
              validations="isExisty"
              validationError={errors.Required} />
            <FormInput
              icon="lock"
              label={texts.password}
              name="password"
              placeholder="Enter your password"
              type="password"
              validations="isExisty"
              validationError={errors.Required} />
            <br />
            <div className="form-group">
              <button className="btn btn-primary form__button">{texts.signIn}</button>
            </div>
            <div className="text-danger text-center">{errors[app.errorMessage]}</div>
          </div>
        </Formsy>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    app: state.App,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
