import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import pick from 'lodash/pick'

import * as actions from '../../store/actions/index';

import classes from './Layout.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ModalWrapper from '../../components/ModalWrapper';
import SideDrawer from '../../containers/SideDrawer';

//import Toolbar from '../../components/Navigation/Toolbar/Toolbar';

class Layout extends Component {
    state = {
        showSideDrawer: false
    }

    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false });
    }

    sideDrawerToggleHandler = (name) => {
        this.props.actions.toggleSidebarView(name);
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        });
    }

    render() {
        const { app, actions } = this.props;
        const content = app.isLogined
            ? <SideDrawer
                app={app}
                actions={actions}
                Texts={app.Texts}
                onToggle={this.sideDrawerToggleHandler}
                children={this.props.children}
            />
            : this.props.children

        return (
            <div className={`wrap app__language_${app.lang}`}>
                <Header
                    lang={app.lang}
                    texts={app.Texts.Languages}
                    helpTexts={app.Texts.Help}
                    changeLang={actions.changeLang}
                    openModal={actions.openModal}
                />
                <main className="content clearfix">
                    {content}
                </main>
                <Footer
                    app={pick(app, ['Texts', 'lang', 'lastUpdate'])} />
                <ModalWrapper
                    modal={app.modal}
                    app={app}
                    actions={actions} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        app: state.App
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);