import React  from 'react'
import { PropTypes } from 'prop-types'
import Icon from '../utils/Icon'

class Collapse extends React.Component {
  render () {
    const {children, icon, label, name, open} = this.props
    const onToggle = () => this.props.onToggle(name)
    
    let iconClass = '', iconName = ''
    if (icon.includes('icon')) 
       iconClass = icon
    else {
       iconName = icon
       iconClass = 'menu__icon'
    }
    return (
      <li className="menu__item" onClick={onToggle}>
        <div className={`dropdown icon-arrow-${open ? 'down' : 'right'}`}>
          <div className="toggle">
            <i className={iconClass} ><Icon icon={iconName} /></i>
            <span>{label}</span>
          </div>
          {open && children}
        </div>
      </li>
    )
  }
}

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
}

export default Collapse
