import React from 'react'
import Checkbox from '../../components/Checkbox'

export default function ReportPageFoot(props) {
  return [
    {
      className: 'text-center',
      component: <Checkbox checked={props.sumBranches} name="summary" onChange={props.onChange} />,
      input: true,
      label: 'empty'
    },
    {label: props.texts.SumBranches, className: 'summary__check'},
    {label: '', className: ''},
    {label: props.texts.Summary, className: 'summary__title'},
    {label: props.TotalPaidCounter, className: 'summary__item'},
    {label: props.TotalPaidSum.toLocaleString('en-US', { minimumFractionDigits: 2}), className: 'summary__item'},
    {label: props.TotalNotPaidCounter, className: 'summary__item'},
    {label: props.TotalNotPaidSum.toLocaleString('en-US', { minimumFractionDigits: 2}), className: 'summary__item'}
  ]
}
