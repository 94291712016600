import React from 'react'
import { PropTypes } from 'prop-types'
import Formsy from 'formsy-react'
import FormDatepicker from '../../components/form/FormDatepicker'

const FilterByDates = ({changeDates, dates, texts}) => {
  return (
    <Formsy className="row datepicker__group" onChange={(model) => changeDates(model)}>
      <div className="col-sm-6 scale__wrap">
        <div className="datepicker">
          <p className="datepicker__descr">{texts.ScaleStart}</p>
          <FormDatepicker
            dateFormat="dd / MM / yyyy"
            name="scalestartdate"
            value={dates.scalestartdate} />
        </div>
      </div>
      <hr className="visible-xs"/>
      <div className="col-sm-6 trans__wrap">
        <div className="datepicker">
          <p className="datepicker__descr">{texts.ScaleEnd}</p>
          <FormDatepicker
            dateFormat="dd / MM / yyyy"
            name="scaleenddate"
            value={dates.scaleenddate} />
        </div>
      </div>
    </Formsy>
  )
}

FilterByDates.propTypes = {
  changeDates: PropTypes.func.isRequired,
  dates: PropTypes.shape({
    scalestartdate: PropTypes.object.isRequired,
    scaleenddate: PropTypes.object.isRequired,
  }).isRequired
}

export default FilterByDates
