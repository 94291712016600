
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import ReactTable from "react-table";

import classes from './Departments.module.scss';

import http from '../../utils/Http';
import httpJSON from '../../utils/HttpJSON'
import * as actions from '../../store/actions/index';

import AdvDeleteItemPopup from '../../components/advertising/AdvDeleteItemPopup';
import produce from 'immer';

class Departments extends Component {

   state = {
      data: [],
      deleted: [],
      showSaveButton: false,
      deleteId: 0,
      showDeletePopup: false,
      saveChanges: false,
   };

   async componentDidMount() {
      const data = await http('GetDepartments', 'GET');
      this.setState({ data });
   }

   addRow = e => {
      const maxId = Math.max.apply(Math, this.state.data.map(o => o.id));
      console.log(`max id is ${maxId}`);

      const data = produce(this.state.data, draft => {
         draft.push({ deleteId: maxId + 1, id: maxId + 1, name: '???', state: 'added' });
      });
      this.setState({ data });
   }

   deleteRow = deleteId => {
      this.setState({ showDeletePopup: true, deleteId });
   }

   handlePopupDelete = (type, e) => {
      if (type === 'delete') {
         const deleted = produce(this.state.deleted, draft => {
            const actualIndex = this.state.data.findIndex(x => x.deleteId === this.state.deleteId);
            draft.push({ ...this.state.data[actualIndex], state: 'deleted' });
         });
         const data = this.state.data.filter(x => x.deleteId !== this.state.deleteId);
         this.setState({ data, deleted });
      }
      this.setState({ showDeletePopup: false });
   }

   handleSumbit = async (e) => {
      const data = await httpJSON(`UpdateDepartments`, 'POST', this.state.data.concat(this.state.deleted));
      this.setState({ data, deleted: [], saveChanges: false });
   }

   renderEditable = cellInfo => {
      if (this.state.data[cellInfo.index] == null)
         return null;
      return (
         <div
            className={classes.vertical_center}
            contentEditable
            suppressContentEditableWarning
            onBlur={e => {
               const data = produce(this.state.data, draft => {
                  draft[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                  draft[cellInfo.index].state = 'modified';
               });
               this.setState({ data, saveChanges: true });
            }}
            dangerouslySetInnerHTML={{
               __html: this.state.data[cellInfo.index][cellInfo.column.id]
            }}
         />
      );
   }

   render() {

      const {
         data,
         showDeletePopup,
         saveChanges
      } = this.state;

      //console.log('Departments render', data);
      return (
         <section className="managment-system">
            <div className="container">
               <div className="row">
                  <div className="col-md-3 col-xs-12">
                     <div className={classes.header}>
                        <h3>מחלקות</h3>
                        <div className={classes.users__buttons} >
                           <button className={`green-btn ${classes.users__button}`} onClick={(e) => this.addRow(e)}>הוסף מחלקה</button>
                           <button className={`${saveChanges ? 'green-btn' : 'grey-btn'} ${classes.users__button}`} onClick={(e) => this.handleSumbit(e)}>שמירת נתונים</button>
                        </div>
                     </div>
                     <div className={classes.users__table}>
                        < ReactTable
                           data={data}
                           noDataText="אין נתונים להצגה.."
                           previousText="הקודם"
                           nextText="הבא"
                           pageText="דף"
                           ofText="/"
                           defaultSorted={[{
                              id: 'id',
                              desc: false,
                           }]}
                           columns={[
                              {
                                 Header: "",
                                 accessor: "deleteId",
                                 width: 30,
                                 Cell: row => {
                                    if (row.index === 0)
                                       return null;
                                    return (
                                       <div className={classes.vertical_center}>
                                          <a href="#" className={`bolet-icon-trash align-center`} onClick={() => this.deleteRow(row.row.deleteId)}> </a>
                                       </div>
                                    )
                                 }
                              },
                              {
                                 accessor: 'id',
                                 Header: 'קוד',
                                 width: 40,
                              },
                              {
                                 accessor: 'name',
                                 Header: 'שם',
                                 Cell: this.renderEditable
                              },
                           ]}
                           showPageSizeOptions={false}
                           defaultPageSize={10}
                           className="-striped -highlight"
                        />
                     </div>
                     <AdvDeleteItemPopup
                        visible={showDeletePopup}
                        title='למחוק מחלקה?'
                        onClick={this.handlePopupDelete}
                     />
                  </div>
               </div>
            </div>
         </section>
      )
   }
}

function mapStateToProps(state) {
   return {
      Departments: state.App.Departments,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: bindActionCreators(actions, dispatch)
   }
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Departments);
