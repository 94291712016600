export function getLocal(item) {
  return JSON.parse(localStorage.getItem(item))
}

export function setLocal(item, data) {
  localStorage.setItem(item, JSON.stringify(data))
}

export function removeLocal(item) {
  localStorage.removeItem(item)
}
