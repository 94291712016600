/** @jsx jsx */

import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';

import ReactDataGrid from 'react-data-grid';
import { Menu } from "react-data-grid-addons";

import { TwitterPicker } from 'react-color';
import { jsx, css } from '@emotion/core';
import produce from "immer";

import * as actions from '../../store/actions/index';
import TableDragSelect from "../TableDragSelect";
import { getLocal } from '../../utils/LocalStorage';
import Http from '../../utils/Http';
import HttpJson from '../../utils/HttpJSON';
import Icon from '../../utils/Icon';

import ScalesGroups from '../../components/ScalesGroups'

import img from '../../assets/images/list.png';
import dropDownIcon from "../../assets/images/dropdown-arrow.png";

import classes from './presets.module.scss';

import { ReactComponent as RefreshIcon } from '../../assets/images/sync.svg';
import { ReactComponent as FirstIcon } from '../../assets/images/first-icon.svg';
import { ReactComponent as PrevIcon } from '../../assets/images/prev-icon.svg';
import { ReactComponent as NextIcon } from '../../assets/images/next-icon.svg';
import { ReactComponent as LastIcon } from '../../assets/images/last-icon.svg';
import { ReactComponent as PresetItemIcon } from '../../assets/images/preset-item.svg';
import { ReactComponent as PresetListsIcon } from '../../assets/images/preset-lists.svg';
import { ReactComponent as PresetNewListIcon } from '../../assets/images/preset-new-list.svg';
import { ReactComponent as AddIcon } from '../../assets/images/new-document.svg';
import { ReactComponent as DelIcon } from '../../assets/images/delete-file-symbol.svg';
import { ReactComponent as SaveIcon } from '../../assets/images/export-file.svg';
import { ReactComponent as EditIcon } from '../../assets/images/details.svg';
import { ReactComponent as AcceptIcon } from '../../assets/images/accept.svg';
import { ReactComponent as DeleteListIcon } from '../../assets/images/delete-button.svg';
import { ReactComponent as AddPresetIcon } from '../../assets/images/add-column.svg';
import { ReactComponent as DelPresetIcon } from '../../assets/images/delete-column.svg';
import { ReactComponent as PaintIcon } from '../../assets/images/paint-icon.svg';
import { ReactComponent as CancelSelectionIcon } from '../../assets/images/cancel-selection.svg';
import { ReactComponent as OptionIcon } from '../../assets/images/details.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/images/duplicate.svg';

import AdvLayoutNamePopup from '../../containers/Advertising/AdvSaleNamePopup';
import AdvDeleteItemPopup from "../../components/advertising/AdvDeleteItemPopup"
import WarningPopup from '../../components/advertising/warningPopup';
import OptionPopup from "./OptionsPopup";

import CheckBox from '../../components/Checkbox';

const ShowListContext = React.createContext(true);
const { ContextMenu, MenuItem, SubMenu, ContextMenuTrigger } = Menu;

const SearchInput = (props) => {
	const onChange = (e) => props.onChange(e.currentTarget.value)

	return (
		<div className="form form__search">
			<div className="form__input-wrap2">
				<input
					type="search"
					className="form__input"
					placeholder={props.placeholder}
					onChange={onChange}
					value={props.value} />
				<i className="input__icon icon-search"></i>
			</div>
		</div>
	)
}

const ExampleContextMenu = ({
	idx,
	id,
	rowIdx,
	onRowDelete,
	onRowInsertAbove,
	onRowInsertBelow
}) => {
	return (
		<ContextMenu id={id}>
			<MenuItem data={{ rowIdx, idx }} onClick={onRowDelete}>
				Delete Row
			</MenuItem>
			<SubMenu title="Insert Row">
				<MenuItem data={{ rowIdx, idx }} onClick={onRowInsertAbove}>
					Above
				</MenuItem>
				<MenuItem data={{ rowIdx, idx }} onClick={onRowInsertBelow}>
					Below
				</MenuItem>
			</SubMenu>
		</ContextMenu>
	);
}

const NameFormatter = (props) => {
	//console.log('NameFormatter', props)
	if (props.row.list.items.length) {
		return (
			<>
				<span>{props.value}</span>
				<img src={img} alt="" />
			</>
		)
	}
	else {
		return props.value;
	}
};

const OptionFormatter = (props) => {
	const isItemOptions = props.row.item.options != null;
	const source = isItemOptions ? props.row.item.options : props.row.options;
	//console.log('OptionFormatter', props.row, isItemOptions, source)

	if (!props.row.list.items.length && props.row.code) {
		const tooltip = source != null && source.length > 0
			? source.map((option, index) => {
				const title = props.presetOptions.find(presetOption => presetOption.fieldName === option.Key).title;
				const seperator = (index !== source.length - 1) ? ' | ' : '';
				return (
					`${title}: ${option.Value} ${seperator}`
				)
			})
			: '';
		const tooltipInfo = (source != null && source.length > 0)
			? <span className="tooltiptext"><pre>{tooltip}</pre></span>
			: null;

		return (
			<button onClick={(e) => props.onClick(e, props.row.id)}>
				<OptionIcon title={tooltipInfo} width="20px" height="20px" css={tooltip.length ? css`path{fill:black};` : css`path{fill:gray};`} />
			</button>
		)
	}
	else if (props.row.list.items.length) {
		return (
			<button onClick={() => props.onDeleteClick(props.row.id)}>
				<DeleteListIcon title='מחיקת קבוצה' width="18px" height="18px" css={css`path{fill:red};`} />
			</button>
		)
	}
	else {
		return props.value;
	}
};

class ItemNameEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchedItems: [],
			searched: null,
		};
	}

	static contextType = ShowListContext;

	async componentDidMount() {
		this.setState({ searchedItems: this.props.items });
	}

	getValue() {
		console.log('getValue', this.props.active, this.state.selected)

		if (this.props.active && this.state.selected)
			return { code: this.state.selected.Code, name: this.state.searched };
		else
			return { code: '' }
	}

	getInputNode() {
		return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
	}

	handleChange = value => {
		const searchedItems = this.props.items.filter(item => item.Name.includes(value));
		this.setState({ searchedItems });
		this.setState({ searched: value });
	};

	getRow = index => {
		const data = produce(this.state.searchedItems[index], draft => {
			if (draft != null) {
				draft.codeName = `[${draft.Code}] ${draft.Name}`;
			}
		})

		return data;
	}

	onRowClick = (index) => {
		const selected = this.state.searchedItems[index];
		this.setState({ selected }, () => this.props.onCommit());
	}

	handlePresetNewListClick = async () => {
		const selected = { Code: '+', Name: this.state.searched };
		this.setState({ selected }, () => this.props.onCommit());
	}

	render() {
		if (!this.props.active)
			return <div></div>;

		const searchType = 'חיפוש פריט';
		return (
			<div className={classes.search_container}>
				<div className={classes.search_header}>
					<span className={classes.search_text} >
						{`${searchType} (${this.state.searchedItems.length})`}
					</span>

				</div>
				<div className={classes.search_input} >
					<SearchInput
						placeholder={'הקש טקסט לחיפוש..'}
						onChange={(e) => this.handleChange(e)}
					/>
					{!this.context &&
						<button className={classes.search_button} onClick={this.handlePresetNewListClick} >
							<PresetNewListIcon />
						</button>
					}
				</div>
				<div className={classes.search_table} >
					<ReactDataGrid
						columns={[
							{
								key: 'codeName',
								name: '[קוד] שם',
							},
						]}
						rowGetter={rowIndex => this.getRow(rowIndex)}
						rowsCount={this.state.searchedItems.length}
						minHeight={150}
						onRowClick={rowIndex => this.onRowClick(rowIndex)} />
				</div>
			</div >
		);
	}
}

class DeleteFormatter extends Component {
	render() {
		return (
			<div>
				<a href="#" className="bolet-icon-trash align-center" onClick={() => this.props.onDeleteRow(this.props.value)}> </a>
			</div>);
	}
}

class Presets extends Component {

	state = {
		data: [],
		items: [],
		scaleTypes: [],
		branches: null,
		selectedDepartment: null,
		showColorPicker: false,
		showScaleTypeSelection: false,
		scaleTypeIndex: null,
		layoutIndex: null,
		showNewLayoutPopup: false,
		showDuplicateLayoutPopup: false,
		showEditLayoutNamePopup: false,
		showDeleteLayoutPopup: false,
		deleteItemTitle: '',
		deleteItemType: '',
		deleteId: null,
		selectedList: null,
		selectedListPresetIndex: null,
		presetOptions: [],
		presetOptionSelectedIndex: null,
		showEmptyPresets: true,
		showWarningPopup: false,
		showDeplicateCodePopup: false,
		showSaveButton: false,
		pendingData: [],
		warningPopupTitle: '',
		selectedLayout: null,
		page: 0,
		pages: 0,
		presetsCount: 0,
		selectedColor: 'black',
		color: '#fff',
		value: '',
	};

	async componentDidMount() {

		const user = getLocal('UserProfile');
		const items = await Http(`GetPluItemList/${getLocal('SessionKey')}`, 'GET', { id: user.UserAccountSysId });
		this.props.actions.presetItemsSet(items);
		const scaleTypes = await Http('GetGroupScaleTypes', 'GET');
		const branches = await Http('GetBranches', 'GET');
		const presetOptions = await Http('GetPresetOptions', 'GET');
		const data = await Http('GetLayouts', 'GET');

		console.log('data', data);
		console.log('scaleTypes', scaleTypes);
		console.log('presetOptions', presetOptions);
		this.setState({
			items,
			scaleTypeIndex: scaleTypes.findIndex(x => x.name === 'netscale'),
			scaleTypes,
			branches,
			data,
			presetOptions,
		});
	}

	RenderRow = (cols, rows, pageSize, items) => {
		const cellStyle = css`
         color: #806E52;
         border-radius: 8px;
         background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #888));
         display: flex;
         flex-direction: column;
				 position: relative;
				 padding-top:3px;
         &:hover {
            cursor: pointer;
         }`;
		const cellSelectedStyle = css`border: 2px solid red`;

		const colors = ['#888', '#FF0000', '#FFFF00', '#00FF00', '#0000FF', '#D2B48C', '#A0522D', '#556B2F', '#8B008B'];

		return (
			items.map((preset, index) => (() => {

				const id = preset.id % pageSize ? preset.id % pageSize : pageSize;
				const row = this.state.selectedList !== null
					? (preset.index + 1) % cols ? Math.floor(((preset.index + 1) % pageSize) / cols) + 1 : Math.floor(((preset.index + 1) % pageSize) / cols)
					: Math.ceil(id / rows);
				const col = this.state.selectedList !== null
					? (preset.index + 1) % cols ? (preset.index + 1) % cols : cols
					: id - (row - 1) * rows;
				const locationStyle = !this.state.scaleTypes[this.state.scaleTypeIndex].removeEmptyPresets ? css`grid-row: ${row}/${row}; grid-column: ${col}/${col};` : '';

				console.log('preset', preset, id, row, col)
				// --- show list presets ---
				if (this.state.selectedList !== null) {
					const cellColorStyle = css`background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, ${colors[preset.color]}));`;
					return (
						<div key={index} css={[cellStyle, locationStyle, cellColorStyle, preset.selected ? cellSelectedStyle : null]} onClick={() => this.handlePresetClick(preset.index)} >
							<div css={css`
									flex: 1;
									color: #333;
									font: 12pt "Arial";
									font-weight: bold;
									display: flex;
									text-align: center;
									align-self: center;`}>
								{preset.name}
							</div>
							<div css={css`
									display: flex;
									justify-content: space-between;
									position: relative;
									bottom: 10px;`}>
								<p css={css`
									color: #2B33A2;
									font: 10pt "Arial";
									font-weight: bold;
									text-align: right;
									margin-right: 10px;`}>
									<span css={css`
												position: relative;
												color: #000;
												font: 10pt "Arial";
												float: left;
												left: 3px;
												top: 5px;`}>
										{preset.index + 1}
									</span>
								</p>
								<p css={css`
									position: relative;
									color: #AE3800;
									font: 10pt "Arial";
									font-weight: bold;
									float: right;
									right: 3px;
									top: 5px;`}>
									{preset.code ? preset.code + ' קוד' : ''}
								</p>
							</div>
						</div >
					)
				}
				// --- show main presets ---
				// Show list preset.
				if (preset.list != null && preset.list.items.length) {
					const cellColorStyle = css`background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, ${colors[preset.list.color]}));`;
					return (
						<div key={index} css={[cellStyle, locationStyle, cellColorStyle, preset.selected ? cellSelectedStyle : null]} onClick={() => this.handlePresetClick(preset.id)} >
							<img css={css`
										align-self:flex-end;
										right: 5px;
										top: 5px;
										position: relative;`}
								src={img} alt="" />
							<div css={css`
										color: #333;
										font: 12pt "Arial";
										font-weight: bold;
										text-align: center;`}>
								{preset.list.names.length > 0 ? preset.list.names[0] : ''}
							</div>
							<div css={css`
										position: relative;
										color: #000;
										font: 10pt "Arial";
										float: left;
										left: 3px;
										top: 5px;`}>
								{preset.id}
							</div>
						</div>
					)
				}
				// Show item preset.
				const cellColorStyle = css`background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, ${colors[preset.item.color]}));`;
				return (
					<div key={index} css={[cellStyle, locationStyle, cellColorStyle, preset.selected ? cellSelectedStyle : null]} onClick={() => this.handlePresetClick(preset.id)} >
						<div css={css`
									flex: 1;
									color: #333;
									font: 12pt "Arial";
									font-weight: bold;
									display: flex;
									text-align: center;
									align-self: center;`}>
							{preset.item.name}
						</div>
						<div css={css`
									display: flex;
									justify-content: space-between;
									position: relative;
									bottom: 10px;`}>
							<p css={css`
									color: #2B33A2;
									font: 10pt "Arial";
									font-weight: bold;
									text-align: right;
									margin-right: 10px;`}>
								<span css={css`
												position: relative;
												color: #000;
												font: 10pt "Arial";
												float: left;
												left: 3px;
												top: 5px;`}>
									{preset.id}
								</span>
							</p>
							<p css={css`
									position: relative;
									color: #AE3800;
									font: 10pt "Arial";
									font-weight: bold;
									float: right;
									right: 3px;
									top: 5px;`}>
								{preset.item.code ? preset.item.code + ' קוד' : ''}
							</p>
						</div>
					</div >
				)
			})()
			))
	}

	RenderRows = (cols = 7, rows = 7, page) => {

		const {
			data,
			scaleTypes,
			scaleTypeIndex,
			selectedList,
			layoutIndex
		} = this.state;

		console.log('render row', data)
		if (data != null && layoutIndex !== null) {

			function filterByCode(preset) {
				//console.log('filterByCode', preset)
				if (preset.list.items.length || preset.item.code > 0)
					return true;
				return false;
			}

			const pageSize = rows * cols;
			let pageStartIndex = (page - 1) * pageSize + 1;

			let source;
			if (selectedList !== null) {
				source = selectedList.items.filter(item => item.code !== 0);
				pageStartIndex = (page - 1) * pageSize;
			}
			else {
				source = data[layoutIndex].presets.filter(filterByCode);
				pageStartIndex = (page - 1) * pageSize + 1;
			}

			//console.log('RenderRows', page, selectedList, source, pageStartIndex, pageSize)
			let rowsView = [];
			for (let row = 0; row < rows; ++row) {

				const rowStartIndex = pageStartIndex + (row * cols);
				const rowEndIndex = rowStartIndex + cols - 1;
				const rowData = selectedList
					? source.filter(x => x.index >= rowStartIndex && x.index <= rowEndIndex)
					: source.filter(x => x.id >= rowStartIndex && x.id <= rowEndIndex);
				//console.log('rowData', rowData, rowStartIndex, pageStartIndex);
				rowsView.push(this.RenderRow(cols, rows, pageSize, rowData));
			}

			return rowsView;
		}
	}

	handlePluFileRefresh = async () => {
		const user = getLocal('UserProfile');
		const items = await Http(`GetPluItemList/${getLocal('SessionKey')}`, 'GET', { id: user.UserAccountSysId });
		this.setState({ items });
	}

	handlePresetClick = id => {
		const { layoutIndex, data, selectedList, selectedListPresetIndex } = this.state;
		const actualIndex = data[layoutIndex].presets.findIndex(preset => preset.id === id);
		//console.log('handlePresetClick', id, selectedListPresetIndex, data[layoutIndex].presets[actualIndex])

		// handle list items view.
		if (selectedList !== null) {
			const actualListIndex = this.state.selectedList.items.findIndex(item => item.index === id);
			const selectedList = produce(this.state.selectedList, draft => {
				draft.items[actualListIndex].selected = !draft.items[actualListIndex].selected;
			});
			this.setState({ selectedList });
		}
		// enter list items view.
		else if (data[layoutIndex].presets[actualIndex].list.items.length && data[layoutIndex].presets[actualIndex].selected) {
			this.handleClick('clear-all');
			this.setState({ selectedListPresetIndex: actualIndex, selectedList: data[layoutIndex].presets[actualIndex].list, page: 1 });
		}
		// exit list items view.
		else if (selectedListPresetIndex) {
			// update list into the main data.
			const data = produce(this.state.data, draft => {
				data[layoutIndex].presets[selectedListPresetIndex].list = selectedList;
			});
			this.setState({ data, selectedListPresetIndex: null, selectedList: null });
		}
		// handle presets view.
		else {
			const data = produce(this.state.data, draft => {
				draft[layoutIndex].presets[actualIndex].selected = !draft[layoutIndex].presets[actualIndex].selected;
			});
			this.setState({ data });
		}
	}

	handleClick = (type) => {

		const { layoutIndex, selectedList } = this.state;

		switch (type) {
			case 'clear-all':
				if (layoutIndex !== null) {
					if (selectedList !== null) {
						const selectedList = produce(this.state.selectedList, draft => {
							for (let i = 0; i < draft.items.length; ++i)
								draft.items[i].selected = false;
						});
						this.setState({ selectedList });
					}
					else {
						const data = produce(this.state.data, draft => {
							for (let i = 0; i < draft[layoutIndex].presets.length; ++i)
								draft[layoutIndex].presets[i].selected = false;
						});
						this.setState({ data });
					}
				}
				break;

			case 'paint':
				this.setState({ showColorPicker: true });
				break;

			case 'add-preset':
				if (layoutIndex !== null) {
					const data = produce(this.state.data, draft => {
						for (let i = 0; i < draft[layoutIndex].presets.length; ++i) {
							if (draft[layoutIndex].presets[i].selected) {
								draft[layoutIndex].presets[i].selected = false;
								// Add myself and change index of all elements after me to be index+1
								const addedId = draft[layoutIndex].presets[i].id;
								const addedIndex = draft[layoutIndex].presets.findIndex(x => x.id == addedId);
								console.log('add id', addedId)
								draft[layoutIndex].presets.forEach(preset => {
									if (preset.id >= addedId)
										preset.id++;
								});
								const emptyItem = { id: addedId, code: '', color: 0, name: "", item: {}, list: { items: 0 } };
								draft[layoutIndex].presets.splice(addedIndex, 0, emptyItem);
							}
						}
					});
					console.log('add preset', data)
					this.setState({ data, showSaveButton: true });
				}
				break;

			case 'del-preset':
				if (layoutIndex !== null) {
					const data = produce(this.state.data, draft => {
						for (let i = 0; i < draft[layoutIndex].presets.length; ++i) {
							if (draft[layoutIndex].presets[i].selected) {
								draft[layoutIndex].presets[i].selected = false;
								// Remove myself and change index of all elements after me to be index-1								
								const deleteId = draft[layoutIndex].presets[i].id;
								draft[layoutIndex].presets = draft[layoutIndex].presets.filter(preset => preset.id !== deleteId);
								draft[layoutIndex].presets.forEach(preset => {
									if (preset.id > deleteId)
										preset.id--;
								});
							}
						}
					});
					this.setState({ data, showSaveButton: true });
				}
				break;

			case 'clear':
				if (layoutIndex) {
					const data = produce(this.state.data, draft => {
						for (let i = 0; i < draft[layoutIndex].presets.length; ++i) {
							if (draft[layoutIndex].presets[i].selected) {
								draft[layoutIndex].presets[i].selected = false;
								draft[layoutIndex].presets[i].code = '';
								draft[layoutIndex].presets[i].color = 0;
								draft[layoutIndex].presets[i].name = '';
								draft[layoutIndex].presets[i].list.items.length = false;
							}
						}
					});
					this.setState({ data });
				}
				break;

			case 'first':
				this.setState({ page: 1 });
				break;

			case 'prev':
				if (this.state.page > 1)
					this.setState(prevState => ({ page: prevState.page - 1 }));
				else
					this.setState({ page: 1 });
				break;

			case 'next':
				if (this.state.page < this.state.pages)
					this.setState(prevState => ({ page: prevState.page + 1 }));
				else
					this.setState({ page: this.state.pages });
				break;

			case 'last':
				this.setState({ page: this.state.pages });
				break;

			default:
				break;
		}
	}

	handleColorSelection = (color) => {
		const { layoutIndex, selectedList } = this.state;
		const colors = ['#888', '#ff0000', '#ffff00', '#00ff00', '#0000ff', '#d2b48c', '#a0522d', '#556b2f', '#8b008b'];
		this.setState({ showColorPicker: false });
		if (layoutIndex !== null) {
			if (selectedList !== null) {
				const selectedList = produce(this.state.selectedList, draft => {
					for (let i = 0; i < draft.items.length; ++i) {
						if (draft.items[i].selected) {
							draft.items[i].selected = false;
							draft.items[i].color = colors.indexOf(color.hex);
						}
					}
				});
				this.setState({ selectedList, showSaveButton: true });
			}
			else {
				const data = produce(this.state.data, draft => {
					for (let i = 0; i < draft[layoutIndex].presets.length; ++i) {
						if (draft[layoutIndex].presets[i].selected) {
							draft[layoutIndex].presets[i].selected = false;
							draft[layoutIndex].presets[i].item.color = colors.indexOf(color.hex);
							draft[layoutIndex].presets[i].list.color = colors.indexOf(color.hex);
						}
					}
				});
				this.setState({ data, showSaveButton: true });
			}
		}
	}

	// custom render Select component
	setValue = (value) => {
		this.setState({ value });
		if (value) {
			console.log('Support level selected:', value.label);
		}
	}
	renderLink = () => {
		return <a style={{ marginLeft: 5 }} href="/upgrade" target="_blank">Upgrade here!</a>;
	}
	renderOption = (option) => {
		return (
			<div>
				{option.label}
			</div>
		);
	}
	renderValue = (option) => {
		return <strong style={{ color: option.color }}>{option.label}</strong>;
	}

	handleDeletedRow = row => {
		const { Branches } = this.props;
		if (row !== Branches[Branches.length - 1].deleteId) {
			let rows = Branches.filter(x => x.deleteId !== row);
			this.props.Actions.UpdateBranchesData(rows);
			this.setState({ showSaveButton: true });
		}
	};

	handleGridRowsUpdate = ({ fromRow, toRow, updated }) => {

		function compare(a, b) {
			let comparison = 0;
			if (a.id > b.id) {
				comparison = 1;
			} else if (a.id < b.id) {
				comparison = -1;
			}
			return comparison;
		}

		console.log('handleGridRowsUpdate', fromRow, toRow, updated);

		const { layoutIndex, showWarningPopup, warningPopupTitle } = this.state;
		const item = this.state.items.find(item => item.Code === Number(updated.code));

		if (this.state.selectedList !== null) {
			const selectedList = produce(this.state.selectedList, draft => {
				for (let i = fromRow; i <= toRow; i++) {
					if (updated) {
						const index = this.state.selectedList.items.findIndex(x => x.index === fromRow);
						if (index === -1) {
							draft.items.push({
								index: i,
								color: 0,
								code: Number(updated.code) !== 0 ? Number(updated.code) : '',
								name: item ? item.Name : '',
								options: []
							});
						}
						// delete row
						else if (updated.code === '') {
							draft.items.splice(i, 1);
						}
						// update row
						else if (draft.items[i] != null) {
							draft.items[i].code = Number(updated.code) !== 0 ? Number(updated.code) : '';
							draft.items[i].name = item ? item.Name : '';
						}
						// add row
						else {
							draft.items.push({
								index: i - 1,
								color: 0,
								code: Number(updated.code) !== 0 ? Number(updated.code) : '',
								name: item ? item.Name : '',
								options: []
							});
						}
					}
				}
			});

			this.setState({ selectedList });
		}
		else {
			// Update list (from search dialog or by entering code).
			if (typeof updated.code.startsWith === "function" && updated.code.startsWith('+')) {
				const items = [];
				// Add first items to indicate that this list exist.
				items.push({ code: 0, color: 0, index: 1, name: '', options: [] });

				// search if row exist, if not add it.
				const index = this.state.data[layoutIndex].presets.findIndex(x => x.id === fromRow + 1);
				if (index === -1) {
					const data = produce(this.state.data, draft => {
						draft[layoutIndex].presets.push({
							id: fromRow + 1,
							item: { code: 0, color: 0, index: 1, name: '', options: [] },
							list: { color: 0, items: items, names: [updated.name] }
						});
					});
					this.setState({ data, presetsCount: data[layoutIndex].presets.length, showSaveButton: true });
				}
				else {
					const data = produce(this.state.data, draft => {
						for (let i = fromRow; i <= toRow; i++) {
							draft[layoutIndex].presets[i].item.name = '';
							draft[layoutIndex].presets[i].list.items = items;
							draft[layoutIndex].presets[i].item.code = 0;
							draft[layoutIndex].presets[i].list.names[0] = updated.name;
						}
					});
					this.setState({ data, presetsCount: data[layoutIndex].presets.length, showSaveButton: true });
				}

			}
			else {
				const data = produce(this.state.data, draft => {
					for (let i = fromRow; i <= toRow; i++) {
						if (updated && layoutIndex !== null) {
							const actualIndex = draft[layoutIndex].presets.findIndex(x => x.id === i + 1);
							// delete row
							if (!updated.code.toString().length && actualIndex !== -1) {
								console.log('delete row', this.state.data[layoutIndex].presets, actualIndex);
								draft[layoutIndex].presets.splice(actualIndex, 1);
							}
							// update row
							else if (draft[layoutIndex].presets[actualIndex] != null) {
								draft[layoutIndex].presets[actualIndex].item.code = Number(updated.code) !== 0 ? Number(updated.code) : '';
								draft[layoutIndex].presets[actualIndex].item.name = item ? item.Name : '';
							}
							// add row
							else {
								draft[layoutIndex].presets.push({
									id: i + 1,
									list: {
										color: 0,
										items: [],
										names: [],
										options: [],
									},
									item: {
										code: Number(updated.code) !== 0 ? Number(updated.code) : '',
										color: 0,
										index: 0,
										name: item ? item.Name : '',
										options: []
									}
								});
								// sort
								const value = draft[layoutIndex].presets.sort(compare);
							}
						}
					}
				}); // produce

				if (updated.code.length && item == null) {
					this.setState({ showWarningPopup: true, warningPopupTitle: 'פריט לא קיים' });
				}
				else {
					// test if item exist in the presets
					if (updated.code.toString().length && this.state.data[layoutIndex].presets.filter(x => x.item.code === Number(updated.code)).length) {
						this.setState({ showDeplicateCodePopup: true, warningPopupTitle: 'קוד קיים בפריסט אחר, להוסיף?', pendingData: data });
					}
					else {
						this.setState({ data, presetsCount: data[layoutIndex].presets.length, showSaveButton: true });
					}
				}
			}
		}
	};

	getRow = index => { // index is zero based, list isn't..

		const emptyItem = { id: index + 1, code: '', color: 0, name: "", item: {}, list: { items: 0 } };
		const emptyListItem = { id: index + 1, code: '', color: 0, name: "", item: {}, list: { items: 0 } };
		const { layoutIndex, selectedList } = this.state;

		if (selectedList != null) {
			const actualIndex = this.state.selectedList.items.findIndex(x => x.index === index);
			const data = produce(this.state.selectedList.items, draft => {
				// row exist
				if (actualIndex !== -1) {
					draft[actualIndex].id = index + 1;
					draft[actualIndex].list = { items: 0 };
					draft[actualIndex].item = {};
					draft[actualIndex].code = Number(draft[actualIndex].code) > 0 ? draft[actualIndex].code : '';
				}
			});
			//console.log('get row list', index, actualIndex !== -1 ? data[actualIndex] : emptyListItem)
			return actualIndex !== -1 ? data[actualIndex] : emptyListItem;
		}
		if (layoutIndex !== null) {
			const actualIndex = this.state.data[layoutIndex].presets.findIndex(x => x.id - 1 === index);
			if (actualIndex !== -1) {
				const data = produce(this.state.data[layoutIndex].presets[actualIndex], draft => {
					if (draft != null) {
						if (draft.list.items.length) {
							draft.name = draft.list.names.length > 0 ? draft.list.names[0] : '';
							draft.code = '';
							draft.color = draft.list.color;
						}
						else {
							draft.name = draft.item.name;
							draft.code = Number(draft.item.code) > 0 ? draft.item.code : '';
							draft.color = draft.item.color;
						}
					}
				});
				//console.log('get row item', index, data)
				return data;
			}
		}
		//console.log('get row empty item', index, emptyItem)
		return emptyItem;
	}

	getRowCount = () => {
		const { scaleTypeIndex, layoutIndex, scaleTypes, selectedListPresetIndex, selectedList } = this.state;

		if (selectedListPresetIndex !== null && selectedList !== null) {
			return scaleTypes[scaleTypeIndex].totalPagePresets - 1;
		}
		else if (layoutIndex !== null) {
			return scaleTypes[scaleTypeIndex].maxPresets - 1;
		}
		return 0;
	}

	handleLayoutSelection = id => {
		const { data } = this.state;
		const layoutIndex = data.findIndex(x => x.id === id);
		const presetsCount = layoutIndex !== null ? parseInt(data[layoutIndex].presets.length) : 0;
		const presetsInPage = this.state.scaleTypes[this.state.scaleTypeIndex].totalPagePresets;
		const page = 1;
		let pages = Math.round(this.state.scaleTypes[this.state.scaleTypeIndex].maxPresets / presetsInPage);
		if (this.state.scaleTypes[this.state.scaleTypeIndex].maxPresets % presetsInPage)
			pages++;
		this.setState({
			layoutIndex,
			page,
			pages,
			presetsCount,
			selectedListPresetIndex: null,
			selectedList: null
			/* FUTURE SUPPORT SELECTING SCALE TYPE,NEED TO CHANGE VIEW ACCORDING SELECTED TYPE
						showScaleTypeSelection: true,
			*/
		});
	}

	handleLayoutSave = async () => {
		const data = await HttpJson(`UpdateLayout`, 'POST', this.state.data[this.state.layoutIndex]);
		this.setState({ data, showSaveButton: false });
	}

	handleScaleTypeSelection = value => {
		const { scaleTypes } = this.state;
		const scaleTypeIndex = scaleTypes.findIndex(x => x.value === value);
		this.setState({ scaleTypeIndex });
	}

	handleBranchSelection = value => {

	}

	getSubRowDetails = expandedRows => rowItem => {
		const isExpanded = expandedRows[rowItem.id]
			? expandedRows[rowItem.id]
			: false;
		return {
			group: rowItem.teamMembers && rowItem.teamMembers.length > 0,
			expanded: isExpanded,
			children: rowItem.teamMembers,
			field: "firstName",
			treeDepth: rowItem.treeDepth || 0,
			siblingIndex: rowItem.siblingIndex,
			numberSiblings: rowItem.numberSiblings
		};
	};

	updateSubRowDetails = (subRows, parentTreeDepth) => {
		const treeDepth = parentTreeDepth || 0;
		subRows.forEach((sr, i) => {
			sr.treeDepth = treeDepth + 1;
			sr.siblingIndex = i;
			sr.numberSiblings = subRows.length;
		});
	}

	onCellExpand = args => ({ rows, expandedRows }) => {
		const rowKey = args.rowData.id;
		const rowIndex = rows.indexOf(args.rowData);
		const subRows = args.expandArgs.children;
		if (expandedRows && !expandedRows[rowKey]) {
			expandedRows[rowKey] = true;
			this.updateSubRowDetails(subRows, args.rowData.treeDepth);
			rows.splice(rowIndex + 1, 0, ...subRows);
		} else if (expandedRows[rowKey]) {
			expandedRows[rowKey] = false;
			rows.splice(rowIndex + 1, subRows.length);
		}
		return { expandedRows, rows };
	};

	handleNewLayoutClick = async (fields) => {
		const data = await Http(`AddLayout?name=${fields['name']}`, 'POST');
		this.setState({ showNewLayoutPopup: false, data });
	}

	handleDuplicateLayoutClick = async (fields) => {
		const { selectedLayout } = this.state;
		const data = await Http(`DuplicateLayout?source=${selectedLayout.value}&name=${fields['name']}`, 'POST');
		this.setState({ showDuplicateLayoutPopup: false, data });
	}

	handleEditLayoutNameClick = async (fields) => {
		console.log('handleEditLayoutNameClick', fields)
		const data = await Http(`UpdateLayoutName?id=${this.state.data[this.state.layoutIndex].id}&name=${fields['name']}`, 'POST');
		this.setState({ showEditLayoutNamePopup: false, data });
	}

	handlePresetOptionPopup = (options) => {

		//console.log('handlePresetOptionPopup', options)

		const { layoutIndex, presetOptionSelectedIndex, selectedList } = this.state;
		if (selectedList !== null) {
			const selectedList = produce(this.state.selectedList, draft => {
				draft.items.find(x => x.index == presetOptionSelectedIndex).options = [...options];
			});
			this.setState({ selectedList, presetOptionSelectedIndex: null });
		}
		else {
			const data = produce(this.state.data, draft => {
				draft[layoutIndex].presets.find(x => x.id == presetOptionSelectedIndex).item.options = [...options];
			});
			this.setState({ data, presetOptionSelectedIndex: null });
		}
	}

	hidePresetOptionPopup = () => {
		this.setState({ presetOptionSelectedIndex: null });
	}

	hideLayoutDeletePopup = () => {
		this.setState({ showDeleteLayoutPopup: false });
	}

	handleLayoutDelete = async (action) => {
		const { layoutIndex, deleteId, deleteItemType } = this.state;
		if (action === 'delete' && deleteItemType === 'delete-table' && layoutIndex !== null) {
			const data = await Http(`DeleteLayout?id=${this.state.data[layoutIndex].id}`, 'POST');
			this.setState({ layoutIndex: null, selectedLayout: null });
			this.setState({ data });

		}
		else if (action === 'delete' && deleteItemType === 'delete-list' && layoutIndex !== null && deleteId !== null) {
			const data = produce(this.state.data, draft => {
				const actualIndex = draft[layoutIndex].presets.findIndex(x => x.id === deleteId);
				const emptyListItem = { color: 0, names: [], items: 0 };
				draft[layoutIndex].presets[actualIndex].list = emptyListItem;
			});
			this.setState({ data, deleteId: null });
		}
		else if (action === 'delete' && deleteItemType === 'delete-preset' && layoutIndex !== null) {
			this.handleClick('del-preset');
		}
		this.setState({ showDeleteLayoutPopup: false });
	}

	setRows = props => {

	}
	deleteRow = rowIdx => rows => {
		// const nextRows = [...rows];
		// nextRows.splice(rowIdx, 1);
		// return nextRows;
	};

	insertRow = rowIdx => rows => {
		// const newRow = createFakeRow("-");
		// const nextRows = [...rows];
		// nextRows.splice(rowIdx, 0, newRow);
		// return nextRows;
	};

	handlePresetOptionsClick = (e, index) => {
		console.log('handlePresetOptionsClick', index)
		this.setState({ presetOptionSelectedIndex: index });
	}

	handleSaveList = () => {
		const { layoutIndex, selectedListPresetIndex, selectedList } = this.state;
		//console.log('handleSaveList', selectedList, selectedListPresetIndex)
		const data = produce(this.state.data, draft => {
			draft[layoutIndex].presets[selectedListPresetIndex].list = selectedList;
		});
		this.setState({
			data,
			selectedListPresetIndex: null,
			selectedList: null,
		});
	}

	handleListDeleteClick = id => {
		this.setState({
			deleteItemTitle: 'למחוק את הקבוצה?',
			deleteItemType: 'delete-list',
			deleteId: id,
			showDeleteLayoutPopup: true
		});
	}

	handleDuplicateCode = key => {
		const { pendingData, layoutIndex } = this.state;
		if (key === 'yes')
			this.setState({ data: pendingData, presetsCount: pendingData[layoutIndex].presets.length, })
		this.setState({ showDeplicateCodePopup: false })
	}

	// ---------------------------

	render() {

		const {
			showColorPicker,
			items,
			color,
			page,
			pages,
			branches,
			scaleTypes,
			scaleTypeIndex,
			presetsCount,
			selectedColor,
			data,
			showScaleTypeSelection,
			layoutIndex,
			selectedListPresetIndex,
			selectedList,
			showNewLayoutPopup,
			showDuplicateLayoutPopup,
			showEditLayoutNamePopup,
			showDeleteLayoutPopup,
			deleteItemTitle,
			deleteItemType,
			showEmptyPresets,
			presetOptions,
			presetOptionSelectedIndex,
			showWarningPopup,
			showDeplicateCodePopup,
			pendingData,
			warningPopupTitle,
			selectedLayout,
			showSaveButton,

		} = this.state;

		const {
			selectedGroupPresets,
			ShowPresetsGroup,
			selectedGroupId,
			selectedGroup,
			presetItems,
		} = this.props;

		let scaleTypesOptions = [];
		scaleTypes.forEach(function (type) {
			scaleTypesOptions.push({ value: type.id, label: `${type.name}` });
		});

		let layoutsOptions = [];
		if (data) {
			data.forEach(function (layout) {
				layoutsOptions.push({ value: layout.id, label: `${layout.id} | ${layout.name}` })
			});
		}

		console.log('Presets render', selectedList !== null ? false : true);

		if (layoutIndex !== null && data[layoutIndex] !== null) {
			const optionValues = selectedList !== null
				? selectedList.items.find(x => x.index === presetOptionSelectedIndex) != null
					? selectedList.items.find(x => x.index === presetOptionSelectedIndex).options
					: null
				: data[layoutIndex].presets.find(x => x.id === presetOptionSelectedIndex) != null
					? data[layoutIndex].presets.find(x => x.id === presetOptionSelectedIndex).item.options
					: null;

			//console.log('optionValues', optionValues, presetOptionSelectedIndex); 
		}

		const columns = [
			{
				key: '',
				name: '',
				formatter: <OptionFormatter onClick={this.handlePresetOptionsClick} onDeleteClick={this.handleListDeleteClick} presetOptions={presetOptions} />,
				width: 35
			},
			{
				key: 'name',
				name: 'שם',
				editor: <ItemNameEditor items={items} active={layoutIndex !== null ? true : false} />,
				formatter: NameFormatter
			},
			{
				key: 'code',
				name: 'קוד',
				editable: this.state.layoutIndex !== null ? true : false,
				width: 80
			},
			{
				key: 'id',
				name: '#',
				width: 40
			},
		];

		const justify = selectedList === null
			? 'justify-content: space-around;'
			: '';
		return (
			<>
				<div css={css`
									direction: rtl;
                  display: grid;
                  grid-template-columns: 350px 900px;
                  grid-template-rows: auto;
                  grid-template-areas: "groups options" "groups info" "items view";`} >
					<div css={css`
                     direction: ltr;
                     grid-area: options;
                     border: solid 1px #555;
										 border-radius: 11px;
                     display: flex;
                     align-items: center;
										 height: 50px;
                     ${justify}
                     `} >
						<a href="#" onClick={() => this.handleClick('clear-all')}>
							<CancelSelectionIcon title='ביטול סימון פריסטים' width="50" height="30" />
						</a>
						<a style={{ fill: selectedColor }} href="#" onClick={() => this.handleClick('paint')}>
							<PaintIcon title='צביעת פריסטים מסומנים' width="50" height="40" />
						</a>

						{selectedList === null &&
							<>
								<a href="#"
									onClick={() => this.setState({
										deleteItemTitle: 'למחוק את הפריסטים המסומנים?',
										deleteItemType: 'delete-preset',
										showDeleteLayoutPopup: true
									})}
								>
									<DelPresetIcon title='מחיקת פריסט מסומן' width="50" height="30" />
								</a>
								<a href="#" onClick={() => this.handleClick('add-preset')}>
									<AddPresetIcon title='הוספת פריסט לפני הסימון' width="50" height="30" />
								</a>
								<a href="#" onClick={() => this.handleClick('first')}>
									<FirstIcon title='מעבר לעמוד ראשון' width="50" height="40" />
								</a>
								<a href="#" onClick={() => this.handleClick('prev')}>
									<PrevIcon title='מעבר לעמוד קודם' width="50" height="40" />
								</a>
								<a href="#" onClick={() => this.handleClick('next')}>
									<NextIcon title='מעבר לעמוד הבא' width="50" height="40" />
								</a>
								<a href="#" onClick={() => this.handleClick('last')}>
									<LastIcon title='מעבר לעמוד אחרון' width="50" height="40" />
								</a>
							</>
						}
					</div>
					<div css={css`
                     grid-area: info;
                     display: flex;
                     justify-content: space-between;
										 padding-top:10px;
										 padding-bottom:5px;`} >

						<label css={css`margin-right:10px;`}>{`דף ${page}`}</label>
						{this.state.selectedList === null &&
							<label css={css`margin-left:10px;`}>{`פריסטים: ${presetsCount}`}</label>
						}
					</div>
					<div css={css`
                     grid-area: view;
                     margin-bottom: 20px;
										 width: 900px;
                     height: 570px;
                     background-color: #555;
                     border-radius: 11px;
                     direction: ltr;
                     display: grid;
                     grid-template-columns: repeat(7, 117px);
                     grid-template-rows: repeat(7, 70px);
                     grid-gap: 10px;
                     padding: 10px;`} >
						{
							this.state.scaleTypeIndex !== null
								? this.RenderRows(this.state.scaleTypes[this.state.scaleTypeIndex].cols, this.state.scaleTypes[this.state.scaleTypeIndex].rows, page)
								: <div></div>
						}

					</div>

					<div css={css`
								grid-area: groups;
								width: 95%;}`}>

						<div css={css`
									display:flex;
									align-items:center;
									`}>
							<label css={css`
									width: 100%;									
									font-size: 12px;
									color: #0f2649;
									transition: background-color 0.5s ease;
									font-weight: 400;
									margin-bottom: 5px;`}>
								בחירת שבלונה
								<Select css={css`width:100%;`}
									autosize={false}
									isClearable={false}
									simpleValue
									placeholder="בחר שבלונה לעריכה.."
									noOptionsMessage={() => 'לא הוגדרו שבלונות'}
									onChange={(option, { action }) => {
										if (action !== 'clear')
											this.handleLayoutSelection(option.value);
										this.setState({ selectedLayout: option });
									}}
									value={selectedLayout}
									options={layoutsOptions}
								/>
							</label>
						</div>

						<div className="presets__buttons_group">
							<button>
								<AddIcon title='הוספת שבלונה חדשה' width='25' height='25' css={css`fill:orange;`} onClick={() => this.setState({ showNewLayoutPopup: true })} />
							</button>
							{layoutIndex !== null &&
								<>
									<button>
										<DuplicateIcon title='שכפול שבלונה' width='25' height='25' css={css`fill:orange;`} onClick={() => this.setState({ showDuplicateLayoutPopup: true })} />
									</button>
									<button>
										<EditIcon title='עריכת שם שבלונה' width='25' height='25' css={css`fill:orange;`} onClick={() => this.setState({ showEditLayoutNamePopup: true })} />
									</button>
									<button>
										<DelIcon title='מחיקת שבלונה' width='25' height='25' css={css`fill:red;`}
											onClick={() => this.setState({
												deleteItemTitle: 'למחוק את השבלונה?',
												deleteItemType: 'delete-table',
												showDeleteLayoutPopup: true
											})}
										/>
									</button>
									{(selectedList === null && layoutIndex !== null) &&
										<button>
											<SaveIcon title='שמור שינויים' width='25' height='25' css={showSaveButton ? css`fill:green;` : css`fill:gray;`}
												onClick={this.handleLayoutSave}
											/>
										</button>
									}
									<button>
										<RefreshIcon title='רענון קובץ פריטים' width='25' height='25' css={css`fill:blue;`} onClick={() => this.handlePluFileRefresh()} />
									</button>
								</>
							}
						</div>

						{showScaleTypeSelection &&
							< div css={css`
									display:flex;`}>
								<label css={css`
									width: 200px;
									font-size: 12px;
									color: #0f2649;
									transition: background-color 0.5s ease;
									font-weight: 400;
									margin-bottom: 5px;`}>
									בחירת סוג משקל
									<Select css={css`width: 200px;`}
										value={scaleTypesOptions[scaleTypeIndex]}
										autosize={false}
										clearable={false}
										simpleValue
										placeholder="בחר סוג משקל.."
										onChange={newValue => this.handleScaleTypeSelection(newValue.value)}
										options={scaleTypesOptions}
									/>
								</label>
							</div>
						}
					</div>

					<div css={css`
								grid-area: items;
								width: 95%; 
								direction: ltr;
								text-align: right;
								`}>
						{selectedList &&
							<div css={css`display:flex;justify-content:space-between;align-items:center;`}>
								<button>
									<AcceptIcon title='שמירת קבוצה' width='25' height='25'
										onClick={this.handleSaveList}
									/>
								</button>
								<h3 css={css`flex:1;`}>{`קבוצת ${selectedList.names[0]}`}</h3>
							</div>
						}

						<ShowListContext.Provider value={selectedList} >
							<ReactDataGrid
								columns={columns}
								rowGetter={rowIndex => this.getRow(rowIndex)}
								rowsCount={this.getRowCount()}
								minHeight={selectedList !== null ? 534 : 560}
								enableCellSelect={true}
								onGridRowsUpdated={this.handleGridRowsUpdate}
								onRowClick={this.onRowClick}
								//getSubRowDetails={this.getSubRowDetails(state.expandedRows)}
								//onCellExpand={args => this.setState(this.onCellExpand(args))}
								rowSelection={{
									showCheckbox: false,
									selectBy: {
										isSelectedKey: 'isSelected'
									}
								}}
								// cellRangeSelection={{
								// 	onStart: args => console.log(args),
								// 	onUpdate: args => console.log(args),
								// 	onComplete: args => console.log(args)
								// }}
								contextMenu={
									<ExampleContextMenu
										onRowDelete={(e, { rowIdx }) => this.setRows(this.deleteRow(rowIdx))}
										onRowInsertAbove={(e, { rowIdx }) => this.setRows(this.insertRow(rowIdx))}
										onRowInsertBelow={(e, { rowIdx }) => this.setRows(this.insertRow(rowIdx + 1))}
									/>
								}
							//RowsContainer={ContextMenuTrigger}
							/>
						</ShowListContext.Provider>
					</div>
				</div>

				{
					showColorPicker &&
					<div css={css`
								position: absolute;
								z-index: 100;
								left: 0px;
								top: 50px;`}>
						<TwitterPicker
							triangle='top-right'
							color={color}
							colors={['#888', '#FF0000', '#FFFF00', '#00FF00', '#0000FF', '#D2B48C', '#A0522D', '#556B2F', '#8B008B']}
							onChange={this.handleColorSelection}
						/>
					</div>
				}

				{
					presetOptionSelectedIndex !== null &&
					<OptionPopup
						options={presetOptions}
						values={selectedList !== null
							? selectedList.items.find(x => x.index == presetOptionSelectedIndex) != null
								? selectedList.items.find(x => x.index == presetOptionSelectedIndex).options
								: null
							: data[layoutIndex].presets.find(x => x.id == presetOptionSelectedIndex) != null
								? data[layoutIndex].presets.find(x => x.id == presetOptionSelectedIndex).item.options
								: null}
						onAccept={this.handlePresetOptionPopup}
						onClose={this.hidePresetOptionPopup}
					/>
				}
				<AdvLayoutNamePopup
					visible={showNewLayoutPopup}
					type={'new-layout'}
					onAccept={this.handleNewLayoutClick}
					onChange={() => { }}
					onClose={() => this.setState({ showNewLayoutPopup: false })}
				/>

				<AdvLayoutNamePopup
					visible={showDuplicateLayoutPopup}
					type={'duplicate-layout'}
					onAccept={this.handleDuplicateLayoutClick}
					onChange={() => { }}
					onClose={() => this.setState({ showDuplicateLayoutPopup: false })}
				/>

				<AdvLayoutNamePopup
					visible={showEditLayoutNamePopup}
					type={'edit-layout'}
					name={selectedLayout}
					onAccept={this.handleEditLayoutNameClick}
					onChange={() => { }}
					onClose={() => this.setState({ showEditLayoutNamePopup: false })}
				/>

				<AdvDeleteItemPopup
					visible={showDeleteLayoutPopup}
					title={deleteItemTitle}
					onClick={this.handleLayoutDelete}
				/>

				<WarningPopup
					visible={showWarningPopup}
					keys={['close']}
					labels={[{ key: 'close', label: 'אישור' }]}
					title={warningPopupTitle}
					onClick={() => this.setState({ showWarningPopup: false })}
				/>

				<WarningPopup
					visible={showDeplicateCodePopup}
					title={warningPopupTitle}
					keys={['yes', 'no']}
					labels={[{ key: 'yes', label: 'כן' }, { key: 'no', label: 'לא' }]}
					onClick={(key) => this.handleDuplicateCode(key)}
				/>
			</>
		)
	}
}

function mapStateToProps(state) {
	return {
		selectedGroupPresets: state.App.selectedGroupPresets,
		ShowPresetsGroup: state.App.showPresetsGroup,
		selectedGroupId: state.App.selectedGroupId,
		selectedGroup: state.App.selectedGroup,
		presetItems: state.App.Presets,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Presets);



/*
a {
	position: relative;
	display: inline-block;
	margin-top: 20px;
}
a[title]:hover:after {
	content: attr(title);
	position: absolute;
	top: -100%;
	left: 0;
}
*/