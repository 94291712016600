import React from 'react';
import { NavLink } from 'react-router-dom';
import Breadcrumbs from 'react-breadcrumbs';

const BreadcrumbsWrapper = (props) => {
   const texts = props.texts

   const langRoutes = (routes) => {
      return routes.map(item => {
         if (item.name === 'Vendors') {
            return { ...item, name: texts.Vendors }
         } else if (item.name === 'Promotions') {
            return { ...item, name: texts.Promotions }
         } else if (item.name === 'Settings') {
            return { ...item, name: texts.Settings }
         } else if (item.name === 'Report List') {
            return { ...item, name: texts.ReportList }
         } else if (item.name === 'Create Report') {
            return { ...item, name: texts.CreateReport }
         } else if (item.name === 'Promo') {
            return { ...item, name: texts.Promo }
         } else if (item.name === 'Sales') {
            return { ...item, name: texts.Sales }
         } else if (item.name === 'Departments') {
            return { ...item, name: texts.Departments }
         } else if (item.name === 'Branches') {
            return { ...item, name: texts.Branches }
         } else if (item.name === 'Users') {
            return { ...item, name: texts.Users }
         } else if (item.name === 'ScaleGroups') {
            return { ...item, name: texts.ScaleGroups }
         } else {
            return item
         }
      })
   }

   return (
      <div className="breadcrumbs__wrapper">
         <NavLink 
            className="breadcrumbs__home-link icon-home" 
            to="/" > 
         </NavLink>

         <Breadcrumbs
            excludes={['App']}
            itemElement="li"
            itemClass="breadcrumbs__item"
            wrapperElement="ul"
            wrapperClass="breadcrumbs"
            separator={null}
            routes={langRoutes(props.routes)}
            params={props.params}
         />
      </div>
   )
}

export default BreadcrumbsWrapper
