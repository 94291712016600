import Http from '../../../utils/Http';
import { getLocal } from '../../../utils/LocalStorage';
import * as actionTypes from '../actionTypes';

export function getReportList(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.REPORT_LIST_REQUEST })
    Http(`GetSellerReportCriterionList/${getLocal('SessionKey')}`, 'GET', data)
      .then(
        response => {
          dispatch({
            type: actionTypes.REPORT_LIST_GET_SELLER_REPORT_CRITERION_LIST,
            payload: response,
            status: data.status
          })
        }
      )
  }
}

export function reportListSetFilter(query) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.REPORT_LIST_SET_FILTER,
      query
    })
  }
}

export function reportListChangeSort(sort) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.REPORT_LIST_TO_CHANGE_SORT,
      sort
    })
  }
}

export function sendToInnactive(item) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.REPORT_LIST_SEND_TO_INNACTIVE,
      item
    })
  }
}
