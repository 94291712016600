import {chain} from 'lodash'

export default function groupFunction(arr) {
  return chain(arr).reduce((acc, x) => {
    if (acc[x.BranchNetCode]){
      acc[x.BranchNetCode].PaidCounter += x.PaidCounter
      acc[x.BranchNetCode].NotPaidCounter += x.NotPaidCounter
      acc[x.BranchNetCode].PaidSum += x.PaidSum
      acc[x.BranchNetCode].NotPaidSum += x.NotPaidSum
      acc[x.BranchNetCode].IsChecked = false
      acc[x.BranchNetCode].IdentificationNumber = x.BranchNetCode
    } else {
      acc[x.BranchNetCode] = {
        ...acc,
        IdentificationNumber: '',
        SellerFirstName: '',
        PaidCounter: x.PaidCounter,
        NotPaidCounter: x.NotPaidCounter,
        PaidSum: x.PaidSum,
        NotPaidSum: x.NotPaidSum,
        BranchNetCode: x.BranchNetCode
      }
    }
    return acc
  }, {}).toArray().value()
}
