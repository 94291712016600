require('jquery')

export function getColumns(texts) {
  return [
    {title: texts.Branch, dataKey: 'branch'},
    {title: texts.ID, dataKey: 'id'},
    {title: texts.Name, dataKey: 'name'},
    {title: texts.PaidCount, dataKey: 'paid_first'},
    {title: texts.PaidSum, dataKey: 'paid_second'},
    {title: texts.NotPaidCount, dataKey: 'not_paid_first'},
    {title: texts.NotPaidSum, dataKey: 'not_paid_second'}
  ]
}

export function createIMG(phrase) {
  var temp = document.createElement('canvas')
  temp.id = 'temp'
  // temp.style = 'color: black;margin:0px;font-size:20px;'
  temp.innerHTML = phrase
  //need to render element, otherwise it won't be displayed
  let tCtx = temp.getContext('2d')
  tCtx.canvas.width = tCtx.measureText(phrase).width;
  tCtx.fillText(phrase, 0, 10);
  return tCtx.canvas.toDataURL()
}

export function getData(data) {
  return data.map((item) => {
    return {
      branch: item.BranchNetCode,
      id: item.SellerId,
      name: '',
      paid_first: item.PaidCounter,
      paid_second: +item.PaidSum.toFixed(2),
      not_paid_first: item.NotPaidCounter,
      not_paid_second: +item.NotPaidSum.toFixed(2)
    }
  })
}
