import { combineReducers } from 'redux';

import App from './App';

import Dashboard from './Dashboard';

import CreateReport from './Vendors/CreateReport';
import ReportList from './Vendors/ReportList';
import ReportPage from './Vendors/ReportPage';
import ReportTrans from './Vendors/ReportTrans';

import Presets from './Scales/presets';

import Sales from './Advertising/Sales';

export default combineReducers({
  App,
  CreateReport,
  Dashboard,
  ReportList,
  ReportPage,
  ReportTrans,
  Sales,
  Presets
})
