/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactPlayer from 'react-player';
import Griddle, { RowDefinition, ColumnDefinition, plugins } from 'griddle-react';
import Select from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import isEmpty from 'lodash/isEmpty';
import Http from '../../utils/Http'
import {
   getLocal,
   setLocal
} from '../../utils/LocalStorage'

import * as actions from '../../store/actions/index';

import Checkbox from '../../components/Checkbox';
import SearchInput from '../../components/SearchInput';

import MoviePlaceholder from "../../assets/images/movie-placeholder-small.png";
import templateIcon from '../../assets/images/template.jpg'

// const enhancedWithRowData = connect((state, props) => {
//    return {
//       rowData: state.Sales.results[props.griddleKey]
//    };
// });

const rowDataSelector = (state, { griddleKey }) => {
   return state
      .get('data')
      .find(r => r.get('griddleKey') === griddleKey)
      .toJSON();
};

const enhancedWithRowData = connect((state, props) => {
   return {
      rowData: rowDataSelector(state, props),
   };
});


const NoResults = (text) => {
   text = 'אין נתונים זמינים';
   return (
      <h3>{text}</h3>
   )
}

function ItemsView(props) {

   const texts = props.texts;
   const styleConfig = {
      icons: {
         TableHeadingCell: {
            sortDescendingIcon: <small>(desc)</small>,
            sortAscendingIcon: <small>(asc)</small>,
         },
      },
      classNames: {
         Table: 'table',
         TableBody: 'table__row',
         TableHeading: 'table__row',
         TableHeadingCell: 'table__item',
         Row: 'table__row'
      },
      styles: {}
   }

   function handleItemToggle(id) {
      props.onClick('update-items');
      props.actions.saleItemsToggleCheckItem(id);
   }

   return (
      <div>
         <h2>מוצרים</h2>
         <div className="pluitem-list__filter search clearfix">
            <SearchInput placeholder={texts.ItemSearch} onChange={props.actions.saleItemsSetFilter} value={props.saleItems.query} />
            <div className="checkbox">
               <input id="showSelected" className="checkbox__input" type="checkbox" checked={props.saleItems.showSelected} onChange={props.actions.saleItemsToggleShowSelected} />
               <label className="checkbox__label" htmlFor="showSelected">{texts.ShowSelected}</label>
            </div>
            <div className="table__found-items text-right">
               <span className="table__found-item">{props.saleItems.results.length} {texts.Items}</span>
            </div>
         </div>
         <div className="table__wrap">
            <Griddle
               styleConfig={styleConfig}
               data={props.saleItems.results}
               plugins={[plugins.LocalPlugin, plugins.PositionPlugin({ tableHeight: 400 })]}
               pageProperties={{
                  currentPage: 1,
                  pageSize: 100
               }}
               components={{
                  Layout: ({ Table }) => <Table />,
                  NoResults: (tableTexts) => <NoResults text={tableTexts.NoData} />
               }}
            >
               <RowDefinition>
                  <ColumnDefinition
                     id='IsChecked'
                     title=' '
                     width='20'
                     cssClassName='table__item text-center'
                     // customHeadingComponent={() => {
                     //    return <Checkbox
                     //       name="reportPage__allSelected"
                     //       checked={props.saleItems.allSelected}
                     //       onChange={() => props.actions.saleItemsToggleCheckAllItems()} />
                     // }}
                     customComponent={enhancedWithRowData((row) => {
                        return <Checkbox
                           name={row.rowData.name}
                           checked={row.rowData.IsChecked}
                           onChange={() => handleItemToggle(row.rowData.id)} />
                     })}
                  />
                  <ColumnDefinition
                     id='id'
                     title='קוד'
                     width='20'
                     cssClassName='table__item'
                  />
                  <ColumnDefinition
                     id='name'
                     title='שם'
                     cssClassName='table__item'
                  />
               </RowDefinition>
            </Griddle>
         </div>
      </div>
   )
}

function CustomerView(props) {

   const { media, template, playerPlaying, onClick, onTemplateView, onMovieClick, onTemplateTypeChanged, items } = props;

   var durations = [
      { value: '5', label: '5 שניות' },
      { value: '10', label: '10 שניות' },
      { value: '15', label: '15 שניות' },
      { value: '20', label: '20 שניות' },
      { value: '25', label: '25 שניות' },
      { value: '30', label: '30 שניות' },
   ];

   if (media !== '' && (media.includes('bmp') || media.includes('jpg') || props.media.includes('png')))
      return (
         <div>
            <h2>צד לקוח-תמונה
               <button style={{ position: 'relative', right: '20px' }} className="bolet-icon-edit" onClick={(e) => onClick('edit-media', e)}></button>
               <button style={{ position: 'relative', right: '40px' }} className="bolet-icon-trash" onClick={(e) => onClick('del-media', e)}></button>
            </h2>
            <img className="details-image" alt="" src={media} />
            <div className="duration">
               <label>
                  משך הצגה
                  <Select
                     value={props.duration}
                     autosize={false}
                     clearable={false}
                     simpleValue
                     placeholder="בחר זמן.."
                     onChange={(newValue) => onClick('set-duration', newValue)}
                     options={durations}
                  />
               </label>
            </div>
         </div>
      )
   else if (media !== '' && media.includes('mp4')) {

      if (playerPlaying) {

         return (
            <div>
               <h2>צד לקוח-סרטון
                  <button style={{ position: 'relative', right: '20px' }} className="bolet-icon-edit" onClick={(e) => onClick('edit-media', e)}></button>
                  <button style={{ position: 'relative', right: '40px' }} className="bolet-icon-trash" onClick={(e) => onClick('del-media', e)}></button>
               </h2>
               <div className="details-movie">
                  <ReactPlayer
                     url={media}
                     playing={true}
                     onEnded={() => onMovieClick('end')}
                     width='176px'
                     height='auto'
                  />
               </div>
            </div>
         )
      }

      return (
         <div>
            <h2>צד לקוח-סרטון
               <button style={{ position: 'relative', right: '20px' }} className="bolet-icon-edit" onClick={(e) => onClick('edit-media', e)}></button>
               <button style={{ position: 'relative', right: '40px' }} className="bolet-icon-trash" onClick={(e) => onClick('del-media', e)}></button>
            </h2>
            <div className="details-movie">
               <a href="#" onClick={() => onMovieClick('start')}>
                  <img src={MoviePlaceholder} alt="" />
               </a>
            </div>
         </div>
      )
   }
   else if (template.id !== -1) {
      let options = [
         { value: '0', label: 'הצג פריט שבחר המפעיל' },
      ];
      let selectItemsType = null;
      items.forEach(element => {
         options.push({ value: `${element.id}`, label: `${element.id} | ${element.name}` });
      });
      if (template.id === 3) {
         selectItemsType = (
            <div className="form__input-wrap">
               <h2>בחירת פריט מהרשימה, תציג את כל הפריטים ברצף</h2>
            </div>
         )
      }
      if (template.id !== 3) {
         selectItemsType = (
            <div className="form__input-wrap">
               <h2>בבחירת מוצרים הצג את הפריט</h2>
               <Select className="select-time"
                  value={template.type}
                  autosize={false}
                  clearable={false}
                  simpleValue
                  placeholder=""
                  onChange={(newValue) => onTemplateTypeChanged(newValue)}
                  options={options}
               />
            </div>
         )
      }
      return (
         <div>
            <h2>צד לקוח-תבנית
               <button style={{ position: 'relative', right: '20px' }} className="bolet-icon-edit" onClick={(e) => onClick('edit-template', e)}></button>
               <button style={{ position: 'relative', right: '40px' }} className="bolet-icon-trash" onClick={(e) => onClick('del-template', e)}></button>
            </h2>
            <img className="details-template" alt="" src={templateIcon} onClick={onTemplateView} />
            {selectItemsType}
         </div>
      )
   }
   return (
      <div>
         <h2>צד לקוח</h2>
         <a className="sale-add-link" onClick={(e) => onClick('add-media', e)}>+ הוסף תמונה</a>
         <a className="sale-add-link" onClick={(e) => onClick('add-template', e)}>+  הוסף תבנית</a>
      </div>
   )
}

function UserView(props) {

   if (props.UserText === '')
      return (
         <div>
            <h2>צד מוכרן</h2>
            <a className="sale-add-link" onClick={(e) => props.onClick('add-message', e)}>+  הוסף  הודעה  למוכרן</a>
         </div>
      );
   else {
      const blocksFromHTML = convertFromHTML(props.UserText);
      const contentState = ContentState.createFromBlockArray(blocksFromHTML);
      const editorState = EditorState.createWithContent(contentState);

      return (
         <div>
            <h2>צד מוכרן
            <button style={{ position: 'relative', right: '20px' }} className="bolet-icon-edit" onClick={(e) => props.onClick('edit-message', e)}></button>
               <button style={{ position: 'relative', right: '40px' }} className="bolet-icon-trash" onClick={(e) => props.onClick('del-message', e)}></button>
            </h2>
            <Editor
               editorState={editorState}
               toolbarHidden="true"
               readOnly="true"
               wrapperClassName="readonly-editor-wrapper"
               editorClassName="editor"
            />

            {/* <div className="sale-user-text" contenteditable="true">{$(props.UserText).text()}</div> */}
         </div>
      );
   }
}

class ViewSaleDetails extends Component {

   state = {
      playerPlaying: false,
   };

   async componentDidMount() {
   }

   handleItemDeletedRow = row => {
      const { Items } = this.props.data;
      let data = Items.filter(x => x.deleteId !== row);
      this.props.onClick('update-items-selection', data);
   }

   handleMovieClick = type => {
      switch (type) {
         case 'start':
            this.setState({ playerPlaying: true });
            break;

         case 'end':
            this.setState({ playerPlaying: false });
            break;

         default:
            break
      }
   }

   onTemplateView = () => {
      this.props.showTemplateView(this.props.data.Id)
   }

   render() {

      const { onClick, app, saleItems } = this.props;
      const texts = !isEmpty(app.Texts) && app.Texts.Vendors.CreateReport[app.lang]
      const { UserText, Template, Display, Items } = this.props.data;

      //console.log('viewSaleDetails', { data: this.props.data, display: Display.image, template: Template.id, saleItems })
      return (
         <>
            <section className="sales-details">
               {/* <UserView
                  UserText={UserText}
                  onClick={onClick}
               />
               <br />  */}
               <CustomerView
                  items={this.props.data.Items}
                  media={Display.image}
                  template={Template}
                  playerPlaying={this.state.playerPlaying}
                  duration={Display.duration}
                  onMovieClick={this.handleMovieClick}
                  onClick={onClick}
                  onTemplateView={this.onTemplateView}
                  onTemplateTypeChanged={(value) => this.props.onClick('update-template-type', value)}
               />
               <br />
               <ItemsView
                  texts={texts}
                  Items={Items}
                  saleItems={saleItems}
                  actions={this.props.actions}
                  onClick={onClick}
                  onItemDeletedRow={this.handleItemDeletedRow}
               />
            </section>
         </>
      )
   }
}

function mapStateToProps(state) {
   return {
      app: state.App,
      saleItems: state.Sales,
   }
}

function mapDispatchToProps(dispatch) {
   return {
      actions: bindActionCreators(actions, dispatch)
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSaleDetails)
