/* eslint-disable no-useless-escape */

import React from 'react'
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty'

import Collapse from '../components/Collapse'

class Menu extends React.Component {

   link(to, name) {
      const s = to.match(/\/([^\/]*)$/)[1];
      name = name || (s.charAt(0).toUpperCase() + s.slice(1));
      const { app } = this.props
      const texts = !isEmpty(app.Texts) && app.Texts.Menu[app.lang]
      return (
         <Link key={name}
            // activeClassName="dropdown__link_active"
            to={to}
            className="dropdown__link"
            onClick={(e) => { e.stopPropagation() }}
         >
            {texts[name]}
         </Link>)
   }

   menu(name, icon, items) {
      const ucname = name.charAt(0).toUpperCase() + name.slice(1);
      const { app } = this.props
      const texts = !isEmpty(app.Texts) && app.Texts.Menu[app.lang]

      if (items.length)
         return (
            <ul className="menu__list">
               <Collapse
                  icon={icon}
                  label={texts[ucname]}
                  name={name}
                  open={this.props.openSidebar[name] || false}
                  onToggle={this.props.onToggle}
               >
                  <div className="dropdown__menu">
                     {items.map(item => (
                        this.link(item.to, item.name)
                     ))}
                  </div>
               </Collapse>
            </ul>
         )
      else
         return (
            <ul className="menu__list menu__item">
               <Link key={name}
                  activeClassName="dropdown__link_active no_items"
                  to={'/Status'}
                  className="dropdown__link no_items"
                  onClick={(e) => { e.stopPropagation() }}
               >
                  {texts[name]}
               </Link>
            </ul>
         )
   }

   render() {
      const { activeModules } = this.props.app;

      var vendors = this.menu('vendors', 'vendors', [
         { to: '/vendors/reportList' },
         { to: '/vendors/createreport' }
      ]);

      var promotionOptios = [];
      if (activeModules.includes('playlist'))
         promotionOptios.push({ to: '/promotions/playlist' });
      if (activeModules.includes('promotion'))
         promotionOptios.push({ to: '/promotions/sales' });

      var promotions = this.menu('promotions', 'statsDots',
         promotionOptios,
      );

      var scales = this.menu('scales', 'scales', [
         { to: '/scales/presets' },
         { to: '/scales/parameters' },
         { to: '/scales/presetsScales' },
         { to: '/scales/status' },
      ]);

      var online = this.menu('OnlineMenu', '', [
         { to: '/OnlineStatus' },
      ]);

      const settingItems = [
         // { to: '/settings/departments' },
         // { to: '/settings/branches' }
      ];
      if (activeModules.includes('playlist') || activeModules.includes('promotion'))
         settingItems.push({ to: '/settings/scalegroups' });
      //settingItems.push({ to: '/settings/users' });
      var settings = this.menu('settings', 'equalizer', settingItems);

      return (
         <div>
            {activeModules.includes('vendors') ? vendors : null}
            {activeModules.includes('playlist') || activeModules.includes('promotion') ? promotions : null}
            {activeModules.includes('scales') ? scales : null}
            {activeModules.includes('settings') ? settings : null}
            {activeModules.includes('online') ? online : null}

         </div>
      )
   }
}

export default Menu;