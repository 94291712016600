import React from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTable from "react-table";
import Select from 'react-select';

import * as actions from '../../store/actions/index';
import classes from './Users.module.scss';
import http from '../../utils/Http';

import AdvDeleteItemPopup from '../../components/advertising/AdvDeleteItemPopup';

const permissionTypes = [
   { value: 'admin', label: 'מנהל מערכת' },
   { value: 'manager', label: 'מנהל' },
   { value: 'user', label: 'משתמש' }
];

class Users extends React.Component {
   state = {
      data: [],
      branches: [],
      deleteId: 0,
      showDeletePopup: false,
      saveChanges: false,
   };

   async componentDidMount() {
      const data = await http('GetUsers', 'GET');

      const branchesLoaded = await http('GetBranches', 'GET');
      branchesLoaded.unshift({ id: -1, name: 'כל הסניפים' });
      const branches = branchesLoaded.map(branch => {
         return ({ label: branch.name, value: branch.id })
      });

      this.setState({ data, branches });
   }

   handleRowBranchChange = (e, cellInfo) => {
      const rows = this.state.data.map((row, index) => {
         if (cellInfo.index === index) {
            return ({
               ...row,
               branch: e.label
            })
         }
         return row;
      })

      this.setState({ data: rows, saveChanges: true });
   }

   handleRowPermissionChange = (e, cellInfo) => {
      const rows = this.state.data.map((row, index) => {
         if (cellInfo.index === index) {
            return ({
               ...row,
               permission: e.value
            })
         }
         return row;
      })

      this.setState({ data: rows, saveChanges: true });
   }

   addRow = e => {
      const data = this.state.data;
      data.push({ deleteId: data.length + 1, id: data.length + 1, name: '' });
      this.setState({ data });
   }

   deleteRow = deleteId => {
      this.setState({ showDeletePopup: true, deleteId });
   }

   handlePopupDelete = (type, e) => {
      if (type === 'delete') {
         const data = this.state.data.filter(x => x.deleteId !== this.state.deleteId);
         this.setState({ data });
      }
      this.setState({ showDeletePopup: false });
   }

   async handleSumbit(e) {
      this.props.actions.UpdateUsers(this.state.data);
      this.setState({ saveChanges: false });
   }

   renderEditable = cellInfo => {
      if (this.state.data[cellInfo.index] == null)
         return null;
      return (
         <div
            className={classes.vertical_center}
            contentEditable
            suppressContentEditableWarning
            onBlur={e => {
               const data = [...this.state.data];
               data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
               this.setState({ data, saveChanges: true });
            }}
            dangerouslySetInnerHTML={{
               __html: this.state.data[cellInfo.index][cellInfo.column.id]
            }}
         />
      );
   }

   render() {

      const {
         showDeletePopup,
         saveChanges
      } = this.state;

      //console.log('Users render', this.state.data);
      return (
         <section className="managment-system">
            <div className="container">
               <div className="row">
                  <div className="col-md-5 col-xs-12">
                     <div className={classes.header} >
                        <h3>משתמשים</h3>
                        <div className={classes.users__buttons} >
                           <button className={`green-btn ${classes.users__button}`} onClick={(e) => this.addRow(e)}>הוסף משתמש</button>
                           <button className={`${saveChanges ? 'green-btn' : 'grey-btn'} ${classes.users__button}`} onClick={(e) => this.handleSumbit(e)}>שמירת נתונים</button>
                        </div>
                     </div>
                     <div className={classes.users__table}>
                        <ReactTable
                           data={this.state.data}
                           noDataText="אין נתונים להצגה.."
                           previousText="הקודם"
                           nextText="הבא"
                           pageText="דף"
                           ofText="/"
                           columns={[
                              {
                                 Header: "",
                                 accessor: "deleteId",
                                 width: 30,
                                 Cell: row => (
                                    <div className={classes.vertical_center}>
                                       <a href="#" className={`bolet-icon-trash align-center`} onClick={() => this.deleteRow(row.row.deleteId)}> </a>
                                    </div>
                                 )
                              },
                              // {
                              //    Header: "קוד",
                              //    accessor: "id",
                              //    width: 80,
                              // },
                              {
                                 Header: "שם",
                                 accessor: "name",
                                 Cell: this.renderEditable
                              },
                              {
                                 Header: "סיסמה",
                                 accessor: "password",
                                 width: 80,
                                 Cell: this.renderEditable
                              },
                              {
                                 Header: "הרשאה",
                                 accessor: "permission",
                                 width: 180,
                                 Cell: row => {
                                    const menuPlacementValue = "auto"; // set to 'top' to make the dropdown move up.
                                    return (
                                       <Select
                                          menuPlacement={menuPlacementValue}
                                          options={permissionTypes}
                                          placeholder='בחר הרשאה..'
                                          searchable={false}
                                          value={permissionTypes.find(x => x.value === row.value)}
                                          onChange={(e) => this.handleRowPermissionChange(e, row)}
                                       />
                                    )
                                 }
                              },
                              {
                                 Header: "סניף",
                                 accessor: "branch",
                                 width: 180,
                                 Cell: row => {
                                    return (
                                       <Select
                                          options={this.state.branches}
                                          placeholder='בחר סניף..'
                                          searchable={false}
                                          value={this.state.branches.find(x => x.label === row.value)}
                                          onChange={(e) => this.handleRowBranchChange(e, row)}
                                       />
                                    )
                                 }
                              },
                           ]}
                           showPageSizeOptions={false}
                           defaultPageSize={10}
                           getTrProps={(state, rowInfo, column) => {
                              return {
                                 style: {
                                    height: '50px'
                                 }
                              };
                           }}
                           className="-striped -highlight"
                        />
                     </div>
                     <AdvDeleteItemPopup
                        visible={showDeletePopup}
                        title='למחוק משתמש?'
                        onClick={this.handlePopupDelete}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

function mapStateToProps(state) {
   return {
      Branches: state.App.Branches,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      actions: bindActionCreators(actions, dispatch)
   }
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Users);
