import React from 'react'
import { PropTypes } from 'prop-types'
import Formsy from 'formsy-react'
import FormRadioGroup from '../../components/form/FormRadioGroup'
// import FormInput from '../components/form/FormInput'
import FormSelect from '../../components/form/FormSelect'

class SortReportList extends React.Component {
  state = {
    windowWidth: window.innerWidth
  }
  
  handleResize() {
    this.setState({windowWidth: window.innerWidth})
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this))
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this))
  }
  
  render() {
    const props = this.props
    const texts = props.texts
    const onChangeStatus = (model) => {  
      if (model.status.value) {
        props.submit({status: model.status.value})
      } else {
        props.submit(model)
      }
      
    }
    
    const options = [
      {
        label: texts.Sales,
        value: 'Sales'
      },
      {
        label: texts.Custom,
        value: 'Custom'
      },
      {
        label: texts.Inactive,
        value: 'Inactive'
      },
      {
        label: texts.All,
        value: 'All'
      }
    ]
    
    return (
      <div  className="sort__block">
        <Formsy onChange={onChangeStatus}>
          {this.state.windowWidth > 767 ? (
            <FormRadioGroup 
              items={options}
              itemClassName="sort__radio"
              listClassName="sort__list"
              name="status"
              value="Sales" />
          ) : (
            <div className="sort__mobile-wrap">
              <p className="sort__descr">Select type report:</p>
              <div className="sort__mobile">
                <div className="custom-select">
                  <FormSelect
                    labelKey="label"
                    valueKey="value"
                    name="status"
                    value="Sales"
                    placeholder="Sales"
                    options={options}
                  />
                </div>
              </div>
            </div>
          )}
        </Formsy>
      </div>
    )
  }
}

SortReportList.propTypes = {
  submit: PropTypes.func.isRequired
}

export default SortReportList
