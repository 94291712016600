import React from 'react'
import { PropTypes } from 'prop-types'
import Modal from 'react-modal'

Modal.setAppElement('#root');

export default function ModalWrapper(props) {
   let ModalContent

   if (props.modal.type === 'ReportTrans') {
      ModalContent = require('../containers/Vendors/ReportTrans').default
   } else if (props.modal.type === 'Help') {
      ModalContent = props.modal.type ? require(`./modal/${props.modal.type}`).default : ''
   }

   if (!props.modal.isOpen)
      return null;

   return (
      <Modal
         isOpen={props.modal.isOpen}
         contentLabel="Modal"
         className={`Modal__Bootstrap ${props.modal.className}`}>

         <div className="modal-dialog">
            <div className="modal-content">
               <div className="modal-header">
                  <button type="button" className="close" onClick={props.actions.closeModal}>
                     <span aria-hidden="true" className="icon-close"></span>
                     <span className="sr-only">Close</span>
                  </button>
                  <h4 className="modal-title">{props.modal.title}</h4>
               </div>
               <div className="modal-body">
                  <ModalContent {...props.modal} app={props.app} />
               </div>
               <div className="modal-footer">
                  <button type="button" className="btn btn-default" onClick={props.actions.closeModal}>Close</button>
               </div>
            </div>
         </div>

      </Modal>
   )
}

ModalWrapper.propTypes = {
   test: PropTypes.string
}
